import React from 'react';
import styles from './styles.module.scss';
import DateService from "../../../../services/DateService";
import {EnumCallStatus, ICallResource} from "../../../../resources/ICall.resource";
import dayjs from "dayjs";
import {observer} from "mobx-react-lite";
import AutomaticAssesmentObserver, {
    EnumDurationType
} from "../../../../mobx/AutomaticAssesment/AutomaticAssesmentObserver";

interface IProps {
    data:ICallResource;
}

const TdPeeked = ({data}:IProps) => {

    const formatCallTime = (duration:number) => {
        let days = Math.floor(duration / (60 * 60 * 24));
        let hours = Math.floor(duration / (60 * 60));
        let minutes = Math.floor(duration / (60));
        let result = '';

        if (days > 0) {
            result += `${days} дн.`;
        }
        if (hours > 0) {
            result += `${hours} год.`;
        }
        if (minutes > 0) {
            result += `${minutes} хв.`;
        }

        return result || '-';
    };

    const getFilterTitleHover = () => {
        return `${data.employee.fullName} / ${DateService.formatUI(data.lastActivity.time, 'HH.MM.YYYY')}`
    }

    const getLastInProgressTime = () => {
        const start = dayjs(data.callTime);
        const end = dayjs(data.resolved ? data.resolved : new Date());
        const seconds = Math.abs(end.diff(start, 's'));
        const minutes = Math.abs(end.diff(start, 'm'));
        const hours = Math.abs(end.diff(start, 'h'));
        const days = Math.abs(end.diff(start, 'd'));
        const months = Math.abs(end.diff(start, 'M'));

        switch (true){
            case months > 0:
                return `${months} міс.`
            case days > 0:
                return `${days} дн.`
            case hours > 0:
                return `${hours} год.`
            case minutes > 0:
                return `${minutes} хв.`
            case seconds > 0:
                return `${seconds} сек.`
            default:
                return `${months} міс.`
        }
    }

    const getLastInProgressTimeHours = () => {
        const start = dayjs(data.callTime);
        const end = dayjs(data.resolved ? data.resolved : new Date());
        const seconds = Math.abs(end.diff(start, 's'));
        const minutes = Math.abs(end.diff(start, 'm'));
        const hours = Math.abs(end.diff(start, 'h'));

        switch (true){
            case hours > 0:
                return `${hours} год.`
            case minutes > 0:
                return `${minutes} хв.`
            case seconds > 0:
                return `${seconds} сек.`
            default:
                return `${hours} год.`
        }
    }

    const getTitle = () => {
        return AutomaticAssesmentObserver.getDurationType() === EnumDurationType.DAYS ? getLastInProgressTime() : getLastInProgressTimeHours();
    }

    return (
        <div>
            <span
                className={styles.peeked}
                title={getFilterTitleHover()}
            >
                {data.state !== EnumCallStatus.NEW && data.problemResult >= 10 ? getTitle() : '-'}
            </span>
        </div>
    );
};

export default observer(TdPeeked);
