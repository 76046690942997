import {EnumCallStatus, ILastActivityResource} from "./ICall.resource";
import {IEmployeeResource} from "./IEmployee.resource";

export interface ICallDetailsResource {
    readonly activities: IActivityResource[];
    readonly blobUrl: string;
    readonly callTime: string;
    readonly channels: number;
    readonly companyId: number;
    readonly countWithSameNumber: number;
    readonly created: string;
    readonly decodingStatus: string;/// ?????
    readonly duration: number;
    readonly employee: IEmployeeResource;
    readonly fileName: string;
    readonly labseg: ILabSegResource[];
    readonly phoneNumber: string;
    readonly problemResult: number;
    readonly resolved: string;
    readonly peeked: string;
    readonly state: EnumCallStatus;
    readonly status: EnumCallStatus;
    readonly uuid: string;
    readonly vttUrl: string;
    readonly lastActivity: ILastActivityResource;
    readonly notes?: string;
    readonly extId:string;
}

export interface IActivityResource {
    readonly activity: EnumCallStatus;
    readonly time: string;
    readonly userId: number;
    readonly who: string;
}

export interface ILabSegResource {
    readonly end: number;
    readonly id: number;
    readonly index: number;
    readonly speaker: EnumSpeakerType;
    readonly start: number;
    readonly staticRegions: IStaticRegionResource[];
    readonly text: string;
    readonly textId: number;
}

export interface IStaticRegionResource {
    readonly end: number;
    readonly id: number;
    readonly name: EnumEmotionType;
    readonly start: number;
    readonly value: string;
}

export enum EnumSpeakerType {
    CLIENT = 'CLIENT',
    OPERATOR = 'OPERATOR',
    SPEAKER = 'SPEAKER'
}

export enum EnumEmotionType {
    solution = 'solution',
    problem = 'problem',
    spend = 'spend',
    emotions = 'emotion',
    unselect = 'unselect',
    multi = 'multi',
}
