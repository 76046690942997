import React, {useEffect, useState} from 'react';
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import SignupObserver from "../../mobx/signup/SignupObserver";
import ROUTES from "../../routes";
import styles from './styles.module.scss';

const RegistrationPage = () => {
    const [fields, setFields] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    });
    const [emailError, setEmailError] = useState<boolean>(false);
    const [phoneError, setPhoneError] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id;
        const value = e.target.value;

        setFields((prev) => {
            return {
                ...prev,
                [id]: value.trim()
            }
        });
    };

    const handleNext = () => {
        SignupObserver.setSignUpInfo(fields);
        navigate(ROUTES.CREATE_COMPANY);
    }

    const isDisabled = () => {
        if(emailError || phoneError) return true;
        return !(fields.email && fields.firstName && fields.lastName && fields.phoneNumber)
    };

    useEffect(() => {
        const signupInfo = SignupObserver.getSignUpInfo();
        if(!signupInfo) return;

        setFields({
            firstName: signupInfo.firstName ? signupInfo.firstName : '',
            lastName: signupInfo.lastName ? signupInfo.lastName : '',
            email: signupInfo.email ? signupInfo.email : '',
            phoneNumber: signupInfo.phoneNumber ? signupInfo.phoneNumber : '',
        });
    },[])

    const handleValidateEmail = (e:React.FocusEvent<HTMLInputElement>) => {
        const value:string = e.target.value;
        const regexEmail = `[A-Za-z0-9\\._%+\\-]+@[A-Za-z0-9\\.\\-]+\\.[A-Za-z]{2,}`;

        if(!value.match(regexEmail)) {
            setEmailError(true);
            return;
        }

        setEmailError(false);
    }

    const handleValidatePhoneNumber = (e:React.FocusEvent<HTMLInputElement>) => {
        const value:string = e.target.value;
        const regexPhone= /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;

        if(!value.match(regexPhone) || value.length > 13) {
            setPhoneError(true);
            return;
        }

        setPhoneError(false);
    }

    return (
        <div className={styles.signup}>
            <div className={styles.signup__card}>
                <div className={styles.signup__header}>
                    <h2 className={styles.signup__title}>
                        User info
                    </h2>
                    <p className={styles.signup__text}>
                        A user is a person who utilizes a computer or network service. A user often has a user account and is identified to the system by a username (or user name).[a]
                        Some software products provide services to other systems and have no direct end users.
                    </p>
                </div>
                <div className={styles.signup__body}>
                    <div className={styles.signup__avatar}>
                        AA
                    </div>
                    <div className={styles.signup__left}>
                        <div className={styles.signup__inputs}>
                            <div className={styles.signup__grid}>
                                <div className={styles.signup__line}>
                                    <div className={styles.signup__input}>
                                        <Input
                                            placeholder={'First name *'}
                                            value={fields.firstName}
                                            id={'firstName'}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={styles.signup__input}>
                                        <Input
                                            placeholder={'Last name *'}
                                            value={fields.lastName}
                                            id={'lastName'}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className={styles.signup__line}>
                                    <div className={styles.signup__input}>
                                        <Input
                                            placeholder={'Email *'}
                                            value={fields.email}
                                            id={'email'}
                                            onChange={handleChange}
                                            onBlur={handleValidateEmail}
                                            errorText={emailError ? 'Invalidate email' : ''}
                                        />
                                    </div>
                                    <div className={styles.signup__input}>
                                        <Input
                                            placeholder={'Phone *'}
                                            value={fields.phoneNumber}
                                            id={'phoneNumber'}
                                            onChange={handleChange}
                                            onBlur={handleValidatePhoneNumber}
                                            errorText={phoneError ? 'Invalidate phone' : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.signup__footer}>
                                <div className={styles.signup__apply}>
                                    <Button
                                        text={'Next'}
                                        onClick={handleNext}
                                        disabled={isDisabled()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(RegistrationPage);
