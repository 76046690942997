import React, {useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import styles from './styles.module.scss';

const ListIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.5858 3.33579 11.25 3.75 11.25H14.25C14.6642 11.25 15 11.5858 15 12C15 12.4142 14.6642 12.75 14.25 12.75H3.75C3.33579 12.75 3 12.4142 3 12Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.25 6C8.25 5.58579 8.58579 5.25 9 5.25H20.25C20.6642 5.25 21 5.58579 21 6C21 6.41421 20.6642 6.75 20.25 6.75H9C8.58579 6.75 8.25 6.41421 8.25 6Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.58579 3.33579 5.25 3.75 5.25H5.25C5.66421 5.25 6 5.58579 6 6C6 6.41421 5.66421 6.75 5.25 6.75H3.75C3.33579 6.75 3 6.41421 3 6Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 18C7.5 17.5858 7.83579 17.25 8.25 17.25H16.5C16.9142 17.25 17.25 17.5858 17.25 18C17.25 18.4142 16.9142 18.75 16.5 18.75H8.25C7.83579 18.75 7.5 18.4142 7.5 18Z" fill="#1E1E1E"/>
        </svg>
    )
}

const ArrowDownIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z" fill="#1E1E1E"/>
        </svg>
    )
}

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2573 7.22745C19.5502 7.52033 19.5503 7.9952 19.2574 8.28811L10.773 16.7734C10.6324 16.9141 10.4416 16.9931 10.2427 16.9931C10.0437 16.9931 9.85297 16.9141 9.71231 16.7735L5.46967 12.5308C5.17678 12.2379 5.17678 11.7631 5.46967 11.4702C5.76256 11.1773 6.23744 11.1773 6.53033 11.4702L10.2426 15.1824L18.1967 7.22751C18.4895 6.9346 18.9644 6.93458 19.2573 7.22745Z" fill="#1E1E1E"/>
        </svg>
    )
}

const perPageArray = ['10', '20', '50', '100'];

interface IProps {
    perPage: number;
    setPerPage: (perPage:number) => void;
}

const PerPageDropDown = ({perPage, setPerPage}:IProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => setIsOpen(false);
    const handleToggleOpen= () => setIsOpen((prev) => !prev);
    const handleSelectPerPage = (e:React.MouseEvent<HTMLButtonElement>) => {
        const pages = e.currentTarget.id;
        setPerPage(Number(pages));
        handleClose();
    }

    const ref = useOutsideClick(handleClose)

    return (
        <div
            ref={ref}
            className={styles.dropdown}
        >
            <button
                className={styles.dropdown__btn}
                type={'button'}
                onClick={handleToggleOpen}
            >
                <ListIcon/>
                Show {perPage}
                <ArrowDownIcon/>
            </button>

            {isOpen ? (
                <div className={styles.dropdown__list}>
                    {perPageArray.map((pages:string) => (
                        <button
                            type={'button'}
                            className={`${styles.dropdown__item} ${pages === String(perPage) ? styles.dropdown__item_active : ''}`}
                            id={pages}
                            onClick={handleSelectPerPage}
                        >
                            <span>Show {pages}</span>
                            {pages === String(perPage) ? <CheckIcon/> : null}
                        </button>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default PerPageDropDown;
