import React, {useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import {IActivityResource, ICallDetailsResource} from "../../../../resources/ICallDetails.resource";
import DateService from "../../../../services/DateService";
import StatusNameColorFactory from "../../../../constants/StatusNameColorFactory";
import StringService from "../../../../services/StringService";
import styles from './styles.module.scss';

const ActivitiesIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 6C12.4142 6 12.75 6.33579 12.75 6.75V11.25H17.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12V6.75C11.25 6.33579 11.5858 6 12 6Z" fill="#1E1E1E"/>
    </svg>
);

interface IProps {
    data: ICallDetailsResource;
}

const CallActivities = ({data}:IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggleOpen = () => setIsOpen((prev) => !prev);
    const handleClose = () => setIsOpen(false);

    const ref = useOutsideClick(handleClose);

    const getFullNameUserActivity = (activityUser: string) => {
        const arrActivity = activityUser.split(' ');
        return `${arrActivity[0]} ${arrActivity[1]}`
    };

    const activitiesMiddleware = () => {
        return data.activities.sort((a:IActivityResource, b:IActivityResource) => DateService.getTime(b.time) - DateService.getTime(a.time))
    }

    return (
        <div
            className={styles.activities}
            ref={ref}
        >
            <button
                className={styles.activities__btn}
                type={'button'}
                onClick={handleToggleOpen}
            >
                <ActivitiesIcon />
            </button>
            {isOpen ? (
                <ul className={styles.activities__list}>
                    {activitiesMiddleware().map((item:IActivityResource) => (
                        <li className={styles.activities__item}>
                            {DateService.formatUI(item.time, 'DD.MM.YYYY')}
                            {' '}
                            {DateService.formatUI(item.time, 'HH:mm')}
                            {' - '}
                            <span title={item.who}>
                                {StringService.cutString(getFullNameUserActivity(item.who), 10)}
                            </span>
                            {' - '}
                            <span
                                className={styles.activities__status}
                                style={{
                                    background:StatusNameColorFactory(item.activity).color
                                }}
                            >
                            {StatusNameColorFactory(item.activity).name}
                        </span>
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

export default CallActivities;
