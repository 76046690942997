import React from 'react';
import styles from './styles.module.scss';

const IconReload = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.23132 14.6533C2.23132 14.2391 2.56711 13.9033 2.98132 13.9033H7.48132C7.89554 13.9033 8.23132 14.2391 8.23132 14.6533C8.23132 15.0675 7.89554 15.4033 7.48132 15.4033H3.73132V19.1533C3.73132 19.5675 3.39554 19.9033 2.98132 19.9033C2.56711 19.9033 2.23132 19.5675 2.23132 19.1533V14.6533Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.5999 9.34619C21.5999 9.76041 21.2641 10.0962 20.8499 10.0962H16.3499C15.9356 10.0962 15.5999 9.76041 15.5999 9.34619C15.5999 8.93198 15.9356 8.59619 16.3499 8.59619H20.0999V4.84619C20.0999 4.43198 20.4356 4.09619 20.8499 4.09619C21.2641 4.09619 21.5999 4.43198 21.5999 4.84619V9.34619Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.45021 14.1238C2.74267 13.8304 3.21755 13.8297 3.51087 14.1222L6.69954 17.3015C7.39525 17.9982 8.2215 18.551 9.13101 18.9281C9.99988 19.2884 11.2922 19.5001 13.125 19.5001C13.5392 19.5001 13.875 19.8359 13.875 20.2501C13.875 20.6643 13.5392 21.0001 13.125 21.0001C11.2078 21.0001 9.68877 20.7832 8.55648 20.3137C7.46507 19.8612 6.47357 19.1979 5.63865 18.3619C5.63847 18.3618 5.63829 18.3616 5.63811 18.3614L2.45177 15.1844C2.15845 14.892 2.15775 14.4171 2.45021 14.1238Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.381 9.87574C21.0885 10.1691 20.6136 10.1698 20.3203 9.8773L17.1316 6.69801C16.4359 6.00128 15.6097 5.44855 14.7002 5.07143C13.8382 4.71401 12.3459 4.50012 10.5 4.50019C10.0858 4.50021 9.75002 4.16443 9.75 3.75022C9.74998 3.33601 10.0858 3.00021 10.5 3.00019C12.4041 3.00012 14.1355 3.21347 15.2747 3.68582C16.3661 4.13836 17.3576 4.80159 18.1925 5.63758C18.1927 5.63776 18.1929 5.63794 18.1931 5.63812L21.3794 8.81508C21.6727 9.10754 21.6734 9.58242 21.381 9.87574Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.3789 17.3187C18.6619 17.6212 18.6461 18.0958 18.3436 18.3787C18.3033 18.4164 18.2637 18.4537 18.2244 18.4905C17.7061 18.9774 17.2613 19.3952 16.4603 19.7957C16.0898 19.9809 15.6393 19.8308 15.4541 19.4603C15.2689 19.0898 15.419 18.6393 15.7895 18.4541C16.3904 18.1536 16.6919 17.8709 17.2176 17.3782C17.2504 17.3474 17.2841 17.3158 17.3188 17.2834C17.6213 17.0004 18.0959 17.0162 18.3789 17.3187Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.45215 6.68035C5.16917 6.37787 5.18498 5.90326 5.48746 5.62028C5.52772 5.58261 5.56738 5.54535 5.60662 5.5085C6.12495 5.02161 6.56973 4.60382 7.37072 4.20332C7.74121 4.01808 8.19171 4.16825 8.37695 4.53873C8.5622 4.90922 8.41203 5.35972 8.04154 5.54496C7.44069 5.84539 7.13911 6.12809 6.61344 6.62084C6.58062 6.65161 6.54692 6.68319 6.51222 6.71566C6.20974 6.99864 5.73513 6.98283 5.45215 6.68035Z"
              fill="#1E1E1E"/>x
    </svg>
)

interface IProps {
    onClick: () => void;
}

const ReloadBtn = ({onClick}: IProps) => {
    return (
        <button
            className={styles.btn__btn}
            type={'button'}
            onClick={onClick}
        >
            <IconReload />
        </button>
    );
};

export default ReloadBtn;
