import React from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import {initialStatusData} from "../TdStatus";
import styles from "./styles.module.scss";

interface IProps {
    handleClose: () => void;
    selectedStatus:string;
    handleApply: () => void;
}

const ModalApproveChangeStatus = ({handleClose, selectedStatus, handleApply}:IProps) => {
    const getStatusName = () => {
        return initialStatusData.find((item) => item.value === selectedStatus)?.label;
    }

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <div>
                    <h2 className={styles.modal__title}>
                        Ви бажаєте змінити статус вибраних дзвінків на "{getStatusName()}" ?
                    </h2>
                </div>
                <div className={styles.modal__footer}>
                    <TextButton
                        text={'Cancel'}
                        onClick={handleClose}
                    />
                    <Button
                        text={'Apply'}
                        onClick={handleApply}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalApproveChangeStatus;
