import React from 'react';
import {NavLink} from "react-router-dom";
import ProblemResultColorFactory from "../../../../constants/ProblemResultColorFactory";
import styles from './styles.module.scss';

interface IProps {
    problemResult: number;
    uuid:string;
}

const TdProblemResult = ({problemResult, uuid}:IProps) => {

    return (
        <div className={styles.mark}>
            <span
                className={styles.mark__value}
                style={{
                    color: ProblemResultColorFactory(problemResult).color,
                    background: ProblemResultColorFactory(problemResult).background,
                }}
            >
                {problemResult || problemResult === 0  ? problemResult : 'н/д'}
            </span>
        </div>
    );

};

export default TdProblemResult;
