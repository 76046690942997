import React, {useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import {ILabSegResource} from "../../../../resources/ICallDetails.resource";
import ModalEditLabSegText from "../ModalEditLabSegText";
import styles from './styles.module.scss';

const MenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
        <path d="M9.5 9.84375C9.96599 9.84375 10.3438 9.46599 10.3438 9C10.3438 8.53401 9.96599 8.15625 9.5 8.15625C9.03401 8.15625 8.65625 8.53401 8.65625 9C8.65625 9.46599 9.03401 9.84375 9.5 9.84375Z" fill="#1E1E1E"/>
        <path d="M9.5 5.34375C9.96599 5.34375 10.3438 4.96599 10.3438 4.5C10.3438 4.03401 9.96599 3.65625 9.5 3.65625C9.03401 3.65625 8.65625 4.03401 8.65625 4.5C8.65625 4.96599 9.03401 5.34375 9.5 5.34375Z" fill="#1E1E1E"/>
        <path d="M9.5 14.3438C9.96599 14.3438 10.3438 13.966 10.3438 13.5C10.3438 13.034 9.96599 12.6562 9.5 12.6562C9.03401 12.6562 8.65625 13.034 8.65625 13.5C8.65625 13.966 9.03401 14.3438 9.5 14.3438Z" fill="#1E1E1E"/>
    </svg>
);

const EditIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9261 5.17524C15.9179 5.17958 15.8886 5.19697 15.8286 5.24786C15.7301 5.33149 15.6069 5.45391 15.4007 5.66009L15.397 5.6638L13.0607 8.00011L16 10.9394L18.3383 8.60113L18.3392 8.60028L18.3452 8.59423C18.548 8.39138 18.669 8.26945 18.7519 8.17181C18.8029 8.11173 18.8203 8.08237 18.8246 8.07415C18.8396 8.02592 18.8396 7.97435 18.8247 7.92621C18.8203 7.91798 18.8029 7.88853 18.7517 7.82822C18.6678 7.72942 18.545 7.60581 18.3383 7.39905L16.5985 5.65925C16.3926 5.45335 16.2695 5.33113 16.1712 5.24765C16.1114 5.19695 16.0822 5.17958 16.074 5.17523C16.0258 5.16034 15.9743 5.16034 15.9261 5.17524ZM14.9393 12.0001L12 9.06078L4.75 16.3108V19.2501L7.68934 19.2501L14.9393 12.0001ZM9.81064 19.2501L19.3929 9.66785L19.399 9.66179L19.3998 9.66094L19.4183 9.64244C19.6002 9.46065 19.7672 9.29362 19.8955 9.14254C20.0337 8.97963 20.1703 8.78667 20.2502 8.54096C20.3644 8.18953 20.3644 7.81093 20.2502 7.4594C20.1703 7.21355 20.0336 7.02045 19.8952 6.85744C19.7668 6.70621 19.5996 6.53899 19.4175 6.35692L17.6407 4.58011C17.4594 4.39883 17.2927 4.23211 17.1419 4.10406C16.9792 3.966 16.7864 3.82949 16.5408 3.74971C16.1894 3.63551 15.8107 3.63551 15.4593 3.74971C15.2136 3.82953 15.0207 3.96614 14.8579 4.10431C14.7069 4.23247 14.5401 4.39937 14.3585 4.58092L14.342 4.59744L3.46967 15.4698C3.32902 15.6104 3.25 15.8012 3.25 16.0001V20.0001C3.25 20.4143 3.58579 20.7501 4 20.7501H15.5C15.9142 20.7501 16.25 20.4143 16.25 20.0001C16.25 19.5859 15.9142 19.2501 15.5 19.2501H9.81064Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.25 19.875C17.25 19.4608 17.5858 19.125 18 19.125H19.5C19.9142 19.125 20.25 19.4608 20.25 19.875C20.25 20.2892 19.9142 20.625 19.5 20.625H18C17.5858 20.625 17.25 20.2892 17.25 19.875Z" fill="#1E1E1E"/>
        </svg>
    )
}

const PlayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.17592 4.57731L19.1907 9.91852C19.1969 9.9216 19.203 9.92477 19.2091 9.92803L19.2397 9.94435C19.6421 10.1589 19.989 10.3439 20.2499 10.5154C20.511 10.687 20.8022 10.9163 20.9636 11.2661C21.1783 11.7313 21.1783 12.2679 20.9636 12.7332C20.8021 13.0831 20.5107 13.3127 20.2491 13.4847C19.9868 13.6571 19.6378 13.8433 19.2327 14.0593L9.17957 19.421C8.81522 19.6153 8.49736 19.7849 8.23081 19.897C7.95988 20.0109 7.63388 20.1148 7.27613 20.0686C6.79289 20.0063 6.35692 19.7455 6.07391 19.3476C5.86467 19.0534 5.80309 18.7165 5.77627 18.424C5.74997 18.1371 5.74998 17.7782 5.75 17.3672L5.75 6.66695C5.75 6.65574 5.75 6.64456 5.75 6.63341C5.74998 6.22235 5.74997 5.86333 5.77627 5.57624C5.80306 5.28374 5.86458 4.94651 6.07391 4.65218C6.35689 4.25432 6.79284 3.99346 7.27613 3.93112C7.63427 3.88493 7.9607 3.98932 8.23129 4.10327C8.49688 4.21511 8.81348 4.38399 9.17592 4.57731ZM7.45609 5.42063C7.39536 5.43143 7.34117 5.46403 7.30348 5.51194C7.29827 5.52732 7.28196 5.58262 7.27001 5.71309C7.25075 5.92331 7.25 6.21248 7.25 6.66695V17.3336C7.25 17.7881 7.25075 18.077 7.27001 18.287C7.28194 18.4172 7.29821 18.4724 7.30343 18.4878C7.34115 18.5357 7.39545 18.5684 7.45634 18.5792C7.47226 18.5766 7.52859 18.565 7.64929 18.5143C7.84346 18.4326 8.09872 18.2974 8.4996 18.0836L18.4996 12.7503C18.9424 12.5142 19.2282 12.3608 19.425 12.2314C19.5487 12.15 19.5949 12.1042 19.6075 12.0909C19.6303 12.0324 19.6303 11.967 19.6075 11.9085C19.5949 11.8951 19.5488 11.8496 19.4261 11.7689C19.232 11.6414 18.9513 11.4905 18.518 11.2595C18.5118 11.2564 18.5057 11.2532 18.4996 11.2499L8.4996 5.91661C8.09887 5.70289 7.84355 5.56757 7.64913 5.48569C7.52851 5.4349 7.47209 5.42327 7.45609 5.42063Z" fill="#1E1E1E"/>
        </svg>
    )
}

interface IProps {
    data:ILabSegResource;
    handleEditText: (labSeg:ILabSegResource, text:string) => void;
    isOpen: boolean;
    handleClose: () => void;
    handleToggleOpen: (e:React.MouseEvent<HTMLButtonElement>) => void;
    handleTogglePlay: () => void;
}

const EditLabsegMenu = ({data, handleEditText, isOpen, handleClose, handleToggleOpen, handleTogglePlay}:IProps) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpenModal = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsOpenModal(true)
    };
    const handleCloseModal = () => setIsOpenModal(false);
    const handlePlay = () => {
        handleTogglePlay();
        handleClose();
    }

    const ref = useOutsideClick(handleClose);

    return (
        <>
            {isOpenModal ? (
                <ModalEditLabSegText
                    handleClose={handleCloseModal}
                    data={data}
                    handleEditText={handleEditText}
                />
            ) : null}
            <div
                className={styles.menu}
                ref={ref}
            >
                <button
                    onClick={handleToggleOpen}
                    type={'button'}
                >
                    <MenuIcon />
                </button>
                {isOpen ? (
                    <div className={styles.menu__list}>
                        <button
                            className={styles.menu__item}
                            type={'button'}
                            onClick={handleOpenModal}
                        >
                            <EditIcon />
                            <span>Edit</span>
                        </button>
                        <button
                            className={styles.menu__item}
                            type={'button'}
                            onClick={handlePlay}
                        >
                            <PlayIcon />
                            <span>Play</span>
                        </button>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default EditLabsegMenu;
