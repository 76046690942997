import React from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import Input from "../../../../components/common/Input";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import {ILimitFields} from "../RequestStatus";
import {EnumRequestStatus} from "../../../../resources/IRequest.resource";
import styles from './styles.module.scss';

interface IProps {
    requestId: number;
    handleClose: () => void;
    setFields: (value:any) => void;
    fields: ILimitFields;
    handleEditStatus: (requestId: number, status:string, maxTotalPhoneCalls?: string, maxTotalPhoneCallsDurationSec?: string) => void;
}

const ModalInitialLimits = ({handleClose, fields, setFields, handleEditStatus, requestId}:IProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const key = e.target.id;

        setFields((prev:ILimitFields) => ({
            ...prev,
            [key]: value
        }));
    }

    const handleCancel = () => {
        setFields({
            maxTotalPhoneCalls: '',
            maxTotalPhoneCallsDurationSec: ''
        });
        handleClose();
    }

    const handleSubmit = () => {
        handleEditStatus(requestId, EnumRequestStatus.DONE, fields.maxTotalPhoneCalls, fields.maxTotalPhoneCallsDurationSec);
        handleClose();
    };


    const isDisabled = () => {
        return !fields.maxTotalPhoneCallsDurationSec || !fields.maxTotalPhoneCalls;
    }

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <h2 className={styles.modal__title}>
                    Додайте ліміти до активованого юзера
                </h2>
                <Input
                    id={'maxTotalPhoneCalls'}
                    placeholder={'Ліміт дзвінків'}
                    value={fields.maxTotalPhoneCalls}
                    onChange={handleChange}
                />
                <Input
                    id={'maxTotalPhoneCallsDurationSec'}
                    placeholder={'Ліміт тривалості'}
                    value={fields.maxTotalPhoneCallsDurationSec}
                    onChange={handleChange}
                />
                <div className={styles.modal__footer}>
                    <TextButton
                        text={'Cancel'}
                        onClick={handleCancel}
                    />
                    <Button
                        onClick={handleSubmit}
                        text={'OK'}
                        disabled={isDisabled()}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalInitialLimits;
