import React from 'react';
import {IRequestResource} from "../../../../resources/IRequest.resource";
import StringService from "../../../../services/StringService";
import DateService from "../../../../services/DateService";
import RequestStatus from "../RequestStatus";
import HeadFilter, {EnumSortType} from "../../../AutomaticAssessmentPage/components/HeadFilter";
import Limits from "../Limits";
import {IPatchRequest} from "../../../../services/api/FetchService";
import TdNote from "../../../AutomaticAssessmentPage/components/TdNote";
import AdditionalMenuBtn from "../AdditionalMenuBtn";
import styles from './styles.module.scss';
import ClickCopyBtn from "../../../AutomaticAssessmentPage/components/ClickCopyBtn";

interface IProps {
    data: IRequestResource[];
    handleEditStatus: (requestId: number, status:string, maxTotalPhoneCalls?: string, maxTotalPhoneCallsDurationSec?: string) => void;
    handleApproveRequest: (requestId: number, status:string, maxTotalPhoneCalls?: string, maxTotalPhoneCallsDurationSec?: string) => void;
    handleSort: (value:string) => void;
    sortValue: EnumSortType;
    handleEditRequest: (requestId: number, body:IPatchRequest) => void;
    handleEditNotes: (requestId:string | number, note:string) => void;
}

const AdminTable = ({data, handleEditStatus, sortValue, handleSort, handleEditRequest, handleEditNotes, handleApproveRequest}:IProps) => {

    return (
        <div
            className={styles.table__container}
        >
            <table
                className={styles.table}
                cellSpacing={0}
                cellPadding={0}
            >
                <thead className={styles.table__thead}>
                <tr>
                    <td className={styles.table__cell_first}>
                        ID
                    </td>
                    <td className={styles.table__cell_head}>
                        <HeadFilter
                            type={EnumSortType.companyName}
                            title={'Компанія'}
                            selectedSort={sortValue}
                            handleSelectSort={handleSort}
                        />
                    </td>
                    <td className={styles.table__cell_head}>
                        <HeadFilter
                            type={EnumSortType.status}
                            title={'Статус'}
                            selectedSort={sortValue}
                            handleSelectSort={handleSort}
                        />
                    </td>
                    <td className={styles.table__cell_head}>
                        <HeadFilter
                            type={EnumSortType.updatedBy}
                            title={'Адмін'}
                            selectedSort={sortValue}
                            handleSelectSort={handleSort}
                        />
                    </td>
                    <td className={styles.table__cell_head}>
                        Email
                    </td>
                    <td className={styles.table__cell_head}>
                        Телефон
                    </td>
                    <td className={styles.table__cell_head}>
                        <HeadFilter
                            type={EnumSortType.created}
                            title={'Дата реєстрації'}
                            selectedSort={sortValue}
                            handleSelectSort={handleSort}
                        />
                    </td>
                    <td className={styles.table__cell_head}>
                        Лім. дзвінків
                    </td>
                    <td className={styles.table__cell_head}>
                        Лім. дзвін., хв
                    </td>
                    <td className={styles.table__cell_last}>
                        Нотатка
                    </td>
                </tr>
                </thead>
                <tbody className={styles.table__tbody}>
                {data.map((item:IRequestResource) => (
                    <tr key={item.id}>
                        <td>
                            {item.id}
                        </td>
                        <td>
                            {item.companyName}
                        </td>
                        <td>
                            <RequestStatus
                                handleEditStatus={handleEditStatus}
                                data={item}
                            />
                        </td>
                        <td>
                            <span title={item.updatedBy}>
                                {item.updatedBy ? StringService.cutString(item.updatedBy) : '-'}
                            </span>
                        </td>
                        <td>
                            <span title={item.email}>
                                <ClickCopyBtn text={item.email}/>
                            </span>
                        </td>
                        <td>
                            <ClickCopyBtn text={item.phoneNumber}/>
                        </td>
                        <td>
                            {DateService.formatUI(item.created, 'DD.MM.YYYY - HH:mm')}
                        </td>
                        <td>
                            <Limits
                                handleEditRequest={handleEditRequest}
                                name={'maxTotalPhoneCalls'}
                                data={item}
                            />
                        </td>
                        <td>
                            <Limits
                                handleEditRequest={handleEditRequest}
                                name={'maxTotalPhoneCallsDurationSec'}
                                data={item}
                            />
                        </td>
                        <td>
                            <div style={{ display: 'flex' }}>
                                <TdNote
                                    isShowLink={false}
                                    note={item.comment}
                                    entityId={item.id}
                                    handleEditNotes={handleEditNotes}
                                />
                                <AdditionalMenuBtn
                                    data={item}
                                    handleEditStatus={handleApproveRequest}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminTable;
