import {makeAutoObservable} from 'mobx';

export enum EnumDurationType {
    DAYS = 'DAYS',
    HOURS_MINUTES = 'HOURS_MINUTES'
}


class AutomaticAssessmentObserver {
    durationType = EnumDurationType.DAYS;

    constructor() {
        makeAutoObservable(this)
    };

    setDurationType = (type:EnumDurationType) => {
        this.durationType = type;
    };

    getDurationType = () => {
       return this.durationType
    };
}

export default new AutomaticAssessmentObserver();
