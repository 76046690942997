import React, {useEffect, useState} from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import styles from './styles.module.scss';

interface IProps {
    handleClose: () => void;
    notes?:string;
    entityId: number | string;
    handleEditNotes: (callId:string | number, notes:string) => void;
}

const ModalEditNote = ({handleClose, notes, handleEditNotes, entityId}:IProps) => {
    const [note, setNote] = useState('');

    useEffect(() => {
        if(!notes) return;
        setNote(notes);
    },[])

    const handleChangeNote = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setNote(value);
    };

    const handleSave = () => {
        handleEditNotes(entityId, note);
        handleClose()
    }

    return (
        <ModalContainer handleClose={handleClose}>
            <div className={styles.modal}>
                <h2 className={styles.modal__subtitle}>
                    Редагування нотатки
                </h2>
                <textarea
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    className={styles.modal__textarea}
                    value={note}
                    onChange={handleChangeNote}
                />
                <div className={styles.modal__footer}>
                    <div className={styles.modal__btn}>
                        <TextButton
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                    </div>
                    <div className={styles.modal__btn}>
                        <Button
                            text={'Apply'}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalEditNote;
