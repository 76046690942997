import React from 'react';
import useQProfile from "../../queries/useQProfile";
import {EnumModules} from "../../resources/IProfile.resource";
import TestAutomaticAssessmentPage from "../../pages/TestAutomaticAssessmentPage";
import AutomaticAssessmentPage from "../../pages/AutomaticAssessmentPage";
import useQCompanies from "../../queries/useQCompanies";
import CompanyObserver from "../../mobx/company/CompanyObserver";
import AdminGridPage from "../../pages/AdminGridPage";
import Loader from "../Loader";

const GridFactory = () => {
    const {profile} = useQProfile();
    const {companies} = useQCompanies();

    const companyId = CompanyObserver.getCompanyId();

    const getCompanyModules = () => {
        if(!companies) return null;

        const selectedCompany = companies.companies.find((item) => item.id === companyId);

        return selectedCompany?.modules;
    };

    switch (true) {
        case getCompanyModules()?.includes(EnumModules.PHONE_CALL_AUTO):
            return <AutomaticAssessmentPage />
        case profile?.modules.includes(EnumModules.LEAD_GENERATION):
            return <AdminGridPage />
        case getCompanyModules()?.includes(EnumModules.PHONE_CALL_AUTO_TEST):
            return <TestAutomaticAssessmentPage />
        default:
            return <Loader />;
    }
};

export default GridFactory;
