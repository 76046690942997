const ProblemResultColorFactory = (problemResult:number) => {
    switch (true) {
        case problemResult >= 0 && problemResult <= 9:
            return {
                color: '#236B00',
                background: '#E9FDE4'
            };
        case problemResult >= 10 && problemResult <= 19:
            return {
                color: '#007499',
                background: '#E4F7FD'
            };
        case problemResult >= 20 && problemResult <= 29:
            return {
                color: '#936A00',
                background: '#FFFBD2'
            };
        case problemResult >= 30:
            return {
                color: '#6B0000',
                background: '#FDE4E4'
            };
        default:
            return {
                color: '#6B0000',
                background: '#FDE4E4'
            };
    }
}

export default ProblemResultColorFactory;
