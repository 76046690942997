import React, {useEffect, useState} from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import {ILabSegResource} from "../../../../resources/ICallDetails.resource";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import CompanyObserver from "../../../../mobx/company/CompanyObserver";
import FetchService from "../../../../services/api/FetchService";
import styles from './styles.module.scss';

interface IProps {
    handleClose: () => void;
    data: ILabSegResource;
    handleEditText: (labSeg:ILabSegResource, text:string) => void;
}

const ModalEditLabSegText = ({handleClose, data, handleEditText}:IProps) => {
    const [editText, setEditText] = useState('');

    useEffect(() => {
        setEditText(data.text)
    },[])

    const handleChangeText = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setEditText(value);
    }

    const handleEdit = () => {
        handleEditText(data, editText);
        handleClose();
    };

    return (
        <ModalContainer handleClose={handleClose}>
            <div className={styles.modal}>
                <div className={styles.modal__container}>
                    <div className={styles.modal__left}>
                        <h2 className={styles.modal__subtitle}>
                            було
                        </h2>
                        <textarea
                            className={styles.modal__textarea}
                            value={data.text}
                            disabled={true}
                        />
                    </div>
                    <div className={styles.modal__right}>
                        <h2 className={styles.modal__subtitle}>
                            відредаговано
                        </h2>
                        <textarea
                            className={styles.modal__textarea}
                            value={editText}
                            onChange={handleChangeText}
                        />
                    </div>
                </div>
                <div className={styles.modal__footer}>
                    <div className={styles.modal__btn}>
                        <TextButton
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                    </div>
                    <div className={styles.modal__btn}>
                        <Button
                            text={'Apply'}
                            onClick={handleEdit}
                            disabled={!editText}
                        />
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalEditLabSegText;
