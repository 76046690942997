import React, {useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import {ICallDetailsResource} from "../../../../resources/ICallDetails.resource";
import DateService from "../../../../services/DateService";
import styles from './styles.module.scss';

const AdditionalInfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 11.25C10.5 10.8358 10.8358 10.5 11.25 10.5H12C12.4142 10.5 12.75 10.8358 12.75 11.25V15.75C13.1642 15.75 13.5 16.0858 13.5 16.5C13.5 16.9142 13.1642 17.25 12.75 17.25H12C11.5858 17.25 11.25 16.9142 11.25 16.5V12C10.8358 12 10.5 11.6642 10.5 11.25Z" fill="#1E1E1E"/>
        <path d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z" fill="#1E1E1E"/>
    </svg>
);

const AdditionalInfoIconActive = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g opacity="0.6">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#1E1E1E"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 11.25C10.5 10.8358 10.8358 10.5 11.25 10.5H12C12.4142 10.5 12.75 10.8358 12.75 11.25V15.75C13.1642 15.75 13.5 16.0858 13.5 16.5C13.5 16.9142 13.1642 17.25 12.75 17.25H12C11.5858 17.25 11.25 16.9142 11.25 16.5V12C10.8358 12 10.5 11.6642 10.5 11.25Z" fill="#1E1E1E"/>
                <path d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z" fill="#1E1E1E"/>
            </g>
        </svg>
    )
};

interface IProps {
    data: ICallDetailsResource;
}

const CallAdditionalInfo = ({data}:IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggleInfo = () => setIsOpen((prev) => !prev);
    const handleCloseInfo = () => setIsOpen(false);

    const ref = useOutsideClick(handleCloseInfo);

    return (
        <div
            ref={ref}
            className={styles.info}
        >
            <button
                type={'button'}
                className={styles.info__btn}
                onClick={handleToggleInfo}
            >
                {isOpen ? <AdditionalInfoIconActive/> : <AdditionalInfoIcon />}
            </button>
            {isOpen ? (
                <div className={styles.info__list}>
                    <div className={styles.info__item}>
                        <span className={styles.info__subtitle}>
                            Дата та час
                        </span>
                        <span className={styles.info__text}>
                            {DateService.formatUI(data.callTime, 'DD.MM.YYYY')}
                            {' '}
                            {DateService.formatUI(data.callTime, 'HH:mm')}
                        </span>
                    </div>
                    <div className={styles.info__item}>
                    <span className={styles.info__subtitle}>
                        Номер
                    </span>
                        <span className={styles.info__text}>
                        {data.phoneNumber}
                    </span>
                    </div>
                    <div className={styles.info__item}>
                    <span className={styles.info__subtitle}>
                        Оператор
                    </span>
                        <span className={styles.info__text}>
                        {data.employee.fullName}
                    </span>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default CallAdditionalInfo;
