import React, {forwardRef} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './styles.module.scss';

const CalendarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 4.5C3 3.67157 3.67157 3 4.5 3H19.5C20.3284 3 21 3.67157 21 4.5V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V4.5ZM19.5 4.5H4.5V19.5H19.5V4.5Z" fill="#1E1E1E"/>
            <path d="M16.5 12.75C16.9142 12.75 17.25 12.4142 17.25 12C17.25 11.5858 16.9142 11.25 16.5 11.25C16.0858 11.25 15.75 11.5858 15.75 12C15.75 12.4142 16.0858 12.75 16.5 12.75Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 1.5C16.9142 1.5 17.25 1.83579 17.25 2.25V5.25C17.25 5.66421 16.9142 6 16.5 6C16.0858 6 15.75 5.66421 15.75 5.25V2.25C15.75 1.83579 16.0858 1.5 16.5 1.5Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 1.5C7.91421 1.5 8.25 1.83579 8.25 2.25V5.25C8.25 5.66421 7.91421 6 7.5 6C7.08579 6 6.75 5.66421 6.75 5.25V2.25C6.75 1.83579 7.08579 1.5 7.5 1.5Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 8.25C3 7.83579 3.33579 7.5 3.75 7.5H20.25C20.6642 7.5 21 7.83579 21 8.25C21 8.66421 20.6642 9 20.25 9H3.75C3.33579 9 3 8.66421 3 8.25Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.75 12C6.75 11.5858 7.08579 11.25 7.5 11.25H13.5C13.9142 11.25 14.25 11.5858 14.25 12C14.25 12.4142 13.9142 12.75 13.5 12.75H7.5C7.08579 12.75 6.75 12.4142 6.75 12Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.75 15.75C6.75 15.3358 7.08579 15 7.5 15H10.5C10.9142 15 11.25 15.3358 11.25 15.75C11.25 16.1642 10.9142 16.5 10.5 16.5H7.5C7.08579 16.5 6.75 16.1642 6.75 15.75Z" fill="#1E1E1E"/>
        </svg>
    )
}

const SeparatorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g opacity="0.6">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L17.0303 11.4697C17.171 11.6103 17.25 11.8011 17.25 12C17.25 12.1989 17.171 12.3897 17.0303 12.5303L12.5303 17.0303C12.2374 17.3232 11.7626 17.3232 11.4697 17.0303C11.1768 16.7374 11.1768 16.2626 11.4697 15.9697L15.4393 12L11.4697 8.03033C11.1768 7.73744 11.1768 7.26256 11.4697 6.96967Z" fill="#1E1E1E"/>
                <path d="M11.25 12C11.25 12.4142 10.9142 12.75 10.5 12.75C10.0858 12.75 9.75 12.4142 9.75 12C9.75 11.5858 10.0858 11.25 10.5 11.25C10.9142 11.25 11.25 11.5858 11.25 12Z" fill="#1E1E1E"/>
                <path d="M8.25 12C8.25 12.4142 7.91421 12.75 7.5 12.75C7.08579 12.75 6.75 12.4142 6.75 12C6.75 11.5858 7.08579 11.25 7.5 11.25C7.91421 11.25 8.25 11.5858 8.25 12Z" fill="#1E1E1E"/>
            </g>
        </svg>
    )
}

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g opacity="0.6">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967Z" fill="#1E1E1E"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.7803 6.21967C17.4874 5.92678 17.0126 5.92678 16.7197 6.21967L6.21967 16.7197C5.92678 17.0126 5.92678 17.4874 6.21967 17.7803C6.51256 18.0732 6.98744 18.0732 7.28033 17.7803L17.7803 7.28033C18.0732 6.98744 18.0732 6.51256 17.7803 6.21967Z" fill="#1E1E1E"/>
            </g>
        </svg>
    )
}

export type TDatePickerItem = Date | null;

interface IProps {
    startDate: TDatePickerItem;
    endDate: TDatePickerItem;
    handleSelectDate:(dates:[TDatePickerItem, TDatePickerItem]) => void;
    amount?: number;
}

const CustomDatePicker = ({startDate, endDate, handleSelectDate, amount}:IProps) => {

    const ExampleCustomInput = forwardRef((props:any, ref:any) => {
        const  { value, onClick, handleSelectDate } = props;
        const spreadValue = value.split('-');

        const handleClearDates = (e:React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            handleSelectDate([null, null])
        }

        return value ? (
            <button
                type={'button'}
                className={styles.calendar__active}
                onClick={onClick}
                ref={ref}

            >
                <CalendarIcon />
                <span className={styles.calendar__date}>
                    {spreadValue[0]}
                </span>
                 <SeparatorIcon />
                <span>
                    {spreadValue[1]}
                </span>
                <button
                    type={'button'}
                    onClick={handleClearDates}
                    className={styles.calendar__clear}
                >
                    <CloseIcon />
                </button>
                {amount ? (
                    <span className={styles.calendar__amount}>
                    {amount}
                </span>
                ) : null}
            </button>
            ) : (
            <button
                type={'button'}
                className={styles.calendar__empty}
                onClick={onClick}
                ref={ref}
            >
                <CalendarIcon />
                Календар
            </button>
        )
    });

    return (
        <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleSelectDate}
            dateFormat={'dd.MM.yyyy'}
            customInput={<ExampleCustomInput handleSelectDate={handleSelectDate} />}
        />
    );
};

export default CustomDatePicker;
