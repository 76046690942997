import React, {useEffect, useState} from 'react';
import {EnumRequestStatus} from "../../../../resources/IRequest.resource";
import {RequestStatusFactory} from "../../../../constants/RequestStatusFactory";
import styles from './styles.module.scss';
import FetchService from "../../../../services/api/FetchService";

interface IProps {
    handleClick: (e:React.MouseEvent<HTMLButtonElement>) => void;
    isActive: boolean;
    type: EnumRequestStatus | 'ALL';
}

const HeaderFilter = ({handleClick, isActive, type}:IProps) => {
    const [amount, setAmount] = useState<number>(0);


    const getAmount = async () => {
        const urlParams = new URLSearchParams();
        urlParams.set('searchRequest', type === 'ALL' ? '' : `status eq enum[OnboardingRequestStatus.${type}]`);

        const response = await FetchService.getRequests(urlParams);
        return setAmount(response.data.total);
    }

    useEffect(() => {
        getAmount()
    },[])

    return (
        <button
            className={`${styles.filter} ${isActive ? styles.filter__active : ''}`}
            type={'button'}
            onClick={handleClick}
            id={type}
        >
            <span className={styles.filter__filter}>
                {RequestStatusFactory(type).filterName}
            </span>
            {amount || amount === 0 ? (
                <span className={styles.filter__amount}>
                    {amount}
                </span>
            ) : null}
        </button>
    );
};

export default HeaderFilter;
