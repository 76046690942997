import React from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import {EnumCallStatus, ICallResource} from "../../../../resources/ICall.resource";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import {initialStatusData, IStatusItem} from "../TdStatus";
import styles from './styles.module.scss';

export const factoryPatchStatus = (type: EnumCallStatus) => {
    switch (type) {
        case EnumCallStatus.ACCEPTED:
            return 'accept';
        case EnumCallStatus.RESOLVED:
            return 'resolve';
        case EnumCallStatus.IN_PROGRESS:
            return 'peek';
        case EnumCallStatus.NEW:
            return 'reset';
        default:
            return 'accept';
    }
};


interface IProps {
    data: ICallResource;
    statuses: IStatusItem[];
    handleClose: () => void;
    handlePatchStatus: (callId:string, status: string) => void;
    setStatuses: (value: IStatusItem[]) => void;
    selectedStatusId?: string;
}

const ModalChangeStatus = ({data, statuses, handleClose, handlePatchStatus, setStatuses, selectedStatusId}:IProps) => {

    const getSelectedStatus = () => statuses.find((item) => item.value === selectedStatusId);

    const handleApply = () => {
        handlePatchStatus(data.uuid, factoryPatchStatus(selectedStatusId as EnumCallStatus))
        setStatuses(initialStatusData.map((item:IStatusItem) => {
            return item.value === selectedStatusId ? {...item, isSelected: true} : {...item, isSelected: false};
        }));
        handleClose();
    }

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <div>
                    <h2 className={styles.modal__title}>
                        Ви бажаєте змінити статус на "{getSelectedStatus()?.label}" ?
                    </h2>
                </div>
                <div className={styles.modal__footer}>
                    <TextButton
                        text={'Cancel'}
                        onClick={handleClose}
                    />
                    <Button
                        text={'Apply'}
                        onClick={handleApply}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalChangeStatus;
