import React, {useState} from 'react';
import ModalImportCalls from "../ModalImportCalls";
import MainLoadBtn from "./MainLoadBtn";
import AdditionalBtn from "./AdditionalBtn";
import FetchService, {IPostCallsVariables} from "../../../../services/api/FetchService";
import CompanyObserver from "../../../../mobx/company/CompanyObserver";
import useQMyEmployee from "../../../../queries/useQMyEmployee";
import styles from './styles.module.scss';
import Loader from "../../../../components/Loader";

interface IProps {
    isMain?: boolean;
    className?:string;
    disabled?: boolean;
    refresh?: () => void;
}

const UploadFilesBtnEmpty = ({isMain, className, disabled, refresh}:IProps) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError,setIsError] = useState<boolean>(false);

    const {employee} = useQMyEmployee();

    const handleDrop = (e:React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        const FILE = 'file';
        const dataItems = e.dataTransfer.items;
        const parsedFiles:File[] = [];

        for (let i= 0; i < dataItems.length; i++){
            const isFile =  dataItems[i].kind === FILE;
            if(isFile){
                const file  = dataItems[i].getAsFile() as File;
                parsedFiles.push(file);
            }
        }
        setFiles((prev) => [...prev, ...parsedFiles]);
    }

    const handleDeleteFile = (file:File) => {
        setFiles((prev:File[]) => prev.filter((item) => item.name !== file.name));
    };

    const handleOpenModal = () => setIsOpenModal(true);
    const handleCloseModal = () => {
        setIsError(false);
        setIsOpenModal(false);
        setFiles([]);
    };

    const handleDropOpen = (e:React.DragEvent<HTMLLabelElement>) => {
        handleDrop(e);
        handleOpenModal();
    }

    const handleChangeUploadFile = (e:React.ChangeEvent<HTMLInputElement>) => {
        const dataItems = e.target.files;
        if (!dataItems) return;

        setFiles((prev) => [...prev, ...Array.from(dataItems)]);
    }

    const handleChangeMain = (e:React.ChangeEvent<HTMLInputElement>) => {
        handleChangeUploadFile(e);
        setIsOpenModal(true);
    }

    const handleLoadFiles = async () => {
        const variables:IPostCallsVariables = {
            phoneTime: new Date().toISOString(),
            encodedPhoneNumber: '0000000000',
            companyId: Number(CompanyObserver.getCompanyId()),
            employeeId: employee ? employee.id : 0,
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('calls', files[i]);
        }
        try {
            setIsLoading(true);
            await FetchService.postCalls(variables, formData)
            setIsOpenModal(false);
            setIsError(false);
        } catch (error:any) {
            if(error?.response?.status === 412) {
                setIsError(true);
            }
        } finally {
            setFiles([]);
            if(refresh) refresh();
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? <Loader /> : null}
            <div className={`${styles.load} ${className}`}>
                {isOpenModal ? (
                    <ModalImportCalls
                        isError={isError}
                        handleClose={handleCloseModal}
                        handleDrop={handleDrop}
                        files={files}
                        handleDeleteFile={handleDeleteFile}
                        handleChangeUploadFile={handleChangeUploadFile}
                        handleLoadFiles={handleLoadFiles}
                    />
                ) : null}
                {isMain ? (
                    <MainLoadBtn
                        handleDrop={handleDropOpen}
                        handleChange={handleChangeMain}
                    />
                ) : (
                    <AdditionalBtn
                        handleDrop={handleDropOpen}
                        handleChange={handleChangeMain}
                        disabled={disabled}
                    />
                )}
            </div>
        </>
    );
};

export default UploadFilesBtnEmpty;
