import React, {useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import styles from './styles.module.scss';
import {observer} from "mobx-react-lite";
import AutomaticAssesmentObserver, {EnumDurationType} from "../../../../mobx/AutomaticAssesment/AutomaticAssesmentObserver";
import SelectItem from "../../../AutomaticAssessmentPage/components/SelectItem";

const IconMenu = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3 16.125C3 15.7108 3.33579 15.375 3.75 15.375H12.75C13.1642 15.375 13.5 15.7108 13.5 16.125C13.5 16.5392 13.1642 16.875 12.75 16.875H3.75C3.33579 16.875 3 16.5392 3 16.125Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15 16.125C15 15.7108 15.3358 15.375 15.75 15.375H20.25C20.6642 15.375 21 15.7108 21 16.125C21 16.5392 20.6642 16.875 20.25 16.875H15.75C15.3358 16.875 15 16.5392 15 16.125Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3 7.875C3 7.46079 3.33579 7.125 3.75 7.125H6.75C7.16421 7.125 7.5 7.46079 7.5 7.875C7.5 8.28921 7.16421 8.625 6.75 8.625H3.75C3.33579 8.625 3 8.28921 3 7.875Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9 7.875C9 7.46079 9.33579 7.125 9.75 7.125H20.25C20.6642 7.125 21 7.46079 21 7.875C21 8.28921 20.6642 8.625 20.25 8.625H9.75C9.33579 8.625 9 8.28921 9 7.875Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.75 4.875C10.1642 4.875 10.5 5.21079 10.5 5.625V10.125C10.5 10.5392 10.1642 10.875 9.75 10.875C9.33579 10.875 9 10.5392 9 10.125V5.625C9 5.21079 9.33579 4.875 9.75 4.875Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.75 13.125C16.1642 13.125 16.5 13.4608 16.5 13.875V18.375C16.5 18.7892 16.1642 19.125 15.75 19.125C15.3358 19.125 15 18.7892 15 18.375V13.875C15 13.4608 15.3358 13.125 15.75 13.125Z"
              fill="#1E1E1E"/>
    </svg>
)

const IconTime = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 6C12.4142 6 12.75 6.33579 12.75 6.75V11.25H17.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12V6.75C11.25 6.33579 11.5858 6 12 6Z"
              fill="#1E1E1E"/>
    </svg>

)

const IconLeftIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303Z"
              fill="#1E1E1E"/>
    </svg>
)

interface IProps {

}

const AdditionalMenu = () => {
    const [isOpenMainMenu, setIsOpenMainMenu] = useState<boolean>(false);
    const [isOpenDuration, seIsOpenDuration] = useState<boolean>(false);

    const handleToggleMenu = () => setIsOpenMainMenu(true);
    const handleCloseMenu = () => setIsOpenMainMenu(false);
    const handleOpenDuration = () => seIsOpenDuration(true);
    const handleCloseDuration = () => seIsOpenDuration(false);

    const ref = useOutsideClick(() => {
        handleCloseMenu();
        handleCloseDuration();
    });

    const handleSelectDurationType = (e:React.MouseEvent<HTMLButtonElement>) => {
        handleCloseMenu();
        handleCloseDuration();
        const value = e.currentTarget.id as EnumDurationType;
        AutomaticAssesmentObserver.setDurationType(value);
    };

    return (
        <div
            className={styles.menu}
            ref={ref}
        >
            <button
                type={'button'}
                className={styles.menu__btn}
                onClick={handleToggleMenu}
            >
                <IconMenu/>
            </button>
            {isOpenMainMenu ? (
                <div className={styles.menu__list}>
                    <button
                        className={styles.menu__item}
                        type={'button'}
                        onClick={handleOpenDuration}
                    >
                        <div className={styles.menu__item_name}>
                            <IconTime />
                            <span className={styles.menu__text}>
                                Одиниці тривалості
                            </span>
                        </div>
                        <IconLeftIcon/>
                    </button>
                </div>
            ) : null}
            {isOpenDuration ? (
                <div className={styles.menu__duration}>
                    <SelectItem
                        handleSelect={handleSelectDurationType}
                        isSelected={AutomaticAssesmentObserver.getDurationType() === EnumDurationType.DAYS}
                        label={'Дні'}
                        value={EnumDurationType.DAYS}
                    />
                    <SelectItem
                        handleSelect={handleSelectDurationType}
                        isSelected={AutomaticAssesmentObserver.getDurationType() === EnumDurationType.HOURS_MINUTES}
                        label={'Години і хвилини'}
                        value={EnumDurationType.HOURS_MINUTES}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default observer(AdditionalMenu);
