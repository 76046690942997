import React, {useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import ROUTES from "../../routes";
import styles from './styles.module.scss';

const AutomaticMark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.25 11.25C20.6642 11.25 21 11.5858 21 12V16.5C21 16.9142 20.6642 17.25 20.25 17.25C19.8358 17.25 19.5 16.9142 19.5 16.5V12C19.5 11.5858 19.8358 11.25 20.25 11.25Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.25 14.25C17.25 13.8358 17.5858 13.5 18 13.5H22.5C22.9142 13.5 23.25 13.8358 23.25 14.25C23.25 14.6642 22.9142 15 22.5 15H18C17.5858 15 17.25 14.6642 17.25 14.25Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.875 3C8.28921 3 8.625 3.33579 8.625 3.75V7.5C8.625 7.91421 8.28921 8.25 7.875 8.25C7.46079 8.25 7.125 7.91421 7.125 7.5V3.75C7.125 3.33579 7.46079 3 7.875 3Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.25 5.625C5.25 5.21079 5.58579 4.875 6 4.875H9.75C10.1642 4.875 10.5 5.21079 10.5 5.625C10.5 6.03921 10.1642 6.375 9.75 6.375H6C5.58579 6.375 5.25 6.03921 5.25 5.625Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.75 16.5C16.1642 16.5 16.5 16.8358 16.5 17.25V20.25C16.5 20.6642 16.1642 21 15.75 21C15.3358 21 15 20.6642 15 20.25V17.25C15 16.8358 15.3358 16.5 15.75 16.5Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 18.75C13.5 18.3358 13.8358 18 14.25 18H17.25C17.6642 18 18 18.3358 18 18.75C18 19.1642 17.6642 19.5 17.25 19.5H14.25C13.8358 19.5 13.5 19.1642 13.5 18.75Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.00597 19.0597C2.42019 18.4739 2.42019 17.5241 3.00597 16.9384L16.947 2.99731C17.5328 2.41152 18.4826 2.41152 19.0683 2.99731L21.0074 4.93633C21.5932 5.52212 21.5931 6.47186 21.0074 7.05765L7.06631 20.9987C6.48053 21.5845 5.53078 21.5845 4.94499 20.9987L3.00597 19.0597ZM18.0077 4.05797L4.06663 17.999L6.00565 19.938L19.9467 5.99699L18.0077 4.05797Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.9697 6.96967C13.2626 6.67678 13.7374 6.67678 14.0303 6.96967L17.0303 9.96967C17.3232 10.2626 17.3232 10.7374 17.0303 11.0303C16.7374 11.3232 16.2626 11.3232 15.9697 11.0303L12.9697 8.03033C12.6768 7.73744 12.6768 7.26256 12.9697 6.96967Z" fill="#1E1E1E"/>
        </svg>
    )
}

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L9.29289 15.2929C9.10536 15.4804 9 15.7348 9 16C9 16.2652 9.10536 16.5196 9.29289 16.7071L15.2929 22.7071C15.6834 23.0976 16.3166 23.0976 16.7071 22.7071C17.0976 22.3166 17.0976 21.6834 16.7071 21.2929L11.4142 16L16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289Z" fill="#1E1E1E"/>
            <path d="M17 16C17 16.5523 17.4477 17 18 17C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16Z" fill="#1E1E1E"/>
            <path d="M21 16C21 16.5523 21.4477 17 22 17C22.5523 17 23 16.5523 23 16C23 15.4477 22.5523 15 22 15C21.4477 15 21 15.4477 21 16Z" fill="#1E1E1E"/>
        </svg>
    )
}


const OpenIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289L22.7071 15.2929C22.8946 15.4804 23 15.7348 23 16C23 16.2652 22.8946 16.5196 22.7071 16.7071L16.7071 22.7071C16.3166 23.0976 15.6834 23.0976 15.2929 22.7071C14.9024 22.3166 14.9024 21.6834 15.2929 21.2929L20.5858 16L15.2929 10.7071C14.9024 10.3166 14.9024 9.68342 15.2929 9.29289Z" fill="#1E1E1E"/>
            <path d="M15 16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15C14.5523 15 15 15.4477 15 16Z" fill="#1E1E1E"/>
            <path d="M11 16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16C9 15.4477 9.44772 15 10 15C10.5523 15 11 15.4477 11 16Z" fill="#1E1E1E"/>
        </svg>
    )
}



const SideBar = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const location = useLocation();
    const handleToggleOpen = () => setIsOpen((prev) => !prev);

    const itemClassNameBuilder = (route:string) => `${styles.sidebar__item} ${route === location.pathname ? styles.sidebar__item_active : ''}`;

    return (
        <div className={`${styles.sidebar} ${!isOpen ? styles.sidebar__closed : ''}`}>
            <button
                className={styles.sidebar__btn}
                onClick={handleToggleOpen}
            >
                {isOpen ? <CloseIcon/> : <OpenIcon />}
            </button>
           <div className={styles.sidebar__list}>
               <ul className={styles.sidebar__menu}>
                   <li
                       className={itemClassNameBuilder(ROUTES.HOME)}
                       title={'Автоматична оцінка'}
                   >
                       <NavLink
                           to={ROUTES.HOME}
                           className={styles.sidebar__link}

                       >
                           <div className={styles.sidebar__icon}>
                               <AutomaticMark />
                           </div>
                           {isOpen ?  'Автоматична оцінка' : ''}
                       </NavLink>
                   </li>
               </ul>
           </div>
        </div>
    );
};

export default SideBar;
