import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import CompanyObserver from "../../mobx/company/CompanyObserver";
import LoginPage from "../../pages/LoginPage";
import ROUTES from "../../routes";


const PrivateRoute = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();

    if(!token) {

        return <Navigate to={ROUTES.LOGIN} state={`${location.pathname}${location.search}`} />
    }

    return <Outlet />
};

export default PrivateRoute;
