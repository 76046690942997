import {makeAutoObservable, toJS} from 'mobx';

export interface ISignupInfo {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    companyName?:string;
    comment?: string;
}

class SignupObserver {
    signUpInfo: ISignupInfo | null = null;

    constructor() {
        makeAutoObservable(this)
    }

    setSignUpInfo = (info:ISignupInfo) => {
        if(!this.signUpInfo){
            this.signUpInfo = info;
            return;
        }

        this.signUpInfo = {...this.signUpInfo, ...info};
    }

    getSignUpInfo = () => toJS(this.signUpInfo);
    clearSignUpInfo = () => {
        this.signUpInfo = null;
    };
}

export default new SignupObserver();
