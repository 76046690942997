import React, {useState} from 'react';
import GuestLayout from "../../layouts/GuestLayout";
import Input from "../../components/common/Input";
import GuestCard from "../../components/GuestCard";
import Button from "../../components/common/Button";
import FetchService from "../../services/api/FetchService";
import Loader from "../../components/Loader";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import styles from './styles.module.scss';
import TextButton from "../../components/common/TextButton";
import routes from "../../routes";

const ResetPasswordEmailPage = () => {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [isSuccess, setIsSuccess] = useState(false);

    const navigate = useNavigate();

    const handleChangeEmail = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
    }

    const handleResetPassword = async () => {
        let body = {};

        if(email.match(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`)){
            body = {
                email: email
            }
        } else {
            body = {
                userName: email
            }
        }

        try {
            setIsLoading(true);
            await FetchService.postResetPasswordEmail(body)
            setIsError(false);
            setIsSuccess(true);
        }catch (e){
            setIsError(true);
        }
        finally {
            setIsLoading(false);
        }

    }

    const isDisabled = () => {
        return !email;
    }

    return (
        <GuestLayout>
            {isLoading ? <Loader/> : null}
            <GuestCard>
                {isSuccess ? (
                    <div className={styles.recover__container}>
                        <p className={styles.recover__success}>
                            We have sent you link in response to your request to reset your password. Check email.
                        </p>
                        <Button
                            text={'OK'}
                            onClick={() => navigate(ROUTES.LOGIN)}
                        />
                    </div>
                ) : (
                    <div className={styles.recover__container}>
                        <Input
                            placeholder={'E-mail or phone'}
                            type={'text'}
                            value={email}
                            onChange={handleChangeEmail}
                            errorText={isError ? 'User not found. Try again' : ''}
                        />
                        <div className={styles.recover__links}>
                            <TextButton
                                variant={'link'}
                                text={'Log in'}
                                to={routes.LOGIN}
                            />
                            <TextButton
                                variant={'link'}
                                text={'Create account'}
                                to={routes.REGISTER}
                            />
                        </div>
                        <Button
                            text={'Reset password'}
                            onClick={handleResetPassword}
                            disabled={isDisabled()}
                        />
                    </div>
                )}
            </GuestCard>
        </GuestLayout>
    );
};

export default ResetPasswordEmailPage;
