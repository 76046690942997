import {useQuery} from "@tanstack/react-query";
import QueryKeyFactory from "./QueryKeyFactory";
import {AxiosResponse} from "axios";
import {IProfileResource} from "../resources/IProfile.resource";
import FetchService from "../services/api/FetchService";

const useQProfile = () => {


    const {data, isFetching, error} = useQuery({
        queryKey: QueryKeyFactory.profile(),
        queryFn: () => FetchService.getProfile(),
        select: (response:AxiosResponse<IProfileResource>) => response.data,
        staleTime: 0,
        throwOnError: true
    });

    return {
        profile: data ? data : null,
        isLoading: isFetching,
        error
    }
};

export default useQProfile;
