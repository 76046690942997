import React from 'react';
import styles from './styles.module.scss';
import {useLocation, useNavigate} from "react-router-dom";
import ROUTES from "../../../routes";

const IGoBackIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20.25 12H3.75" stroke="#1E1E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.25 7.5L3.75 12L8.25 16.5" stroke="#1E1E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

interface IProps {

}

const GoBackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleGoBack = () => {
        if(location.state === ROUTES.LOGIN){
            navigate(ROUTES.HOME)
        } else {
            navigate(-1);
        }
    }

    return (
        <button
            type={'button'}
            onClick={handleGoBack}
        >
            <IGoBackIcon />
        </button>
    );
};

export default GoBackButton;
