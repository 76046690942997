import React, {useState} from 'react';
import {EnumEmotionType, ILabSegResource} from "../../../../resources/ICallDetails.resource";
import SpeakerColorNameFactory from "../../../../constants/SpeakerColorNameFactory";
import SelectEmotion from "../SelectEmotion";
import EditLabsegMenu from "../EditLabsegMenu";
import styles from './styles.module.scss';

interface IProps {
    data: ILabSegResource;
    handleGoToWave: (startDate: number) => void;
    currentTime: number;
    postCallLabsegLabel: (labsegId: number, emotion: EnumEmotionType) => void;
    handleEditText: (labSeg:ILabSegResource, text:string) => void;
    handleTogglePlay: () => void;
    deleteCallLabels: (labsegId: number, labelIds: number[]) => void;
}

const TextCard = ({data, handleGoToWave, currentTime, postCallLabsegLabel, handleEditText, handleTogglePlay, deleteCallLabels}:IProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsOpen((prev) => !prev)
    };
    const handleClose = () => setIsOpen(false);

    const isActive = () => data.start <= currentTime && currentTime <= data.end;
    const operatorNameColor = SpeakerColorNameFactory(data.speaker);

    const handleMovePlay = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(e.target !== e.currentTarget) return;
        handleGoToWave(data.start);
    }

    return (
        <div
            id={String(data.id)}
            className={`
                ${styles.card}
                ${isOpen ? styles.card__card_selected : ''}
                ${isActive() ? styles.card__active : ''}
            `}
        >
            <button
                className={`${styles.card__role} ${isActive() ? styles.card__role_active : ''}`}
                onClick={handleMovePlay}
            >
                {operatorNameColor.name}
            </button>
            <div className={`${styles.card__locale}`}>
                <div className={styles.card__header}>
                    <SelectEmotion
                        data={data}
                        postCallLabsegLabel={postCallLabsegLabel}
                        deleteCallLabels={deleteCallLabels}
                    />
                    <EditLabsegMenu
                        handleTogglePlay={handleTogglePlay}
                        isOpen={isOpen}
                        handleToggleOpen={handleToggleOpen}
                        handleClose={handleClose}
                        data={data}
                        handleEditText={handleEditText}
                    />
                </div>
                <button
                    className={styles.card__text}
                    onClick={handleMovePlay}
                >
                    {data.text}
                </button>
            </div>
        </div>
    );
};

export default TextCard;
