import React from 'react';
import styles from './styles.module.scss';
import * as buffer from "node:buffer";

export enum EnumCardType {
    BASIC = 1,
    SMALL = 2,
}

interface IProps {
    title: string;
    amount?: number;
    limit?: number;
    description: string;
    type?: EnumCardType;
    className?:string;
}

const GeneralInfoCardEmpty = ({title, amount, description, type=EnumCardType.BASIC, className, limit}:IProps) => {
    switch (type) {
        case EnumCardType.BASIC:
            return (
                <div className={`${styles.info} ${className}`}>
                    <h2 className={styles.info__title}>
                        {title}
                    </h2>
                    <div className={styles.info__footer}>
                        <h2 className={styles.info__amount}>
                            {amount}
                            <span className={styles.info__limit}>
                                / {limit}
                            </span>
                        </h2>
                        <span className={styles.info__description}>
                    {description}
                </span>
                    </div>
                </div>
            )
        case EnumCardType.SMALL:
            return (
                <div className={`${styles.small} ${className}`}>
                    <h2 className={styles.small__title}>
                        {title}
                    </h2>
                    <div className={styles.small__footer}>
                        <h2 className={styles.small__amount}>
                            {amount}
                            <span className={styles.info__limit}>
                                / {limit}
                            </span>
                        </h2>
                        <span className={styles.small__description}>
                            {description}
                        </span>
                    </div>
                </div>
            )
        default:
            return (
                <div className={styles.info}>
                    <h2 className={styles.info__title}>
                        {title}
                    </h2>
                    <div className={styles.info__footer}>
                        <h2 className={styles.info__amount}>
                            {amount}
                            <span className={styles.info__limit}>
                                / {limit}
                            </span>
                        </h2>
                        <span className={styles.info__description}>
                            {description}
                        </span>
                    </div>
                </div>
            )
    }
};

export default GeneralInfoCardEmpty;
