import {EnumRequestStatus} from "../resources/IRequest.resource";

export const RequestStatusFactory  = (type: EnumRequestStatus | 'ALL') => {
    switch (type) {
        case EnumRequestStatus.NEW:
            return {
                name: 'Очікує підтвердження',
                filterName: 'Очікують підтвердження',
                color: '#FFE8BC'
            }
        case EnumRequestStatus.DONE:
            return {
                name: 'Активний',
                filterName: 'Активні',
                color: '#CCFFD1'
            }
        case EnumRequestStatus.SUSPEND:
            return {
                name: 'Призупинений',
                filterName: 'Призупинені',
                color: '#D5DAF5'
            }
        case EnumRequestStatus.CANCEL:
            return {
                name: 'Відхилено',
                filterName: 'Відхилені',
                color: '#BDBDBD'
            }
        default:
            return {
                name: 'Всі',
                filterName: 'Всі',
                color: '#FFBCBC'
            }
    }
}

export const REQUEST_STATUS_LIST:EnumRequestStatus[] = Object.values(EnumRequestStatus)
