import React from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import styles from './styles.module.scss';
import {useNavigate} from "react-router-dom";
import ROUTES from "../../../../routes";

interface IProps {
    handleClose: () => void;
}

const ModalSuccessCreateCompany = ({handleClose}:IProps) => {
    const navigate = useNavigate();

    const handleApply = () => {
        navigate(ROUTES.LOGIN);
        handleClose();
    };

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <div>
                    <h2 className={styles.modal__title}>
                        Спасибі за реєстрацію! Протягом одного робочого дня з Вами зв'яжеться представник нашої команди та надасть доступи до тестування Harmonica
                    </h2>
                </div>
                <div className={styles.modal__footer}>
                    <Button
                        text={'Ok'}
                        onClick={handleApply}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalSuccessCreateCompany;
