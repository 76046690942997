import React, {useEffect, useState} from 'react';
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import SignupObserver, {ISignupInfo} from "../../mobx/signup/SignupObserver";
import styles from './styles.module.scss';
import FetchService from "../../services/api/FetchService";
import TextButton from "../../components/common/TextButton";
import ModalSuccessCreateCompany from "./components/ModalSuccessCreateCompany";

const CreateCompanyPage = () => {
    const [fields, setFields] = useState({
        companyName: '',
        comment: ''
    });
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleOpenModal = () => setIsOpenModal(true);
    const handleCloseModal = () => setIsOpenModal(true);

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id;
        const value = e.target.value;

        setFields((prev) => {
            return {
                ...prev,
                [id]: value
            }
        });
    };

    const handleApply = async () => {
        const signupInfo = SignupObserver.getSignUpInfo();
        if(!signupInfo) return;

        const body:ISignupInfo = {
            ...signupInfo,
            companyName: fields.companyName,
            comment: fields.comment
        };

        try {
            await FetchService.postNewLead(body);
            SignupObserver.clearSignUpInfo();
        } finally {
            handleOpenModal();
        }

    };

    const handleBack = () => {
        SignupObserver.setSignUpInfo({
            companyName: fields.companyName,
            comment: fields.comment
        });

        navigate(-1);
    };

    useEffect(() => {
        const signupInfo = SignupObserver.getSignUpInfo();
        if(!signupInfo) return;

        setFields({
            comment: signupInfo.comment ? signupInfo.comment : '',
            companyName: signupInfo.companyName ? signupInfo.companyName : ''
        });

    },[]);

    const isDisabled = () => {
        return !fields.companyName;
    };

    return (
        <>
            {isOpenModal ? (
                <ModalSuccessCreateCompany handleClose={handleCloseModal} />
            ) : null}
            <div className={styles.company}>
                <div className={styles.company__card}>
                    <div className={styles.company__header}>
                        <h2 className={styles.company__title}>
                            Company info
                        </h2>
                        <p className={styles.company__text}>
                            A user is a person who utilizes a computer or network service. A user often has a user account and is identified to the system by a username (or user name).[a]
                            Some software products provide services to other systems and have no direct end users.
                        </p>
                    </div>
                    <div className={styles.company__body}>
                        <div className={styles.company__avatar}>
                            AA
                        </div>
                        <div className={styles.company__left}>
                            <div className={styles.company__grid}>
                                <div className={styles.company__line}>
                                    <div className={styles.company__input}>
                                        <Input
                                            placeholder={'Company name *'}
                                            value={fields.companyName}
                                            id={'companyName'}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <Input
                                    placeholder={'Description'}
                                    value={fields.comment}
                                    id={'comment'}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.company__footer}>
                                <div className={styles.company__apply}>
                                    <TextButton
                                        text={'Back'}
                                        onClick={handleBack}
                                    />
                                </div>
                                <div className={styles.company__apply}>
                                    <Button
                                        text={'Apply'}
                                        onClick={handleApply}
                                        disabled={isDisabled()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(CreateCompanyPage);
