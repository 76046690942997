import React, {useEffect, useState} from 'react';
import EmotionColorNameFactory from "../../../../constants/EmotionColorNameFactory";
import {EnumEmotionType, ILabSegResource, IStaticRegionResource} from "../../../../resources/ICallDetails.resource";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import styles from './styles.module.scss';

const IconCheck = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2573 7.22745C19.5502 7.52033 19.5503 7.9952 19.2574 8.28811L10.773 16.7734C10.6324 16.9141 10.4416 16.9931 10.2427 16.9931C10.0437 16.9931 9.85297 16.9141 9.71231 16.7735L5.46967 12.5308C5.17678 12.2379 5.17678 11.7631 5.46967 11.4702C5.76256 11.1773 6.23744 11.1773 6.53033 11.4702L10.2426 15.1824L18.1967 7.22751C18.4895 6.9346 18.9644 6.93458 19.2573 7.22745Z" fill="#1E1E1E"/>
        </svg>
    )
}

const initialEmotions = [
    {
        title: EmotionColorNameFactory(EnumEmotionType.problem).name,
        value: EnumEmotionType.problem
    },
    {
        title: EmotionColorNameFactory(EnumEmotionType.emotions).name,
        value: EnumEmotionType.emotions
    },
    {
        title: EmotionColorNameFactory(EnumEmotionType.spend).name,
        value: EnumEmotionType.spend
    },
    {
        title: EmotionColorNameFactory(EnumEmotionType.solution).name,
        value: EnumEmotionType.solution
    }
];

interface IProps {
    data:  ILabSegResource;
    postCallLabsegLabel: (labsegId: number, emotion: EnumEmotionType) => void;
    deleteCallLabels: (labsegId: number, labelIds: number[]) => void;
}

const SelectEmotion = ({data, postCallLabsegLabel, deleteCallLabels}:IProps) => {
    const [isOpen, setIOpen] = useState(false);
    const [labsegs, setLabsegs] = useState<IStaticRegionResource[]>([]);

    const handleToggleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIOpen(true)
    };
    const handleClose = () => setIOpen(false);
    const handleSelect = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        const emotion = e.currentTarget.name;
        const value = !!e.currentTarget.id;
        if(value) {
            const labelIds = data.staticRegions.filter((item) => item.name === emotion || item.name === 'emotions' as EnumEmotionType || item.name === 'unselect' as EnumEmotionType )
                .map((unit) => unit.id);
            deleteCallLabels(data.id, labelIds);
            return;
        }

        postCallLabsegLabel(data.id, emotion as EnumEmotionType);
        handleClose();
    };
    const ref = useOutsideClick(handleClose);

    const getEmotions = () => {
        if(!data.staticRegions.length) {
            return [EmotionColorNameFactory(EnumEmotionType.unselect)];
        } else {
            return data.staticRegions.map((item) => EmotionColorNameFactory(item.name)).filter((unit) => unit.name !== EnumEmotionType.unselect);
        }
    };

    const buttonsList = () => {
        const emotionsList =  data.staticRegions.map((item) => item.name);
        const uniqueEmotions = Array.from(new Set(emotionsList));
        const validUniqueEmotions = uniqueEmotions.filter((unit) => unit !== EnumEmotionType.unselect && unit !== 'emotions' as EnumEmotionType);

        if(validUniqueEmotions.length >= 1) {
            return uniqueEmotions.filter((unit) => unit !== EnumEmotionType.unselect && unit !== 'emotions' as EnumEmotionType)
                .map((item) => EmotionColorNameFactory(item));
        }

        return [EmotionColorNameFactory(EnumEmotionType.unselect)];
    }

    useEffect(() => {
        setLabsegs(data.staticRegions);
    }, [data]);

    return (
        <div
            className={styles.select}
            ref={ref}
        >
            {buttonsList().map((item) => (
                <button
                    key={item.name}
                    className={styles.select__btn}
                    type='button'
                    style={{background: item.color}}
                    onClick={handleToggleOpen}
                >
                    {item.name}
                </button>
            ))}
            {isOpen ? (
                <div className={styles.select__list}>
                    {initialEmotions.map((item) => {
                        return (
                            <button
                                type={'button'}
                                className={`${styles.select__item} ${getEmotions().find((unit) => unit.name === item.value) ? styles.select__item_active : ''}`}
                                name={item.value}
                                id={getEmotions().find((unit) => unit.name === item.value) ? '1' : ''}
                                onClick={handleSelect}
                            >
                                <div className={styles.select__emotion}>
                                    <div
                                        className={styles.select__circle}
                                        style={{background: EmotionColorNameFactory(item.value).color}}
                                    />
                                    {item.title}
                                </div>
                                {getEmotions().find((unit) => unit.name === item.value) ? <IconCheck/> : null}
                            </button>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

export default SelectEmotion;
