import React, {useEffect, useState} from 'react';
import UserInfo from "../../components/UserInfo";
import GoBackButton from "../../components/common/GoBackButton";
import Input from "../../components/common/Input";
import TextButton from "../../components/common/TextButton";
import Button from "../../components/common/Button";
import useQProfile from "../../queries/useQProfile";
import {useNavigate} from "react-router-dom";
import styles from './styles.module.scss';
import FetchService from "../../services/api/FetchService";
import Loader from "../../components/Loader";
import {toast} from "react-toastify";
import {ErrorToastSettings, SuccessToastSettings} from "../../services/toast/toastSettings";

const ProfilePage = () => {
    const [fields, setFields] = useState({
        login: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isServerError, setIsServerError] = useState<boolean>(false);

    const {profile} = useQProfile();
    const navigate = useNavigate();

    const initializeData = () => {
        if(profile) {
            setFields((prev) => {
                return {
                    ...prev,
                    login: profile.userName,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    email: profile.email
                }
            })
        }
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id;
        const value = e.target.value;

        setFields((prev) => {
            return {
                ...prev,
                [id]: value
            }
        });
    };

    const handleCancel = () => {
        navigate(-1);
        initializeData();
    };

    useEffect(() => {
        initializeData()
    },[profile]);

    const handleSubmitChangePassword = async () => {
        if(fields.newPassword !== fields.confirmPassword) {
            setIsError(true);
            return;
        }
        setIsError(false);

        try {
            setIsLoading(true);
            await FetchService.putChangeMyPassword(encodeURIComponent(fields.oldPassword), encodeURIComponent(fields.newPassword), encodeURIComponent(fields.confirmPassword))
            setIsServerError(false)
            toast.success('Password successfully changed!', SuccessToastSettings)
        } catch (error) {
            toast.error('Something goes wrong!', ErrorToastSettings);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? <Loader/> : null}
            <div className={styles.profile}>
                <div className={styles.profile__header}>
                    <GoBackButton />
                    <UserInfo />
                </div>
                <div className={styles.profile__card}>
                    <div className={styles.profile__avatar}>
                        R
                    </div>
                    <div className={styles.profile__left}>
                        <div className={styles.profile__inputs}>
                            <div className={styles.profile__input}>
                                <Input
                                    placeholder={'Login'}
                                    value={fields.login}
                                    id={'login'}
                                    onChange={handleChange}
                                    disabled={true}
                                />
                            </div>
                            <div className={styles.profile__grid}>
                                <div className={styles.profile__line}>
                                    <div className={styles.profile__input}>
                                        <Input
                                            placeholder={'First name'}
                                            value={fields.firstName}
                                            id={'firstName'}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className={styles.profile__input}>
                                        <Input
                                            placeholder={'Last name'}
                                            value={fields.lastName}
                                            id={'lastName'}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={styles.profile__line}>
                                    <div className={styles.profile__input}>
                                        <Input
                                            placeholder={'Email'}
                                            value={fields.email}
                                            id={'email'}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className={styles.profile__input}>
                                        <Input
                                            placeholder={'Phone'}
                                            value={fields.phone}
                                            id={'phone'}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={styles.profile__line}>
                                    <div className={styles.profile__input}>
                                        <Input
                                            placeholder={'Old password'}
                                            value={fields.oldPassword}
                                            id={'oldPassword'}
                                            onChange={handleChange}
                                            type={'password'}
                                        />
                                    </div>
                                    <div className={styles.profile__input}>
                                        <Input
                                            placeholder={'New password'}
                                            value={fields.newPassword}
                                            id={'newPassword'}
                                            onChange={handleChange}
                                            type={'password'}
                                        />
                                    </div>
                                    <div className={styles.profile__input}>
                                        <Input
                                            placeholder={'Confirm password'}
                                            value={fields.confirmPassword}
                                            id={'confirmPassword'}
                                            onChange={handleChange}
                                            type={'password'}
                                            errorText={isError ? 'Passwords do not match' : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.profile__footer}>
                                <TextButton
                                    text={'Cancel'}
                                    onClick={handleCancel}
                                />
                                <div className={styles.profile__apply}>
                                    <Button
                                        text={'Apply'}
                                        onClick={handleSubmitChangePassword}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfilePage;
