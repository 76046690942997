const ROUTES = {
    LOGIN: '/login',
    REGISTER: '/create-user',
    CREATE_COMPANY: '/create-company',
    HOME: '/',
    CALL_DETAILS: '/call/:id',
    PROFILE: '/profile',
    CREATE_PASSWORD: '/set-password',
    RESET_PASSWORD_EMAIL: '/reset-password-email'
}

export default ROUTES;
