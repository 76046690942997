import React from 'react';
import styles from './styles.module.scss';

interface IProps {
    text?: string;
    onClick?: (e:React.MouseEvent<HTMLButtonElement>) => void;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
}

const Button = ({text, onClick, type='button', disabled=false}:IProps) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={styles.button__button}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default Button;
