import {useQuery} from "@tanstack/react-query";
import QueryKeyFactory from "./QueryKeyFactory";
import {AxiosResponse} from "axios";
import FetchService from "../services/api/FetchService";
import {ICompaniesResource} from "../resources/ICompany.resource";

const useQCompanies = () => {

    const {data, isFetching} = useQuery({
        queryKey: QueryKeyFactory.companies(),
        queryFn: () => FetchService.getCompanies(),
        select: (response:AxiosResponse<ICompaniesResource | null>) => response.data,
        staleTime: 0
    });

    return {
        companies: data ? data : null,
        isLoading: isFetching
    }
};

export default useQCompanies;
