import {EnumSpeakerType} from "../resources/ICallDetails.resource";

const SpeakerColorNameFactory = (type: EnumSpeakerType) => {
    switch (type) {
        case EnumSpeakerType.CLIENT:
            return {
                name: 'Client',
                color: '#E0E5EE'
            }
        case EnumSpeakerType.OPERATOR:
            return {
                name: 'Operator',
                color: '#E0EEEB'
            }
        default:
            return {
                name: 'Speaker',
                color: '#E0E5EE'
            }
    }
};

export default SpeakerColorNameFactory;
