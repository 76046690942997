import {makeAutoObservable} from 'mobx';

class GlobalObserver {
    companyId: number | null = null;

    constructor() {
        makeAutoObservable(this)
    }

    setCompanyId = (companyId: number) => {
        this.companyId = companyId;
    }

    getCompanyId = () => {
        if(!this.companyId) {
            return Number(localStorage.getItem('company'));
        }
        return this.companyId
    };
}

export default new GlobalObserver();
