import React from 'react';
import styles from './styles.module.scss';
import ModalContainer from "../../../../components/ModalContainer";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";

interface IProps {
    handleClose: () => void;
    handleApply: () => void;
}

const ModalReAnalyzeCall = ({handleClose, handleApply}:IProps) => {

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <div>
                    <h2 className={styles.modal__title}>
                        Бажаєте відправити дзвінок на повторне розпізнавання?
                    </h2>
                </div>
                <div className={styles.modal__footer}>
                    <TextButton
                        text={'Cancel'}
                        onClick={handleClose}
                    />
                    <Button
                        text={'Apply'}
                        onClick={handleApply}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalReAnalyzeCall;
