import React from 'react';
import styles from './styles.module.scss';
import {NavLink} from "react-router-dom";

interface IProps {
    text?:string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    variant?: 'text' | 'link';
    type?: 'button' | 'submit' | 'reset';
    to?:string
}

const TextButton = ({text, onClick, type='button', variant='text', to='/'}:IProps) => {
    switch (variant) {
        case 'link':
            return (
                <NavLink
                    to={to}
                    className={styles.button__button}
                >
                    {text}
                </NavLink>
            )
        default:
            return (
                <button
                    className={styles.button__button}
                    type={type}
                    onClick={onClick}
                >
                    {text}
                </button>
            )
    }
};

export default TextButton;
