import React, {useEffect, useState} from 'react';
import GeneralInfoCardEmpty, {EnumCardType} from "../GeneralInfoCardEmpty";
import UploadFilesBtnEmpty from "../UploadFilesBtnEmpty";
import FetchService from "../../../../services/api/FetchService";
import CompanyObserver from "../../../../mobx/company/CompanyObserver";
import {ICompanyBalancesResource} from "../../../../resources/ICompanyBalances.resource";
import Loader from "../../../../components/Loader";
import styles from './styles.module.scss';

interface IProps {
    refresh: () => void;
}

const InfoHeader = ({refresh}:IProps) => {
    const [companyBalances, setCompanyBalances] = useState<ICompanyBalancesResource | null>(null)
    const companyId =  CompanyObserver.getCompanyId();

    const getCompanyBalances = async () => {
        if(!companyId) return;
        try {
            const {data} = await FetchService.getCompanyBalance(companyId);
            setCompanyBalances(data);
        } finally {}
    };

    useEffect(() => {
        getCompanyBalances();
    },[companyId])

    if(!companyBalances) {
        return <Loader/>;
    }

    const isDisabled = () => {
        if(!companyBalances) return false

        return companyBalances.balance.maxTotalPhoneCallsDurationSec <=0 || companyBalances.balance.maxTotalPhoneCalls <= 0;
    };

    const getMinutes = (amount:number) => {
        if(!companyBalances) return 0;

        return Math.ceil(amount / 60 )
    }

    return (
        <div className={styles.info__header}>
            <GeneralInfoCardEmpty
                className={styles.info__card}
                title={'Дзвінки'}
                limit={companyBalances?.limits?.maxTotalPhoneCalls}
                amount={companyBalances?.balance?.maxTotalPhoneCalls}
                description={'доступно'}
                type={EnumCardType.SMALL}
            />
            <GeneralInfoCardEmpty
                className={styles.info__card}
                title={'Тривалість дзвінків'}
                limit={getMinutes(companyBalances?.limits?.maxTotalPhoneCallsDurationSec)}
                amount={getMinutes(companyBalances?.balance?.maxTotalPhoneCallsDurationSec)}
                description={'доступно хвилин дзвінків'}
                type={EnumCardType.SMALL}
            />
            <UploadFilesBtnEmpty
                refresh={refresh}
                disabled={isDisabled()}
                isMain={false}
                className={styles.info__card}
            />
        </div>
    );
};

export default InfoHeader;
