export interface IProfileResource {
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly id: number;
    readonly roles: []; // TODO
    readonly userName: string;
    readonly uuid: string;
    readonly modules: EnumModules[];
}

export enum EnumModules {
    PHONE_CALL_AUTO = 'PHONE_CALL_AUTO',
    PHONE_CALL_AUTO_TEST = 'PHONE_CALL_AUTO_TEST',
    LEAD_GENERATION = 'LEAD_GENERATION'
}

