import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import useOutsideClick from "../../hooks/useOutsideClick";
import useQProfile from "../../queries/useQProfile";
import styles from './styles.module.scss';
import StringService from "../../services/StringService";

const ArrowDownIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13 6L8 11L3 6" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

const ProfileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z" stroke="#1E1E1E" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M2.90625 20.2499C3.82775 18.6534 5.15328 17.3277 6.74958 16.406C8.34588 15.4843 10.1567 14.999 12 14.999C13.8433 14.999 15.6541 15.4843 17.2504 16.406C18.8467 17.3277 20.1722 18.6534 21.0938 20.2499" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};

const LogOutIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7.6875 15.9375L3.75 12L7.6875 8.0625" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.25 12L3.75 12" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.25 3.75L19.5 3.75C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5L20.25 19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25L14.25 20.25" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};

const UserInfo = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggleOpen = () => setIsOpen((prev) => !prev);
    const handleClose = () => setIsOpen(false);

    const containerRef = useOutsideClick(handleClose);
    const {profile, error} = useQProfile();
    const navigate = useNavigate();

    const handleRedirectProfile = () => {
        navigate(ROUTES.PROFILE)
        handleClose();
    };
    const handleLogout = () => {
        localStorage.clear();
        navigate(ROUTES.LOGIN);
        handleClose();
    };

    return (
        <div
            ref={containerRef}
            className={styles.info}
        >
            <button
                className={styles.info__info}
                type={'button'}
                onClick={handleToggleOpen}
            >
                <div className={styles.info__avatar}>
                    {profile ? profile.firstName[0] : 'U'}
                </div>
                <span
                    className={styles.info__name}
                    title={profile?.firstName}
                >
                     {profile ? StringService.cutString(profile.firstName, 7) : 'user'}
                </span>
                <ArrowDownIcon />
            </button>
            {isOpen ? (
                <ul className={styles.info__list}>
                    <li>
                        <button
                            className={styles.info__item_profile}
                            type='button'
                            onClick={handleRedirectProfile}
                        >
                            <ProfileIcon />
                            <span className={styles.info__text}>
                                Профіль
                            </span>
                        </button>
                    </li>
                    <li>
                        <button
                            className={styles.info__item}
                            type='button'
                            onClick={handleLogout}
                        >
                            <LogOutIcon />
                            <span className={styles.info__text}>
                                Вийти
                            </span>
                        </button>
                    </li>
                </ul>
            ) : null}
        </div>
    );
};

export default UserInfo;
