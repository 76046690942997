import axiosInstance from "./axiosInstance";
import UrlCreator from "./UrlCreator";
import {AxiosResponse} from "axios";
import {EnumModules, IProfileResource} from "../../resources/IProfile.resource";
import {ICompaniesResource} from "../../resources/ICompany.resource";
import {IEmployeeResource, IEmployeesResource} from "../../resources/IEmployee.resource";
import {ICallDetailsResource} from "../../resources/ICallDetails.resource";
import {ISignupInfo} from "../../mobx/signup/SignupObserver";
import {ICompanyBalancesResource} from "../../resources/ICompanyBalances.resource";
import {ICallResource} from "../../resources/ICall.resource";
import {IRequestResource} from "../../resources/IRequest.resource";
import * as async_hooks from "node:async_hooks";

export interface IPostCallsVariables {
    companyId: number;
    employeeId: number;
    encodedPhoneNumber: string;
    phoneTime: string;
}

export interface IPostPassword {
    otp: string;
    newPassword: string;
    confirmNewPassword: string;
}

export interface IPostResetPasswordEmail {
    email?:string;
    userName?: string;
}

export interface IPatchRequest {
    companyLimits?: {
        maxTotalPhoneCalls?: number;
        maxTotalPhoneCallsDurationSec?: number;
    }
    comment?:string;
}

class FetchService {
    getProfile = async ():Promise<AxiosResponse<IProfileResource>> => {
        return await axiosInstance.get(UrlCreator.getProfile())
    }

    getMyEmployee = async (companyId:number):Promise<AxiosResponse<IEmployeeResource>> => {
        return await axiosInstance.get(UrlCreator.getMyEmployee(companyId))
    }

    getCompanies = async ():Promise<AxiosResponse<ICompaniesResource | null>> => {
        const data = await this.getProfile();
        if (data.data.modules.includes(EnumModules.LEAD_GENERATION)) {
            // @ts-ignore
            return null;
        }

        return await axiosInstance.get(UrlCreator.getCompanies())
    }

    getEmployees = async (companyId: number, page: number, searchRequest:string):Promise<AxiosResponse<IEmployeesResource>> => {
        return await axiosInstance.get(UrlCreator.getAllEmployees(companyId), {
            params: {
                pageToken: page,
                pageSize: 50,
                searchRequest: searchRequest
            }
        })
    }

    getCompanyBalance = async (companyId: number):Promise<AxiosResponse<ICompanyBalancesResource>> => {
      return await axiosInstance.get(UrlCreator.getCompanyBalance(companyId))
    };

    getAllCalls = async (companyId:number):Promise<AxiosResponse<{calls: ICallResource[]}>> => {
        return await axiosInstance.get(UrlCreator.getAllCalls(companyId));
    };

    getRequests = async (params:any):Promise<AxiosResponse<{total: number, requests:IRequestResource[]}>> => {
        return await axiosInstance.get(UrlCreator.getRequests(), {params: params});
    }

    patchCallDecoding = async (companyId: number, callId:string):Promise<AxiosResponse<ICallDetailsResource>> => {
        return await axiosInstance.patch(UrlCreator.patchDecodingCall(companyId, callId));
    };

    patchRequestApprove = async (requestId: number, body: any):Promise<AxiosResponse<IRequestResource>> => {
        return await axiosInstance.patch(UrlCreator.patchRequestApprove(requestId), body);
    };

    patchRequestChangeStatus = async (requestId: number, status: string):Promise<AxiosResponse<IRequestResource>> => {
        return await axiosInstance.patch(UrlCreator.patchChangeStatus(requestId, status));
    };

    patchRequestEditLimits = async (requestId: number, body:IPatchRequest):Promise<AxiosResponse<IRequestResource>> => {
        return await axiosInstance.patch(UrlCreator.patchEditRequest(requestId), body);
    };

    putCallLabseg = async (companyId: number, phoneCallUuid:string, labsegId:number, labelId:number, body: any):Promise<AxiosResponse<{id:number, name: string, value:string}>> => {
        return await axiosInstance.put(UrlCreator.putCallLabseg(companyId, phoneCallUuid, labsegId, labelId), body)
    }

    putChangeMyPassword = async (oldPassword:string, newPassword:string, confirmNewPassword:string) => {
        return await axiosInstance.put(UrlCreator.putChangeMyPassword(oldPassword, newPassword, confirmNewPassword), )
    }

    patchCallNotes = async (companyId: number, phoneCallUuid:string, notes:string) => {
        return await axiosInstance.patch(UrlCreator.patchCallNotes(companyId, phoneCallUuid, notes))
    }

    postNewLead = async (body: ISignupInfo) => {
        return await axiosInstance.post(UrlCreator.postNewLead(), body);
    }

    postCalls = async (variables: IPostCallsVariables, body:FormData) => {
        const {companyId, employeeId,encodedPhoneNumber,phoneTime} = variables;
        return await axiosInstance.post(UrlCreator.postCalls(companyId, employeeId, encodedPhoneNumber, phoneTime), body, {headers: {'Content-Type': 'multipart/form-data'}});
    }

    postPassword = async (body:IPostPassword) => {
        return await axiosInstance.post(UrlCreator.postPasswordChange(), body);
    };

    postRefreshEmployees = async () => {
        return await axiosInstance.post(UrlCreator.postRefreshEmployees());
    }

    postResetPasswordEmail = async (body:IPostResetPasswordEmail) => {
        return await axiosInstance.post(UrlCreator.postPasswordResetEmail(), body);
    };

    deleteLabsegLabels = async (companyId:number, callId: string, labsegId:number, labelIds: number[]) => {
        return await axiosInstance.delete(UrlCreator.deleteCallLabels(companyId, callId, labsegId, labelIds));
    };

}

export default new FetchService();
