import React from 'react';
import styles from './styles.module.scss';

const Loader = () => {
    return (
        <div className={styles.loader__overlay}>
            <div className={styles.loader}/>
        </div>
    );
};

export default Loader;
