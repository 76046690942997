import React, {useEffect, useState} from 'react';
import {EnumCallStatus, ICallResource} from "../../../../resources/ICall.resource";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import StatusNameColorFactory from "../../../../constants/StatusNameColorFactory";
import {ICallDetailsResource} from "../../../../resources/ICallDetails.resource";
import ModalChangeStatus from "../ModalChangeStatus";
import styles from './styles.module.scss';

const SelectedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2573 7.22745C19.5502 7.52033 19.5503 7.9952 19.2574 8.28811L10.773 16.7734C10.6324 16.9141 10.4416 16.9931 10.2427 16.9931C10.0437 16.9931 9.85297 16.9141 9.71231 16.7735L5.46967 12.5308C5.17678 12.2379 5.17678 11.7631 5.46967 11.4702C5.76256 11.1773 6.23744 11.1773 6.53033 11.4702L10.2426 15.1824L18.1967 7.22751C18.4895 6.9346 18.9644 6.93458 19.2573 7.22745Z" fill="#1E1E1E"/>
        </svg>
    )
};

export const initialStatusData = [
    {
        label: 'Не потребує уваги',
        value: EnumCallStatus.ACCEPTED,
        isSelected: false,
    },
    {
        label: 'В роботі',
        value: EnumCallStatus.IN_PROGRESS,
        isSelected: false,
    },
    {
        label: 'Вирішено',
        value: EnumCallStatus.RESOLVED,
        isSelected: false,
    },
    {
        label: 'Не вибрано',
        value: EnumCallStatus.NEW,
        isSelected: false,
    }
];

export interface IStatusItem {
    label: string;
    value: EnumCallStatus;
    isSelected: boolean;
}

interface IProps {
    data: ICallResource | ICallDetailsResource;
    handlePatchStatus: (callId:string, status: string) => void;
    classNameContainer?: string;
}

const TdStatus = ({data, handlePatchStatus, classNameContainer}:IProps) => {
    const [statuses, setStatuses] = useState<IStatusItem[]>([]);
    const [selectedStatusId, setSelectedStatusId] = useState<string>();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleOpenModal = () => setIsOpenModal(true);
    const handleCloseModal = () => setIsOpenModal(false);

    const ref = useOutsideClick(handleClose);

    useEffect(() => {
        setStatuses(initialStatusData.map((item:IStatusItem) => {
            return item.value === data.state ? {...item, isSelected: true} : item;
        }))
    },[data]);

    const handleSelectStatus = (e:React.MouseEvent<HTMLButtonElement>) => {
        const id:string = e.currentTarget.id;
        setSelectedStatusId(id)

        handleOpenModal();
        handleClose();
    }

    return (
        <>
            {isOpenModal ? (
                <ModalChangeStatus
                    data={data}
                    statuses={statuses}
                    setStatuses={setStatuses}
                    handleClose={handleCloseModal}
                    handlePatchStatus={handlePatchStatus}
                    selectedStatusId={selectedStatusId}
                />
            ) : null}
            <div
                ref={ref}
                className={`${styles.status__container} ${classNameContainer}`}
            >
                <button
                    type={'button'}
                    className={`${styles.status} ${isOpen ? styles.status__active : ''}`}
                    style={{background: StatusNameColorFactory(data.state).color}}
                    onClick={handleOpen}
                >
                    {StatusNameColorFactory(data.state).name}
                </button>
                {data.state !== 'NEW' ? (
                    <span className={styles.status__employee}>
                    {data.lastActivity.who.split(' ')[0]}{' '}{data.lastActivity.who.split(' ')[1]}
                </span>
                ) : null}
                {isOpen ? (
                    <div className={styles.status__list}>
                        {statuses.map((item:IStatusItem) => (
                            <button
                                type={'button'}
                                className={`${styles.status__item} ${item.isSelected ? styles.status__item_active : ''}`}
                                id={item.value}
                                onClick={handleSelectStatus}
                            >
                                <div className={styles.status__label}>
                                    <div
                                        className={styles.status__circle}
                                        style={{
                                            background: StatusNameColorFactory(item.value).color
                                        }}
                                    />
                                    {item.label}
                                </div>
                                {item.isSelected ? <SelectedIcon /> : null}
                            </button>
                        ))}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default TdStatus;
