import React from 'react';
import styles from './styles.module.scss';

const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <g opacity="0.6">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.66475 4.66475C4.88442 4.44508 5.24058 4.44508 5.46025 4.66475L13.3352 12.5398C13.5549 12.7594 13.5549 13.1156 13.3352 13.3352C13.1156 13.5549 12.7594 13.5549 12.5398 13.3352L4.66475 5.46025C4.44508 5.24058 4.44508 4.88442 4.66475 4.66475Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M13.3352 4.66475C13.1156 4.44508 12.7594 4.44508 12.5398 4.66475L4.66475 12.5398C4.44508 12.7594 4.44508 13.1156 4.66475 13.3352C4.88442 13.5549 5.24058 13.5549 5.46025 13.3352L13.3352 5.46025C13.5549 5.24058 13.5549 4.88442 13.3352 4.66475Z" fill="white"/>
            </g>
        </svg>
    )
};

interface IProps {
    handleDelete: (type:string) => void;
    title: string;
    type: string;
}

const FilterTag = ({handleDelete, title, type}:IProps) => {
    const handleDeleteTag = () => handleDelete(type);

    return (
        <div className={styles.tag}>
            <span>
                {title}
            </span>
            <button
                className={styles.tag__delete}
                type={'button'}
                onClick={handleDeleteTag}
            >
                <DeleteIcon />
            </button>
        </div>
    );
};

export default FilterTag;
