import React, {forwardRef, useEffect} from 'react';
import {ICallResource} from "../../../../resources/ICall.resource";
import TdDate from "../TdDate";
import TdPhoneNumber from "../TdPhoneNumber";
import TdStatus from "../TdStatus";
import TdPeeked from "../TdPeeked";
import TdProblemResult from "../TdProblemResult";
import HeadFilter, {EnumSortType} from "../HeadFilter";
import TdNote from "../TdNote";
import styles from './styles.module.scss';
import ClickCopyBtn from "../ClickCopyBtn";

interface IProps {
    data: ICallResource[];
    handlePhoneFilter: (phoneNumber: string) => void;
    handlePatchStatus: (callId:string, status: string) => void;
    selectedSort: string;
    handleSelectSort: (value: string) => void;
    filter?:string;
    handleEditNotes: (callId:string | number, notes:string) => void;
    handleCheckCall: (e:React.ChangeEvent<HTMLInputElement>) => void;
    handleOpenFilterModal: () => void;
    handleOpenProblemResultModal: () => void;
}

const TableCalls = forwardRef(({data, handlePhoneFilter, handlePatchStatus, selectedSort, handleSelectSort, handleOpenFilterModal, filter, handleEditNotes, handleCheckCall, handleOpenProblemResultModal}:IProps, ref:any) => {

    const handleScroll = (e:React.UIEvent<HTMLElement>) => {
        const scrollTop = e.currentTarget.scrollTop;
        sessionStorage.setItem('scroll', JSON.stringify(scrollTop));
    };

    const handleScrollTo = () => {
        const scroll = sessionStorage.getItem('scroll');
        if(!scroll) return;
        ref?.current?.scrollTo(0, Number(scroll))
    }

    useEffect(() => {
        handleScrollTo();
    },[data])

    return (
        <>
            <div
                className={styles.table__container}
                id={'table-container'}
                ref={ref}
                onScroll={handleScroll}
            >
                <table
                    className={styles.table}
                    cellSpacing={0}
                    cellPadding={0}
                >
                    <thead className={styles.table__thead}>
                    <tr>
                        <td className={styles.table__cell_first}>
                            <div className={styles.table__id}>
                                <input
                                    id={'ALL'}
                                    type={'checkbox'}
                                    onChange={handleCheckCall}
                                />
                                ID
                            </div>
                        </td>
                        <td className={styles.table__cell_head}>
                            <HeadFilter
                                selectedSort={selectedSort}
                                handleSelectSort={handleSelectSort}
                                title={'Дата та час'}
                                type={EnumSortType.callTime}
                            />
                        </td>
                        <td className={styles.table__cell_head}>
                            Номер
                        </td>
                        <td className={styles.table__cell_head}>
                            <HeadFilter
                                selectedSort={selectedSort}
                                handleSelectSort={handleSelectSort}
                                title={'Оператор'}
                                type={EnumSortType.operator}
                                filter={filter}
                                isFilter={true}
                                handleOpenFilterModal={handleOpenFilterModal}
                            />
                        </td>
                        <td className={styles.table__cell_head}>
                            Статус
                        </td>
                        <td className={styles.table__cell_head}>
                            <HeadFilter
                                selectedSort={selectedSort}
                                handleSelectSort={handleSelectSort}
                                title={'Час в роботі'}
                                type={EnumSortType.duration}
                            />
                        </td>
                        <td className={styles.table__cell_head}>
                            <HeadFilter
                                selectedSort={selectedSort}
                                handleSelectSort={handleSelectSort}
                                title={'Оцінка'}
                                type={EnumSortType.problemResult}
                                isFilter={true}
                                handleOpenFilterModal={handleOpenProblemResultModal}
                            />
                        </td>
                        <td className={styles.table__cell_last}>
                            Нотатка
                        </td>
                    </tr>
                    </thead>
                    <tbody className={styles.table__tbody}>
                    {data.map((item:ICallResource) => (
                        <tr key={item.uuid}>
                            <td>
                                <div className={styles.table__id}>
                                    <input
                                        type={'checkbox'}
                                        checked={item.isSelected}
                                        id={item.uuid}
                                        onChange={handleCheckCall}

                                    />
                                    <ClickCopyBtn text={item.extId}/>
                                </div>
                            </td>
                            <td>
                                <TdDate
                                    date={item.callTime}
                                    duration={item.duration}
                                />
                            </td>
                            <td>
                                <TdPhoneNumber
                                    handlePhoneFilter={handlePhoneFilter}
                                    data={item}
                                />
                            </td>
                            <td>
                                {item.employee.fullName}
                            </td>
                            <td>
                                <TdStatus
                                    data={item}
                                    handlePatchStatus={handlePatchStatus}
                                />
                            </td>
                            <td>
                                <TdPeeked data={item} />
                            </td>
                            <td>
                                <TdProblemResult
                                    problemResult={item.problemResult}
                                    uuid={item.uuid}
                                />
                            </td>
                            <td>
                                <TdNote
                                    note={item.notes}
                                    entityId={item.uuid}
                                    handleEditNotes={handleEditNotes}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
});

export default TableCalls;
