class QueryKeyFactory {
    basic:string = 'query';

    profile = () => [this.basic, 'profile'];
    companies = () => [this.basic, 'companies'];
    employees = () => [this.basic, 'employees'];
    myEmployees = (companyId:number) => [this.basic, 'employee', companyId];
}

export default new QueryKeyFactory()
