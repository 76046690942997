import React, {useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import styles from './styles.module.scss';
import ModalEditLimits from "../ModalInitialLimits";
import {ILimitFields} from "../RequestStatus";
import {IRequestResource} from "../../../../resources/IRequest.resource";


const IconMenu = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
            d="M9.00178 9.818C9.45364 9.818 9.81996 9.45169 9.81996 8.99982C9.81996 8.54795 9.45364 8.18164 9.00178 8.18164C8.54991 8.18164 8.18359 8.54795 8.18359 8.99982C8.18359 9.45169 8.54991 9.818 9.00178 9.818Z"
            fill="#1E1E1E"/>
        <path
            d="M9.00178 5.45472C9.45364 5.45472 9.81996 5.08841 9.81996 4.63654C9.81996 4.18467 9.45364 3.81836 9.00178 3.81836C8.54991 3.81836 8.18359 4.18467 8.18359 4.63654C8.18359 5.08841 8.54991 5.45472 9.00178 5.45472Z"
            fill="#1E1E1E"/>
        <path
            d="M9.00178 14.1818C9.45364 14.1818 9.81996 13.8155 9.81996 13.3636C9.81996 12.9117 9.45364 12.5454 9.00178 12.5454C8.54991 12.5454 8.18359 12.9117 8.18359 13.3636C8.18359 13.8155 8.54991 14.1818 9.00178 14.1818Z"
            fill="#1E1E1E"/>
    </svg>
);

const IconResendRequest = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_858_14385)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.3438 10.2623C23.6367 10.5552 23.6367 11.03 23.3438 11.3229L19.8083 14.8585C19.5154 15.1514 19.0405 15.1514 18.7476 14.8585L15.2121 11.3229C14.9192 11.03 14.9192 10.5552 15.2121 10.2623C15.505 9.96939 15.9799 9.96939 16.2728 10.2623L18.7317 12.7212C18.8245 11.4168 18.5628 10.1068 17.9683 8.93227C17.268 7.54899 16.1424 6.42663 14.7571 5.72992C13.3719 5.03321 11.7999 4.79882 10.2719 5.06139C8.74388 5.32395 7.34046 6.0697 6.26721 7.18889C5.194 8.30802 4.50774 9.74168 4.30939 11.2794C4.11103 12.8172 4.41087 14.3776 5.16494 15.7324C5.91902 17.0872 7.08763 18.1647 8.49908 18.8065C8.87615 18.9779 9.04284 19.4226 8.8714 19.7996C8.69996 20.1767 8.2553 20.3434 7.87823 20.172C6.17472 19.3974 4.76436 18.097 3.85429 16.4619C2.94421 14.8269 2.58231 12.9435 2.82171 11.0875C3.06112 9.23153 3.88938 7.50127 5.18456 6.15067C6.4797 4.80012 8.17347 3.89998 10.0179 3.58305C11.8622 3.26614 13.7594 3.54907 15.4311 4.38986C17.1028 5.23063 18.4613 6.58514 19.3065 8.25478C19.9433 9.51267 20.2637 10.8994 20.2495 12.2959L22.2832 10.2623C22.5761 9.96939 23.0509 9.96939 23.3438 10.2623Z"
                  fill="#1E1E1E"/>
            <path
                d="M12.75 20.25C12.75 20.6642 12.4142 21 12 21C11.5858 21 11.25 20.6642 11.25 20.25C11.25 19.8358 11.5858 19.5 12 19.5C12.4142 19.5 12.75 19.8358 12.75 20.25Z"
                fill="#1E1E1E"/>
        </g>
        <defs>
            <clipPath id="clip0_858_14385">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

interface IProps {
    data:IRequestResource;
    handleEditStatus: (requestId: number, status:string, maxTotalPhoneCalls?: string, maxTotalPhoneCallsDurationSec?: string) => void;
}

const AdditionalMenuBtn = ({data, handleEditStatus}:IProps) => {
    const [isOpenMenu, setIsOpenMenu] = React.useState(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [fields, setFields] = React.useState<ILimitFields>({
        maxTotalPhoneCalls: '',
        maxTotalPhoneCallsDurationSec: ''
    });


    const handleOpenModal = () => setIsOpenModal(true);
    const handleCloseModal = () => setIsOpenModal(false);
    const handleToggleMenu = () => setIsOpenMenu((prev) => !prev);
    const handleCloseMenu = () => setIsOpenMenu(false);

    const ref = useOutsideClick(handleCloseMenu);

    return (
        <>
            {isOpenModal ? (
                <ModalEditLimits
                    requestId={data.id}
                    handleClose={handleCloseModal}
                    fields={fields}
                    setFields={setFields}
                    handleEditStatus={handleEditStatus}
                />
            ) : null}
            <div
                className={styles.additional__container}
                ref={ref}
            >
                <button
                    className={styles.additional__btn}
                    type="button"
                    onClick={handleToggleMenu}
                >
                    <IconMenu/>
                </button>
                {isOpenMenu ? (
                    <div className={styles.additional__list}>
                        <button
                            className={styles.additional__item}
                            type="button"
                            onClick={handleOpenModal}
                        >
                            <IconResendRequest/>
                            <span>Відправити запит</span>
                        </button>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default AdditionalMenuBtn;
