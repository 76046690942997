export enum EnumRequestStatus {
    DONE = 'DONE',
    NEW = 'NEW',
    SUSPEND = 'SUSPEND',
    CANCEL = 'CANCEL'
}

export interface IRequestResource {
    readonly id: number;
    readonly status: EnumRequestStatus;
    readonly local: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly companyName: string;
    readonly phoneNumber: string;
    readonly companyId: number;
    readonly companyTimezone: string;
    readonly companyLimits: ICompanyLimits;
    readonly companyBalance: ICompanyLimits;
    readonly companyUserId: number;
    readonly companyUser: string;
    readonly updatedBy: string;
    readonly created: string;
    readonly comment?: string;

}

export interface ICompanyLimits {
    [key: string]: number;
    readonly maxTotalPhoneCalls: number;
    readonly maxTotalChats: number;
    readonly maxTotalPhoneCallsDurationSec: number;
    readonly maxTotalChatsCharacters: number;
}
