import React from 'react';
import styles from './styles.module.scss';
import DateService from "../../../../services/DateService";

interface IProps {
    date: string;
    duration: number;
}

const TdDate = ({date, duration}:IProps) => {

    return (
        <div className={styles.date}>
            {DateService.formatUI(date, 'DD.MM.YYYY')}
            {' '}
            {DateService.formatUI(date, 'HH:mm')}
            {' '}
            {duration ? (
            <span className={styles.date__duration}>
                {DateService.convertSecondsToMinutes(duration)}
            </span>
            ) : null}
        </div>
    );
};

export default TdDate;
