import React from 'react';
import {ICallDetailsResource} from "../../../../resources/ICallDetails.resource";
import StatusNameColorFactory from "../../../../constants/StatusNameColorFactory";
import ProblemResultColorFactory from "../../../../constants/ProblemResultColorFactory";
import TdStatus from "../../../AutomaticAssessmentPage/components/TdStatus";
import styles from './styles.module.scss';
import CallAdditionalInfo from "../CallAdditionalnfo";
import CallActivities from "../CallActivities";
import dayjs from "dayjs";
import ReAnalyzeCall from "../ReanalyzeCall";
import TdNote from "../../../AutomaticAssessmentPage/components/TdNote";

interface IProps {
    data: ICallDetailsResource;
    handlePatchStatus: (callId:string, status: string) => void;
    setCallDetails: (data:ICallDetailsResource) => void;
    handleEditNotes: (callId: string | number, notes:string) => void;
}

const CallGeneralInfo = ({data, handlePatchStatus, setCallDetails, handleEditNotes}:IProps) => {

    const getLastInProgressTime = () => {
        const start = dayjs(data.callTime);
        const end = dayjs(data.resolved ? data.resolved : new Date());
        const seconds = Math.abs(end.diff(start, 's'));
        const minutes = Math.abs(end.diff(start, 'm'));
        const hours = Math.abs(end.diff(start, 'h'));
        const days = Math.abs(end.diff(start, 'd'));
        const months = Math.abs(end.diff(start, 'M'));

        switch (true){
            case months > 0:
                return `${months} міс.`
            case days > 0:
                return `${days} дн.`
            case hours > 0:
                return `${hours} год.`
            case minutes > 0:
                return `${minutes} хв.`
            case seconds > 0:
                return `${seconds} сек.`
            default:
                return `${months} міс.`
        }
    }

    return (
        <div className={styles.info}>
            <TdNote
                note={data.notes}
                entityId={data.uuid}
                handleEditNotes={handleEditNotes}
                isShowLink={false}
            />
            <div className={styles.info__wrapper}>
                <TdStatus
                    data={data}
                    handlePatchStatus={handlePatchStatus}
                    classNameContainer={styles.info__status}
                />
                <div
                    className={styles.info__duration}
                    style={{background: StatusNameColorFactory(data.state).color}}
                >
                    {getLastInProgressTime()}
                </div>
            </div>
            <div className={styles.info__wrapper}>
                <span className={styles.info__mark}>
                    Оцінка
                </span>
                <span
                    className={styles.info__problem}
                    style={{
                        background: ProblemResultColorFactory(data.problemResult).background,
                        color: ProblemResultColorFactory(data.problemResult).color,
                    }}
                >
                    {data.problemResult}
                </span>
            </div>
            <CallAdditionalInfo data={data} />
            {data.decodingStatus !== "NEW" ? (
                <ReAnalyzeCall
                    data={data}
                    refreshCall={setCallDetails}
                />
            ) : null}
            <CallActivities data={data} />
        </div>
    );
};

export default CallGeneralInfo;
