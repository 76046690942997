import React, {useEffect, useState} from 'react';
import BaseLayout from "../../layouts/BaseLayout";
import HeaderFilter from "./components/HeaderFilter";
import {EnumRequestStatus, IRequestResource} from "../../resources/IRequest.resource";
import {REQUEST_STATUS_LIST} from "../../constants/RequestStatusFactory";
import InputSearch from "../AutomaticAssessmentPage/components/InputSearch";
import AdminTable from "./components/AdminTable";
import FetchService, {IPatchRequest} from "../../services/api/FetchService";
import Loader from "../../components/Loader";
import {useSearchParams} from "react-router-dom";
import {EnumSortType} from "../AutomaticAssessmentPage/components/HeadFilter";
import CustomPagination from "../AutomaticAssessmentPage/components/Pagination";
import PerPageDropDown from "../AutomaticAssessmentPage/components/PerPageDropDown";
import styles from './styles.module.scss';


const AdminGridPage = () => {
    const [allRequests, setAllRequests] = useState<IRequestResource[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params, setParams] = useSearchParams();
    const [total, setTotal] = useState<number>(0);

    const loadRequests = async () => {
        try {
            setIsLoading(true);
            const response = await FetchService.getRequests(paramsFactory());
            setAllRequests(response.data.requests);
            setTotal(response.data.total)
        } finally {
            setIsLoading(false);
        }
    }

    const handleEditStatus =  async (requestId: number, status: string, maxTotalPhoneCalls?: string, maxTotalPhoneCallsDurationSec?: string) => {
        const currentStatus = allRequests.find((request:IRequestResource) => request.id === requestId)?.status;

        if(currentStatus === EnumRequestStatus.NEW && status === EnumRequestStatus.DONE) {
            const body = {
                companyLimits: {
                    maxTotalPhoneCalls: Number(maxTotalPhoneCalls),
                    maxTotalPhoneCallsDurationSec: Number(maxTotalPhoneCallsDurationSec) * 60,
                    maxTotalChats: 0,
                    maxTotalChatsCharacters: 0
                },
                companyBalance: {
                    maxTotalPhoneCalls: Number(maxTotalPhoneCalls),
                    maxTotalPhoneCallsDurationSec: Number(maxTotalPhoneCallsDurationSec) * 60,
                    maxTotalChats: 0,
                    maxTotalChatsCharacters: 0
                }
            };
            try {
                setIsLoading(true);
                const res =  await FetchService.patchRequestApprove(requestId, body);
                setAllRequests((prev:IRequestResource[]) => {
                    return prev.map((item:IRequestResource):IRequestResource => item.id === requestId ? res.data : item)
                });
            } finally {
                setIsLoading(false);
            }
            return;
        }

        if(currentStatus !== EnumRequestStatus.NEW) {
            const urlStatus  = (type:EnumRequestStatus) => {
                switch (type) {
                    case EnumRequestStatus.SUSPEND:
                        return 'suspend';
                    case EnumRequestStatus.CANCEL:
                        return 'cancel';
                    case EnumRequestStatus.DONE:
                        return 'restore';
                    default:
                        return 'restore';
                }
            }

            try {
                setIsLoading(true);
                await FetchService.patchRequestChangeStatus(requestId, urlStatus(status as EnumRequestStatus));
                setAllRequests((prev:IRequestResource[]) => {
                    return prev.map((item:IRequestResource) => item.id === requestId ? {...item, status: status as EnumRequestStatus} : item)
                });
            } finally {
                setIsLoading(false);
            }
            return;
        }
    };


    const handleApproveRequest = async (requestId: number, status: string, maxTotalPhoneCalls?: string, maxTotalPhoneCallsDurationSec?: string) => {
        const body = {
            companyLimits: {
                maxTotalPhoneCalls: Number(maxTotalPhoneCalls),
                maxTotalPhoneCallsDurationSec: Number(maxTotalPhoneCallsDurationSec) * 60,
                maxTotalChats: 0,
                maxTotalChatsCharacters: 0
            },
            companyBalance: {
                maxTotalPhoneCalls: Number(maxTotalPhoneCalls),
                maxTotalPhoneCallsDurationSec: Number(maxTotalPhoneCallsDurationSec) * 60,
                maxTotalChats: 0,
                maxTotalChatsCharacters: 0
            }
        };
        console.log('body', body);
        try {
            setIsLoading(true);
            await FetchService.patchRequestApprove(requestId, body);
            setAllRequests((prev:IRequestResource[]) => {
                return prev.map((item:IRequestResource):IRequestResource => item.id === requestId ? {
                    ...item,
                    companyLimits: {
                        ...body.companyLimits,
                        maxTotalChats: 0,
                        maxTotalChatsCharacters: 0
                    },
                    companyBalance: {
                        ...body.companyLimits,
                        maxTotalChats: 0,
                        maxTotalChatsCharacters: 0
                    }
                } : item)
            });
        } finally {
            setIsLoading(false);
        }
    }

    const handleEditRequest = async (requestId: number, body:IPatchRequest) => {
        setIsLoading(true);
        try {
            const res = await FetchService.patchRequestEditLimits(requestId, body);

            setAllRequests((prev) => {
                return prev.map((item) => {
                    return item.id === requestId ? res.data : item;
                })
            });
        } finally {
            setIsLoading(false);
        }
    }

    const handleEditNotes = async (requestId: number | string, notes:string) => {
        setIsLoading(true);
        try {
            await FetchService.patchRequestEditLimits(Number(requestId), {comment: notes});
            setAllRequests((prev) => {
                return prev.map((item) => {
                    return item.id === requestId ? {...item, comment: notes} : item;
                })
            });
        } finally {
            setIsLoading(false);
        }
    }

    const handleFilterStatus = (e:React.MouseEvent<HTMLButtonElement>) => {
        const type = e.currentTarget.id;
        setParams((prev) => {
            prev.set('filter', type);
            prev.set('page', '1')
            return prev
        })
    };

    const handleSearch = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setParams((prev) => {
            if(!value) {
                prev.delete('search');
                return prev;
            }

            prev.set('search', value);
            return prev;
        })
    };

    const getParamsValue = (key:string) => {
        const value = params.get(key);
        return value ? value : '';
    };

    const handleSort = (value:string) => {
        setParams((prev) => {
            if(value === '_') {
                prev.delete('sort');
                return prev;
            }
            prev.set('sort', value);
            return prev;
        })
    }

    const handleSetSearchParams = (perPage: number) => setParams((prev) => {
        prev.set('perPage', String(perPage))
        prev.set('page', '1')
        return prev;
    });

    const handleSetPage = (page: number) => setParams((prev) => {
        prev.set('page', String(page))
        return prev;
    });

    const paramsFactory = () => {

        const perPage = getParamsValue('perPage');
        const page = getParamsValue('page');
        const getSortQuery = () => {
            const searchQuery = getParamsValue('sort');
            if(!searchQuery) return 'dateCreate desc';

            return `${searchQuery.split('_')[0]} ${searchQuery.split('_')[1]}`
        };

        const getStatus = ():string => {
            const filter = getParamsValue('filter');
            if(!filter) return '';
            return filter === 'ALL' ? '' : `status eq enum[OnboardingRequestStatus.${filter}]`;
        }

        const getSearch = () => {
            const value = getParamsValue('search');
            if(!value) return '';

            if(value.match(`^[0-9]*$`)) {
                return `phoneNumber cp '*${value}*' and`;
            } else {
                return `companyName lp '*${value.toLowerCase()}*' and`
            }

        };

        const urlParams = new URLSearchParams();

        urlParams.set('searchRequest', `${getSearch()} ${getStatus()}`);
        urlParams.set('orderByRequest', getSortQuery());
        urlParams.set('pageToken', page ?  String(Number(page) - 1) : '');
        urlParams.set('pageSize', perPage ? perPage : '20');

        return urlParams;
    }

    useEffect(() => {
        loadRequests();
    },[params])

    useEffect(() => {
        if(!getParamsValue('page') || !getParamsValue('perPage') || !getParamsValue('filter')) {
            setParams((prev) => {
                prev.set('page', '1');
                prev.set('perPage', '20');
                prev.set('filter', 'ALL');
                return prev;
            });
        }
    }, []);

    useEffect(() => {
        if(!getParamsValue('page') || !getParamsValue('perPage') || !getParamsValue('filter')) {
            setParams((prev) => {
                prev.set('page', '1');
                prev.set('perPage', '20');
                prev.set('filter', 'ALL');
                return prev;
            });
        }
    }, [params]);

    return (
        <>
            {isLoading ? <Loader /> : null}
            <BaseLayout>
                <div className={styles.grid__header}>
                    <div className={styles.grid__filters}>
                        <HeaderFilter
                            handleClick={handleFilterStatus}
                            isActive={getParamsValue('filter') === 'ALL'}
                            type={'ALL'}
                        />
                        {REQUEST_STATUS_LIST.map((item: EnumRequestStatus) => (
                            <HeaderFilter
                                key={item}
                                handleClick={handleFilterStatus}
                                isActive={getParamsValue('filter') === item}
                                type={item}
                            />
                        ))}
                    </div>
                    <div className={styles.grid__header_left}>
                        <InputSearch
                            value={getParamsValue('search')}
                            onChange={handleSearch}
                            inputClassName={styles.grid__search}
                        />
                    </div>
                </div>
                <AdminTable
                    handleApproveRequest={handleApproveRequest}
                    handleEditNotes={handleEditNotes}
                    handleEditRequest={handleEditRequest}
                    sortValue={getParamsValue('sort') as EnumSortType}
                    handleSort={handleSort}
                    handleEditStatus={handleEditStatus}
                    data={allRequests}
                />
                <div className={styles.grid__footer}>
                    <div/>
                    <CustomPagination
                        total={total}
                        perPage={Number(getParamsValue('perPage'))}
                        page={Number(getParamsValue('page'))}
                        setPage={handleSetPage}
                    />
                    <PerPageDropDown
                        setPerPage={handleSetSearchParams}
                        perPage={Number(getParamsValue('perPage'))}
                    />
                </div>
            </BaseLayout>
        </>
    );
};

export default AdminGridPage;
