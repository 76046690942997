import React, {useEffect, useState} from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import InputSearch from "../InputSearch";
import SelectItem from "../SelectItem";
import Button from "../../../../components/common/Button";
import TextButton from "../../../../components/common/TextButton";
import {observer} from "mobx-react-lite";
import CompanyObserver from "../../../../mobx/company/CompanyObserver";
import FetchService from "../../../../services/api/FetchService";
import styles from './styles.module.scss';
import {IEmployeeResource} from "../../../../resources/IEmployee.resource";

const IconClear = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.2803 4.71967C19.5732 5.01256 19.5732 5.48744 19.2803 5.78033L5.78033 19.2803C5.48744 19.5732 5.01256 19.5732 4.71967 19.2803C4.42678 18.9874 4.42678 18.5126 4.71967 18.2197L18.2197 4.71967C18.5126 4.42678 18.9874 4.42678 19.2803 4.71967Z" fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L19.2803 18.2197C19.5732 18.5126 19.5732 18.9874 19.2803 19.2803C18.9874 19.5732 18.5126 19.5732 18.2197 19.2803L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z" fill="#1E1E1E"/>
    </svg>
);

export interface IFilterSelect {
    label: string;
    value: string;
}

interface IProps {
    handleClose: () => void;
    handleSetOperator: (operator:IFilterSelect) => void;
    selectedOperatorsJSON: string | null;
    handleClearOperators: () => void;
}

const ModalFilterOperator = ({handleClose, handleSetOperator, selectedOperatorsJSON, handleClearOperators}:IProps) => {
    const [page, setPage] = useState<number>(0);
    const [employees, setEmployees] = useState<IFilterSelect[]>([]);
    const [search, setSearch] = useState<string>('');

    const parseSelectedOperators = ():IFilterSelect[] => {
        if(!selectedOperatorsJSON) return [];
        const operators = JSON.parse(selectedOperatorsJSON) as IFilterSelect[];

        return operators;
    };

    // const employeesMiddleWare = () => {
    //     if(search) return employees;
    //     return employees.filter((item) => !parseSelectedOperators().find((unit) => unit.value === item.value))
    // };

    const companyId = CompanyObserver.getCompanyId();

    const getAllEmployees = async () => {
        if(!companyId) return;

        const searchRequest = search ? `user.fullName lp '*${search.toLowerCase()}*'` : '';

        const response = await FetchService.getEmployees(companyId, page, searchRequest);
        const mappedData:IFilterSelect[] = response.data.employees.map((item:IEmployeeResource):IFilterSelect => ({
            label: item.fullName,
            value: String(item.id)
        }))

        setEmployees(mappedData);
    };

    const getAllEmployeesLoadMore = async () => {
        if(!companyId) return;

        setPage((prev) => prev + 1);
        const searchRequest = search ? `user.fullName cp '*${search}*'` : '';

        const response = await FetchService.getEmployees(companyId, page, searchRequest);
        const mappedData:IFilterSelect[] = response.data.employees.map((item:IEmployeeResource):IFilterSelect => ({
            label: item.fullName,
            value: String(item.id)
        }))

        setEmployees((prev) => ([...prev, ...mappedData]));
    };

    const handleChangeSearch = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setSearch(value);
    };

    const handleSelectEmployee = (e:React.MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.id;
        const label = e.currentTarget.name;

        handleSetOperator({value, label});
    };

    useEffect(() => {
        getAllEmployees();
    },[companyId, page, search])

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__title}>
                        <span>
                            Оператори
                        </span>
                        <span className={styles.modal__amount}>
                            {parseSelectedOperators().length}
                        </span>
                    </div>
                    <button
                        type={'button'}
                        className={styles.modal__clear}
                        onClick={() => {
                            handleClearOperators();
                            // handleClose();
                        }}
                    >
                        <IconClear />
                        <span>Очистити</span>
                    </button>
                </div>
                <InputSearch
                    inputClassName={styles.modal__search}
                    value={search}
                    onChange={handleChangeSearch}
                />
                <div className={styles.modal__list}>
                    {employees.map((item:IFilterSelect) => (
                        <SelectItem
                            handleSelect={handleSelectEmployee}
                            key={item.value}
                            value={item.value}
                            label={item.label}
                            isSelected={!!parseSelectedOperators().find((unit) => unit.value === item.value)}
                        />
                    ))}
                    {/*{employees.length !== 0 && employees.length >= 50 ? <LoadMoreButton onClick={() => getAllEmployeesLoadMore()}/> : null}*/}
                </div>
                <div className={styles.modal__footer}>
                    <TextButton
                        onClick={handleClose}
                        text={'Cancel'}
                    />
                    <Button
                        onClick={handleClose}
                        type={'button'}
                        text={'Apply'}
                        disabled={parseSelectedOperators().length === 0}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default observer(ModalFilterOperator);

