import React from 'react';
import styles from './styles.module.scss';
import {ICallResource} from "../../../../resources/ICall.resource";
import DateService from "../../../../services/DateService";

interface IProps {
    handlePhoneFilter: (phoneNumber:string) => void;
    data: ICallResource;
}

const TdPhoneNumber = ({handlePhoneFilter, data}:IProps) => {

    const handleClick = () => {
        handlePhoneFilter(`${data.phoneNumber}_${data.uuid}`);
    };

    const getFilterTitleHover = () => {
        return `Кількість дзвінків з/но номер ${data.phoneNumber} з ${DateService.formatUI(String(data.countWithSameNumberPeriodStart))} по ${DateService.formatUI(String(data.countWithSameNumberPeriodEnd))} - ${data.countWithSameNumber}`
    }

    return (
        <div className={styles.phone}>
            <span>{data.phoneNumber}</span>
            {data.countWithSameNumber > 1 ? (
                <button
                    className={styles.phone__btn}
                    type='button'
                    onClick={handleClick}
                    title={getFilterTitleHover()}
                >
                    {data.countWithSameNumber}
                </button>
            ) : null}
        </div>
    );
};

export default TdPhoneNumber;
