import React, {useState} from 'react';
import Pagination from 'rc-pagination';
import styles from './styles.module.scss';

const ReducePageNumberIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.5303 3.96967C18.8232 4.26256 18.8232 4.73744 18.5303 5.03033L11.5607 12L18.5303 18.9697C18.8232 19.2626 18.8232 19.7374 18.5303 20.0303C18.2374 20.3232 17.7626 20.3232 17.4697 20.0303L9.96967 12.5303C9.67678 12.2374 9.67678 11.7626 9.96967 11.4697L17.4697 3.96967C17.7626 3.67678 18.2374 3.67678 18.5303 3.96967Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 6.75C6.41421 6.75 6.75 7.08579 6.75 7.5V16.5C6.75 16.9142 6.41421 17.25 6 17.25C5.58579 17.25 5.25 16.9142 5.25 16.5V7.5C5.25 7.08579 5.58579 6.75 6 6.75Z" fill="#1E1E1E"/>
        </svg>
    )
};

const IncreasePageNumberIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 3.96967C5.17678 4.26256 5.17678 4.73744 5.46967 5.03033L12.4393 12L5.46967 18.9697C5.17678 19.2626 5.17678 19.7374 5.46967 20.0303C5.76256 20.3232 6.23744 20.3232 6.53033 20.0303L14.0303 12.5303C14.3232 12.2374 14.3232 11.7626 14.0303 11.4697L6.53033 3.96967C6.23744 3.67678 5.76256 3.67678 5.46967 3.96967Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18 6.75C17.5858 6.75 17.25 7.08579 17.25 7.5V16.5C17.25 16.9142 17.5858 17.25 18 17.25C18.4142 17.25 18.75 16.9142 18.75 16.5V7.5C18.75 7.08579 18.4142 6.75 18 6.75Z" fill="#1E1E1E"/>
        </svg>
    )
};

interface IProps {
    total: number;
    perPage: number;
    page: number
    setPage: (page:number) => void;
}

const CustomPagination = ({total, setPage, page, perPage}:IProps) => {
    return (
        <div>
            <Pagination
                total={total}
                pageSize={perPage}
                current={page}
                hideOnSinglePage={true}
                className={styles.pagination}
                showPrevNextJumpers={true}
                onChange={setPage}
                disabled={false}
                itemRender={(currentPage: number, type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next', element) => {
                    switch (type) {
                        case 'jump-next':
                            return (
                                <button
                                    className={styles.pagination__page}
                                    title={'jump-next'}
                                >
                                    ...
                                </button>
                            )
                        case "jump-prev":
                            return (
                                <button
                                    className={styles.pagination__page}
                                    title={'jump-prev'}
                                >
                                    ...
                                </button>
                            )
                        case "prev":
                            return (
                                <button
                                    className={`${styles.pagination__page} ${styles.pagination__prev}`}
                                    title='prev'
                                >
                                    <ReducePageNumberIcon/>
                                </button>
                            )
                        case 'next':
                            return (
                                <button
                                    className={`${styles.pagination__page} ${styles.pagination__next}`}
                                    title='next'
                                >
                                    <IncreasePageNumberIcon/>
                                </button>
                            )
                        case 'page':
                            return (
                                <button
                                    className={`${styles.pagination__page} ${currentPage === page ?  styles.pagination__page_active : ''}` }
                                >
                                    {currentPage}
                                </button>
                            )
                        default:
                            return element
                    }
                }}
            />

        </div>
    );
};

export default CustomPagination;
