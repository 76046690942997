import {useEffect, useState} from 'react';
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer.esm.js'
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import {
    EnumEmotionType,
    ICallDetailsResource,
    ILabSegResource,
} from "../resources/ICallDetails.resource";
import {getBaseURL} from "../services/api/axiosInstance";

export const colors = ['rgba(255,188,188,0.6)',  'rgba(229, 195, 255, 0.6)', 'rgba(195, 241, 255,0.53)','rgba(195, 255, 201,0.53)', "rgb(192, 222, 216, 0.6)"];

export const getColorForRegion = (type:string) => {
    switch (type) {
        case EnumEmotionType.problem:
            return colors[0];
        case EnumEmotionType.emotions:
            return colors[1];
        case EnumEmotionType.spend:
            return colors[2];
        case EnumEmotionType.solution:
            return colors[3];
        case EnumEmotionType.multi:
            return colors[4];
        default:
            return '';
    }
};

interface IProps {
    call?:ICallDetailsResource;
    ref: HTMLElement;
    setIsPlaying: (isPlaying: boolean) => void;
}


const UseWavePlayer = ({call, ref}:IProps) => {
    const [wave, setWave] = useState<any>(null);
    const [regions, setRegions] = useState<any>(null);

    useEffect(() => {
        if(!call) return;
        if(ref) {
            const instance =  WaveSurfer.create({
                container: ref,
                height: 60,
                waveColor: '#9C9C9C',
                progressColor: '#595959',
                cursorColor: '#f01a1a',
                url: `${getBaseURL()}/api/v1${call.blobUrl}`,
                fetchParams: {
                    headers: {
                        Authorization: `${localStorage.getItem('token')}`
                    }
                }
            });
            const wsRegions = instance.registerPlugin(RegionsPlugin.create());
            setRegions(wsRegions);
            setWave(instance);

            const addCustomRegion = (start:number, end:number, content:any, color:any)  => {
                const newRegion = {
                    start: start,
                    end: end,
                    content: content,
                    color: color,
                    drag: false,
                    resize: false,
                    id: start,
                    borderRadius: '20px',
                };
                wsRegions.addRegion(newRegion);
            }

            instance.on('decode', () => {
                let regions:any = [];

                call.labseg.forEach((segment:ILabSegResource) => {
                    if (segment.staticRegions && segment.staticRegions.length === 1) {
                        const staticRegion = segment.staticRegions[segment.staticRegions.length - 1];
                        regions.push(staticRegion);
                    }

                    // @ts-ignore
                    const validStaticRegions = segment.staticRegions.filter((item) => item.name !== 'unselect' && item.name !== 'emotions'  )

                    if (validStaticRegions && validStaticRegions.length > 1) {
                        const staticRegion = validStaticRegions[validStaticRegions.length - 1];
                        regions.push({...staticRegion, name: 'multi'});
                    }

                });

                regions.forEach((item:any) => {
                    addCustomRegion(item.start, item.end, '', getColorForRegion(item.name))
                });
            });
        }
    },[ref])

    return {
        wave : wave,
        regionsPlugin: regions,
    };

};

export default UseWavePlayer;
