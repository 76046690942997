import React, {useState} from 'react';
import styles from './styles.module.scss';

const LoadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 18C5 10.5442 11.0442 4.5 18.5 4.5H30.5C37.9558 4.5 44 10.5442 44 18V30C44 37.4558 37.9558 43.5 30.5 43.5H18.5C11.0442 43.5 5 37.4558 5 30V18ZM18.5 7.5C12.701 7.5 8 12.201 8 18V30C8 35.799 12.701 40.5 18.5 40.5H30.5C36.299 40.5 41 35.799 41 30V18C41 12.201 36.299 7.5 30.5 7.5H18.5Z" fill="#EBFF00"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.5 24C15.5 23.1716 16.1716 22.5 17 22.5H32C32.8284 22.5 33.5 23.1716 33.5 24C33.5 24.8284 32.8284 25.5 32 25.5H17C16.1716 25.5 15.5 24.8284 15.5 24Z" fill="#EBFF00"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.5 15C25.3284 15 26 15.6716 26 16.5V31.5C26 32.3284 25.3284 33 24.5 33C23.6716 33 23 32.3284 23 31.5V16.5C23 15.6716 23.6716 15 24.5 15Z" fill="#EBFF00"/>
    </svg>
)

interface IProps {
    handleDrop: (e:React.DragEvent<HTMLLabelElement>) => void;
    handleChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
}

const MainLoadBtn = ({handleDrop, handleChange}:IProps) => {

    const handleDragOver = (e:React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
    };
    const handleDragStart = (e:React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
    };
    const handleDragEnd = (e:React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
    }

    return (
        <label
            className={styles.load__btn}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            onDragLeave={handleDragEnd}
            onDragEnd={handleDragEnd}
        >
            <LoadIcon />
            <div className={styles.load__text}>
                <h2 className={styles.load__title}>
                    Завантажити дзвінки
                </h2>
                <p className={styles.load__description}>
                    Drag and drop or click to upload file
                </p>
                <p className={styles.load__format}>
                    mp3, wav, mp4
                </p>
            </div>
            <input
                multiple
                className={styles.load__input}
                onChange={handleChange}
                type={'file'}
                value={''}
            />
        </label>
    );
};

export default MainLoadBtn;
