import React from 'react';
import styles from './styles.module.scss';

const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z" stroke="#1E1E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

interface IProps {
    value:string;
    onChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
    inputClassName?: string;
}

const InputSearch = ({value, onChange, inputClassName}:IProps) => {
    return (
        <div className={styles.search}>
            <span className={`${styles.search__icon}`}>
                <SearchIcon />
            </span>
            <input
                className={`${styles.search__input} ${inputClassName}`}
                placeholder={'Search'}
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default InputSearch;
