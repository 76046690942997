import React from 'react';
import styles from  './styles.module.scss';

const IconLoad = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 18C4.5 10.5442 10.5442 4.5 18 4.5H30C37.4558 4.5 43.5 10.5442 43.5 18V30C43.5 37.4558 37.4558 43.5 30 43.5H18C10.5442 43.5 4.5 37.4558 4.5 30V18ZM18 7.5C12.201 7.5 7.5 12.201 7.5 18V30C7.5 35.799 12.201 40.5 18 40.5H30C35.799 40.5 40.5 35.799 40.5 30V18C40.5 12.201 35.799 7.5 30 7.5H18Z" fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 24C15 23.1716 15.6716 22.5 16.5 22.5H31.5C32.3284 22.5 33 23.1716 33 24C33 24.8284 32.3284 25.5 31.5 25.5H16.5C15.6716 25.5 15 24.8284 15 24Z" fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24 15C24.8284 15 25.5 15.6716 25.5 16.5V31.5C25.5 32.3284 24.8284 33 24 33C23.1716 33 22.5 32.3284 22.5 31.5V16.5C22.5 15.6716 23.1716 15 24 15Z" fill="#1E1E1E"/>
    </svg>
)

interface IProps {
    handleDrop: (e:React.DragEvent<HTMLLabelElement>) => void;
    handleChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const AdditionalBtn = ({handleDrop, handleChange, disabled=false}:IProps) => {
    return (
        <label
            className={`${styles.additional__btn} ${disabled ? styles.additional__btn_disabled : null}`}
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
        >
            <IconLoad />
            <div className={styles.additional__text}>
                <h2 className={styles.additional__title}>
                    Завантажити дзвінки
                </h2>
                {disabled ? (
                    <p className={styles.additional__empty}>
                        Ви вичерпали  ліміт безкоштовної версії
                    </p>
                ) : null}
            </div>
            <input
                multiple
                disabled={disabled}
                className={styles.additional__input}
                onChange={handleChange}
                type={'file'}
                value={''}
            />
        </label>
    );
};

export default AdditionalBtn;
