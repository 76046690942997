import React from 'react';
import styles from './styles.module.scss';

interface IProps {
    amount: number;
    maxAmount: number;
}

const AddedCallsAmount = ({amount, maxAmount}:IProps) => {
    return (
        <div className={styles.amount}>
            <span className={styles.amount__title}>
                Added
            </span>
            <span className={styles.amount__value}>
                {amount}/{maxAmount}
            </span>
        </div>
    );
};

export default AddedCallsAmount;
