import {EnumEmotionType} from "../resources/ICallDetails.resource";

const EmotionColorNameFactory = (emotion: EnumEmotionType) => {
    switch (emotion) {
        case EnumEmotionType.problem:
            return {
                name: 'problem',
                color: '#FFBCBC',
                value: '1.0'
            }
        case EnumEmotionType.emotions:
            return {
                name: 'emotion',
                color: '#E5C3FF',
                value: '2.0'
            }
        case EnumEmotionType.spend:
            return {
                name: 'spend',
                color: '#C3F1FF',
                value: '3.0'
            }
        case EnumEmotionType.solution:
            return {
                name: 'solution',
                color: '#C3FFC9',
                value: '4.0'
            }
        case EnumEmotionType.unselect:
            return {
                name: 'unselect',
                color: '#E3E3E3',
                value: '5.0'
            }
        default:
            return {
                name: '',
                color: '#E3E3E3',
                value: '5.0'
            }
    }
};

export default EmotionColorNameFactory;
