import React, {useState} from 'react';
import ModalEditLimits from "../ModalEditLimits";
import {IPatchRequest} from "../../../../services/api/FetchService";
import DateService from "../../../../services/DateService";
import {IRequestResource} from "../../../../resources/IRequest.resource";
import styles from './styles.module.scss';

interface IProps {
    data:IRequestResource;
    name:string;
    handleEditRequest: (requestId: number, body:IPatchRequest) => void;
}

const Limits = ({handleEditRequest, data, name}:IProps) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const handleOpenModal = () => setIsOpenModal(true);
    const handleCloseModal = () => setIsOpenModal(false);


    const isEmptyBalance = () => {
        if(!data.companyLimits) return false;
        if(!data.companyBalance) return false;
        return !data.companyBalance[name];
    }

    const valueFormat = (value:number) => {
        if(name==='maxTotalPhoneCalls') return value;

        return DateService.convertSecondsToMinutes(value)
    }

    return (
        <>
            {isOpenModal ? (
                <ModalEditLimits
                    handleEditRequest={handleEditRequest}
                    handleClose={handleCloseModal}
                    requestId={data.id}
                    current={data.companyLimits}
                />
            ) : null}
            <button
                className={styles.limits}
                type="button"
                onClick={handleOpenModal}
            >
            <span
                className={`${name === 'maxTotalPhoneCallsDurationSec' ? styles.limits__limit_time : styles.limits__limit} ${isEmptyBalance() ? styles.limits__full : ''}`}
            >
                {data.companyBalance ? valueFormat(data.companyBalance[name]) : '-'}
            </span>
                <span
                    className={`${name === 'maxTotalPhoneCallsDurationSec' ? styles.limits__limit_time : styles.limits__limit} ${isEmptyBalance() ? styles.limits__full : ''}`}
                >
                {data.companyLimits ? valueFormat(data.companyLimits[name]) : '-'}
            </span>
            </button>
        </>
    );
};

export default Limits;
