import React, {useEffect, useRef, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import dayjs from "dayjs";
import styles from './styles.module.scss';
import DateFastFilter, {EnumDateFastFilterType} from "../../../AutomaticAssessmentPage/components/DateFastFilter";
import {EnumCallStatus, ICallResource} from "../../../../resources/ICall.resource";
import  {IFilterSelect} from "../../../AutomaticAssessmentPage/components/ModalFilterOperator";
import CustomDatePicker, {TDatePickerItem} from "../../../../components/CustomDatePicker";
import DateService from "../../../../services/DateService";
import {EnumSortDirection, EnumSortType} from "../../../AutomaticAssessmentPage/components/HeadFilter";
import CompanyObserver from "../../../../mobx/company/CompanyObserver";
import axiosInstance from "../../../../services/api/axiosInstance";
import UrlCreator from "../../../../services/api/UrlCreator";
import FetchService from "../../../../services/api/FetchService";
import StatusNameColorFactory from "../../../../constants/StatusNameColorFactory";
import Loader from "../../../../components/Loader";
import InputSearch from "../../../AutomaticAssessmentPage/components/InputSearch";
import FilterTag from "../../../AutomaticAssessmentPage/components/FilterTag";
import ClearAllTagsBtn from "../../../AutomaticAssessmentPage/components/ClearAllTagsBtn";
import InfoHeader from "../InfoHeader";
import TestTableCalls from "../TestTableCalls";
import TestSelectAdditional from "../TestSelectAdditional";
import CustomPagination from "../../../AutomaticAssessmentPage/components/Pagination";
import PerPageDropDown from "../../../AutomaticAssessmentPage/components/PerPageDropDown";
import ModalProblemResultRange from "../../../../components/ModalProblemResultRange";

interface IDateFilter  {
    type: EnumDateFastFilterType;
}

const initialDateFilters = [
    {
        type: EnumDateFastFilterType.TODAY,
        isActive: true
    },
    {
        type: EnumDateFastFilterType.YESTERDAY,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CURRENT_WEEK,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CURRENT_MONTH,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CUSTOM_RANGE,
        isActive:false
    }
];

const TestGrid = () => {
    const [allCalls, setAllCalls] = useState<ICallResource[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [timer, setTimer] = useState<any>(null);
    const [isOpenProblemResultModal, setIsOpenProblemResultModal] = useState<boolean>(false);

    const tableRef= useRef<HTMLDivElement>(null);

    const handleOpenProblemResultModal = () => setIsOpenProblemResultModal(true);
    const handleCloseProblemResultModal = () => setIsOpenProblemResultModal(false);

    const handleSetProblemResultFilter = (start: string, end: string, clean?:boolean) => {
        setSearchParams((prev) => {
            if(clean) {
                prev.delete('problemResultRange');
                return prev;
            }
            prev.set('problemResultRange', `${start}-${end}`);
            return prev;
        });
    };

    const handleSelectFastDateFilter = (start:string, end:string, type:EnumDateFastFilterType) => {

        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            prev.set('startDate', start);
            prev.set('endDate', end);
            prev.set('dateFilterType', type);
            prev.set('page', '1');
            return prev;
        })
    }

    const handleSelectDate = (date:[TDatePickerItem, TDatePickerItem]) => {
        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            if(!date[0] && !date[1]){
                prev.set('dateFilterType', EnumDateFastFilterType.TODAY);
                prev.set('startDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                prev.set('endDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                return prev;
            }

            prev.set('startDate', DateService.formatUI(String(date[0]), 'YYYY-MM-DD'));
            prev.set('endDate', date[1] ? DateService.formatUI(String(date[1]), 'YYYY-MM-DD') : 'null');
            prev.set('dateFilterType', EnumDateFastFilterType.CUSTOM_RANGE);
            return prev;
        });
    }

    const getSearchParams = (key: string) => searchParams.get(key);
    const getSearchParamsCustomRangeDate = (key: string) => {
        if(searchParams.get('dateFilterType') !== EnumDateFastFilterType.CUSTOM_RANGE) return null;
        const value = searchParams.get(key);
        if(!value) return null;
        if('null' === value) return null;
        return new Date(value);
    }

    const handlePhoneFilter = (phoneNumber: string) => {
        if(getSearchParams('phoneNumber')){
            setSearchParams((prev) => {
                prev.delete('phoneNumber');
                prev.delete('search');
                return prev;
            })
        } else {
            setSearchParams((prev) => {
                prev.set('phoneNumber', phoneNumber);
                prev.delete('search');
                return prev;
            })
        }
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchParams((prev) => {

            prev.delete('phoneNumber');
            prev.delete('problemsResult');
            prev.delete('topProblems');
            prev.set('search', value);
            if(!value) prev.delete('search');
            return prev;
        });
    };

    const handleSelectSort =(value: string) => {
        setSearchParams((prev) => {
            if(value === '_') {
                prev.delete('sort');
                prev.delete('topProblems')
                return prev;
            }

            prev.set('sort', value);
            prev.delete('topProblems')
            return prev;
        });
    };

    const handleProblemsResult = (problemsResult: string) => {
        setSearchParams((prev) => {
            const currentProblemResult = prev.get('problemsResult');
            if(currentProblemResult === problemsResult){
                prev.delete('problemsResult');
                prev.delete('phoneNumber');
                prev.delete('topProblems');
                prev.delete('search');
                return prev;
            }
            prev.set('problemsResult', problemsResult);
            prev.delete('phoneNumber');
            prev.delete('topProblems');
            prev.delete('search');
            return prev;
        })
    };

    const handleDefaultAnalyzed = () => {
        setSearchParams((prev) => {
            const isAnalyzed = prev.get('isAnalyzed');
            if(!isAnalyzed) {
                prev.set('isAnalyzed', '1');
                prev.delete('isNotAnalyzed')
            } else {
                prev.delete('isAnalyzed');
                prev.delete('isNotAnalyzed')
            }
            return prev;
        })
    };
    const handleNotAnalyzed = () => {
        setSearchParams((prev) => {
            const isNotAnalyzed = prev.get('isNotAnalyzed');
            if(!isNotAnalyzed) {
                prev.set('isNotAnalyzed', '1');
                prev.delete('isAnalyzed')
            } else {
                prev.delete('isNotAnalyzed');
                prev.delete('isAnalyzed')
            }
            return prev;
        })
    };

    const handleTopProblems = (topProblems: string) => {
        setSearchParams((prev) => {
            const currentTopProblems = prev.get('topProblems');
            if(currentTopProblems === topProblems) {
                prev.delete('topProblems');
                prev.delete('sort');
                return prev;
            }

            prev.delete('phoneNumber');
            prev.delete('search');
            prev.delete('problemsResult');
            prev.set('sort', `${EnumSortType.problemResult}_${EnumSortDirection.desc}`);
            prev.set('topProblems', topProblems);
            return prev;
        })
    };


    const paramsFactory = () => {
        const startDate = DateService.transformPicker(getSearchParams('startDate'));
        const endDate = getSearchParams('endDate');
        if(!startDate ) return;

        const perPage = getSearchParams('perPage');
        const page = getSearchParams('page');
        const phoneNumber = getSearchParams('phoneNumber');
        const problemsResult= getSearchParams('problemsResult');
        const topProblems= getSearchParams('topProblems');
        const status = getSearchParams('status');
        const statusFilter =  status ? `state in [${JSON.parse(status).map((item:EnumCallStatus, index:number) => `enum[PhoneCallState.${item}]`)}]` : '';
        const problemResultRange = getSearchParams('problemResultRange');
        const problemResultRangeSearchParam = problemResultRange ? `problemResult ge ${problemResultRange.split('-')[0]} and problemResult lt ${problemResultRange.split('-')[1]} and` : ''

        const generateStaticParams = () => {
            return statusFilter ?
                `${statusFilter} and dateCreate ge 'T${startDate}' and dateCreate lt 'T${endDateMiddleware()}'`
                :
                `dateCreate ge 'T${startDate}' and dateCreate lt 'T${endDateMiddleware()}'`
        };

        const searchParams = () => {
            const value = getSearchParams('search');
            if(!value) return null;

            if(value.match(`^[0-9]*$`)) {
                return `phoneNumber cp '*${value}*'`;
            } else {
                return `employee.user.fullName lp '*${value.toLowerCase()}*'`
            }
        }

        const endDateMiddleware = () => {
            if(getSearchParams('startDate') === endDate) {
                return DateService.transformPicker(dayjs(startDate).add(2, "day").format('YYYY-MM-DD'));
            }
            return !endDate || endDate === 'null' ? DateService.transformPicker(dayjs(startDate).add(1, "days").format('YYYY-MM-DD')) : DateService.transformPicker(endDate);
        }

        const getSortQuery = () => {
            const searchQuery = getSearchParams('sort');
            if(!searchQuery) return '';

            return `${searchQuery.split('_')[0]} ${searchQuery.split('_')[1]}`
        };

        const getIsAnalyzed = () => {
            const isAnalyzed = getSearchParams('isAnalyzed');
            const isNotAnalyzed = getSearchParams('isNotAnalyzed');
            if(isNotAnalyzed){
                return `problemResult Is null and`
            }

            if(!isAnalyzed) return '';


            return isAnalyzed === '1' ? `problemResult Not_Is null and` : '';
        }

        const params = new URLSearchParams();

        switch (true) {
            case Boolean(phoneNumber):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', '');
                params.set('orderByRequest', getSortQuery());
                params.set('phoneCallUuid', String(phoneNumber?.split('_')[1]));
                return params;
            case Boolean(topProblems) && Boolean(phoneNumber):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', '');
                params.set('orderByRequest', getSortQuery());
                params.set('phoneCallUuid', String(phoneNumber?.split('_')[1]));
                return params;
            case Boolean(topProblems):
                params.set('pageSize', String(topProblems));
                params.set('searchRequest', `${getIsAnalyzed()} problemResult gt 0 and dateCreate ge 'T${startDate}' and dateCreate lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', getSortQuery());
                return params;
            case Boolean(problemsResult) && Boolean(phoneNumber):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', '');
                params.set('orderByRequest', getSortQuery());
                params.set('phoneCallUuid', String(phoneNumber?.split('_')[1]));
                return params;
            case Boolean(problemsResult):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', `${getIsAnalyzed()} problemResult ge ${problemsResult} and ${generateStaticParams()}`);
                params.set('orderByRequest', getSortQuery());
                return params;
            case Boolean(searchParams()):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', `${getIsAnalyzed()} ${searchParams()} ${problemResultRangeSearchParam} and ${generateStaticParams()}`);
                params.set('orderByRequest', getSortQuery());
                return params;
            default:
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', `${getIsAnalyzed()} ${problemResultRangeSearchParam} dateCreate ge 'T${startDate}' and dateCreate lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', getSortQuery());
                return params;
        }
    };


    const handleSetPage = (page: number) => setSearchParams((prev) => {
        prev.set('page', String(page))
        return prev;
    });

    const handleSetSearchParams = (perPage: number) => setSearchParams((prev) => {
        prev.set('perPage', String(perPage))
        prev.set('page', '1')
        return prev;
    });

    const getAllCalls = async () => {
        if(!CompanyObserver.companyId) return;
        setIsLoading(true);

        try {
            const res = await axiosInstance.get(UrlCreator.getAllCalls(CompanyObserver.companyId),{
                params: paramsFactory()
            });
            setAllCalls(res.data.calls);
            setTotal(res.data.total);
        } finally {
            setIsLoading(false);
        }
    };

    const getAllCallsRefresh = async () => {
        if(!CompanyObserver.companyId) return;

        try {
            const res = await axiosInstance.get(UrlCreator.getAllCalls(CompanyObserver.companyId),{
                params: paramsFactory()
            });
            setTotal(res.data.total);
        } finally {
            clearInterval(timer);
        }
    };

    const handleEditNotes = async (callId:string | number, notes:string) => {
        const companyId = CompanyObserver.getCompanyId();
        if(!companyId) return;

        try {
            const data = await FetchService.patchCallNotes(companyId, String(callId), notes);
            setAllCalls((prev) => {
                return prev.map((item) => {
                    return item.uuid === callId ? {...item, notes: notes} : item;
                })
            })
        } finally {

        }
    }

    const getTags = () => {
        const tags = [];
        const phoneNumber = searchParams.get('phoneNumber');
        const search = searchParams.get('search');
        const problemsResult= getSearchParams('problemsResult');
        const topProblems= getSearchParams('topProblems');
        const status= getSearchParams('status');
        const operator= getSearchParams('operator');
        const problemResultRange= getSearchParams('problemResultRange');

        if(phoneNumber) {
            tags.push({
                title: phoneNumber.split('_')[0],
                type: 'phoneNumber'
            })
        }
        if(problemResultRange) {
            tags.push({
                title: `Оцінка ${problemResultRange}`,
                type: 'problemResultRange'
            })
        }
        if(operator) {
            const operatorList= JSON.parse(operator) as IFilterSelect[];
            const operatorTags = operatorList.map((item) => ({
                title: item.label,
                type: `operator_${item.value}`
            }));
            tags.push(...operatorTags);
        }
        if(problemsResult) {
            tags.push({
                title: `${problemsResult}+`,
                type: 'problemsResult'
            })
        }
        if(topProblems) {
            tags.push({
                title: `топ ${topProblems}`,
                type: 'topProblems'
            })
        }
        if(search) {
            tags.push({
                title: search,
                type: 'search'
            })
        }
        if(status) {
            const arrStatus = JSON.parse(status) as EnumCallStatus[];
            const statuses = arrStatus.map((item) => {
                return {
                    title: StatusNameColorFactory(item as EnumCallStatus).name,
                    type: `status-${item}`
                }
            })

            tags.push(...statuses)
        }
        return tags;
    };

    const handleClearTag = (type:string) => {

        setSearchParams((prev) => {
            if(type.split('_')[0] === 'operator') {
                const operators = prev.get('operator');
                if(!operators) return prev;

                const parsedOperators = JSON.parse(operators) as IFilterSelect[];
                const filteredOperators = parsedOperators.filter((item:IFilterSelect) => item.value !== type.split('_')[1]);
                if(filteredOperators.length === 0){
                    prev.delete('operator');
                    return prev;
                }
                prev.set('operator', JSON.stringify(filteredOperators));
                return prev;
            }
            if(type.split('-')[0] === 'status') {
                const statuses = prev.get('status');
                if(!statuses) return prev;

                const parsedStatuses = JSON.parse(statuses) as EnumCallStatus[];
                const filteredStatuses = parsedStatuses.filter((item:EnumCallStatus) => item !== type.split('-')[1]);

                if(filteredStatuses.length === 0){
                    prev.delete('status');
                    return prev;
                }
                prev.set('status', JSON.stringify(filteredStatuses));
                return prev;
            }

            prev.delete(type);
            return prev;
        })
    }

    const handleClearAllTags = () => {
        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            prev.delete('search');
            prev.delete('problemsResult');
            prev.delete('topProblems');
            prev.delete('status');
            prev.delete('operator');
            prev.delete('problemResultRange');
            return prev;
        })
    }

    useEffect(() => {
        if(!getSearchParams('page') || !getSearchParams('perPage') || !getSearchParams('dateFilterType')) {
            setSearchParams((prev) => {
                prev.set('page', '1');
                prev.set('perPage', '20');
                prev.set('dateFilterType', EnumDateFastFilterType.TODAY);
                prev.set('startDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                prev.set('endDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                return prev;
            });
        }
    }, []);

    useEffect(() => {
        if(!getSearchParams('page') || !getSearchParams('perPage') || !getSearchParams('dateFilterType')) {
            setSearchParams((prev) => {
                prev.set('page', '1');
                prev.set('perPage', '20');
                prev.set('dateFilterType', EnumDateFastFilterType.TODAY);
                prev.set('startDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                prev.set('endDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                return prev;
            });
        }
    }, [searchParams]);

    const startCountTime = () => {
        const LOAD_TIME =  5 * 1000;

        const timer = setTimeout(() => {
            getAllCallsRefresh();
            startCountTime();
        }, LOAD_TIME)

        setTimer(timer);
    }

    useEffect(() => {
        const LOAD_TIME = 3 * 60 * 1000;
        const timer = setInterval(() => {
            getAllCallsRefresh();
        }, LOAD_TIME)

        return () => {
            clearInterval(timer);
        }
    }, []);


    useEffect(() => {
        getAllCalls();
    },[searchParams, CompanyObserver.companyId]);

    const totalCountFactory = ():number => {
        const topProblems = getSearchParams('topProblems');

        if(!topProblems) return total;
        if(total === 0) return 0;

        return Number(topProblems) > total ? total : Number(topProblems);
    }

    return (
        <>
            {isLoading ? <Loader/> : null}
            {isOpenProblemResultModal ? (
                <ModalProblemResultRange
                    handleSetProblemResult={handleSetProblemResultFilter}
                    selectedProblemResultJSON={searchParams.get('problemResultRange')}
                    handleClose={handleCloseProblemResultModal}
                />
            ) : null}
            <>
                <InfoHeader
                    refresh={getAllCalls}
                />
                <div className={styles.automatic__header}>
                    <div className={styles.automatic__dates}>
                        {initialDateFilters.map((item: IDateFilter) => {
                            if (item.type === EnumDateFastFilterType.CUSTOM_RANGE) return null;
                            return (
                                <DateFastFilter
                                    key={item.type}
                                    type={item.type}
                                    isActive={String(item.type) === getSearchParams('dateFilterType')}
                                    handleSetDate={handleSelectFastDateFilter}
                                />
                            )
                        })}
                        <TestSelectAdditional
                            handleProblemsResult={handleProblemsResult}
                            handleTopProblems={handleTopProblems}
                            problemsResult={getSearchParams('problemsResult')}
                            topProblems={getSearchParams('topProblems')}
                            handleDefaultAnalyzed={handleDefaultAnalyzed}
                            isAnalyzed={getSearchParams('isAnalyzed')}
                            isNotAnalyzed={getSearchParams('isNotAnalyzed')}
                            handleNotAnalyzed={handleNotAnalyzed}
                        />
                        <CustomDatePicker
                            startDate={getSearchParamsCustomRangeDate('startDate')}
                            endDate={getSearchParamsCustomRangeDate('endDate')}
                            handleSelectDate={handleSelectDate}
                        />
                        <div className={styles.automatic__total}>
                            Загальна кількість: {totalCountFactory()}
                        </div>
                    </div>
                    <div>
                        <InputSearch
                            value={getSearchParams('search') ? String(getSearchParams('search')) : ''}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                {getTags().length ? (
                    <div className={styles.automatic__tags}>
                        {getTags().map((item) => (
                            <FilterTag
                                key={item.title}
                                type={item.type}
                                title={item.title}
                                handleDelete={handleClearTag}
                            />
                        ))}
                        <ClearAllTagsBtn onClick={handleClearAllTags}/>
                    </div>
                ) : null}
                <TestTableCalls
                    ref={tableRef}
                    filter={getSearchParams('operator') as string}
                    selectedSort={getSearchParams('sort') as EnumSortType}
                    handleSelectSort={handleSelectSort}
                    handlePhoneFilter={handlePhoneFilter}
                    data={allCalls}
                    handleEditNotes={handleEditNotes}
                />
                <div className={styles.automatic__footer}>
                    <div/>
                    <CustomPagination
                        total={getSearchParams('topProblems') ? Number(getSearchParams('topProblems')) : total}
                        perPage={Number(getSearchParams('perPage'))}
                        page={Number(getSearchParams('page'))}
                        setPage={handleSetPage}
                    />
                    {!getSearchParams('topProblems') ? (
                        <PerPageDropDown
                            setPerPage={handleSetSearchParams}
                            perPage={Number(getSearchParams('perPage'))}
                        />
                    ) : null}
                </div>
            </>
        </>
    );
};

export default observer(TestGrid);
