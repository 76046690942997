import {EnumCallStatus} from "../resources/ICall.resource";

export const StatusNameColorFactory = (status: EnumCallStatus | string) => {
    switch (status) {
        case EnumCallStatus.RESOLVED:
            return {
                name: 'Вирішено',
                color: '#CCFFD1'
            };
        case 'PEEKED'://todo
            return {
                name: 'В роботі',
                color: '#F5861E'
            };
        case 'ACCEPTED'://todo
            return {
                name: 'Не потребує уваги',
                color: '#D5DAF5'
            };
        case EnumCallStatus.IN_PROGRESS:
            return {
                name: 'В роботі',
                color: '#F5861E'
            };
        case EnumCallStatus.NEW:
            return {
                name: 'Не вибрано',
                color: '#EBEBEB'
            };
        case EnumCallStatus.ACCEPTED:
            return {
                name: 'Не потребує уваги',
                color: '#D5DAF5'
            };
        default:
            return {
                name: 'Не вибрано',
                color: '#EBEBEB'
            }
    }
};

export default StatusNameColorFactory;
