import React, {useEffect, useRef, useState} from 'react';
import axiosInstance from "../../services/api/axiosInstance";
import {
    EnumEmotionType,
    EnumSpeakerType,
    ICallDetailsResource,
    ILabSegResource,
    IStaticRegionResource
} from "../../resources/ICallDetails.resource";
import {AxiosResponse} from "axios";
import GoBackButton from "../../components/common/GoBackButton";
import UserInfo from "../../components/UserInfo";
import useWavePlayer, {getColorForRegion} from "../../hooks/useWavePlayer";
import PlayerActions from "./components/PlayerActions";
import TextCard from "./components/TextCard";
import CallGeneralInfo from "./components/CallGeneralInfo";
import UrlCreator from "../../services/api/UrlCreator";
import {useParams} from "react-router-dom";
import EmotionColorNameFactory from "../../constants/EmotionColorNameFactory";
import CompanyInfo from "../../components/CompanyInfo";
import CompanyObserver from "../../mobx/company/CompanyObserver";
import Loader from "../../components/Loader";
import FetchService from "../../services/api/FetchService";
import styles from './styles.module.scss';

const CallDetailsPage = () => {
    const [call, setCall] = useState<ICallDetailsResource>();
    const [volume, setVolume] = useState<number>(1);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [timer, setTimer] = useState<any>(null);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [isPlaying, setIsPaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const {id: callId} = useParams();
    const containerRef:React.MutableRefObject<any> = useRef(null);
    const getCompanyId = () => Number(localStorage.getItem('company'));

    const getCallDetails = async () => {
        if(!callId) return;
        try {
            const response:AxiosResponse<ICallDetailsResource> = await axiosInstance.get(UrlCreator.getCallById(getCompanyId(), callId));
            setCall(response.data)
        } finally {}
    }

    const handleEditNotes = async (callId:string | number, notes:string) => {
        const companyId = CompanyObserver.getCompanyId();
        if(!companyId) return;

        try {
            const data = await FetchService.patchCallNotes(companyId, String(callId), notes);
            setCall((prev) => {
                if(!prev) return;
                return {
                    ...prev,
                    notes: notes
                }
            })
        } finally {}
    }

    const handlePatchStatus = (callId:string, status: string) => {
        if(!CompanyObserver.companyId) return;
        axiosInstance.patch(UrlCreator.patchCallStatus(CompanyObserver.companyId, callId, status)).then((res:AxiosResponse<ICallDetailsResource>) => {
            setCall((prevState) => {
                if(!prevState) return prevState;
                return {
                    ...prevState,
                    state: res.data.state,
                    lastActivity: res.data.lastActivity,
                    activities: [...prevState.activities, res.data.lastActivity]
                }
            });
        })
    };

    const postCallLabsegLabel = (labsegId: number, emotion: EnumEmotionType) => {
        axiosInstance.post(UrlCreator.postCallLabel(getCompanyId(), String(callId), labsegId), {
            id: 0,
            name: emotion,
            value: EmotionColorNameFactory(emotion).value
        }).then((res) => {
           setCall((prev) => {
               if(!prev) return prev;

                const newLabseg: ILabSegResource[] = prev.labseg.map((item) => {
                    if(item.id === labsegId) {
                        const newStaticLabs:IStaticRegionResource[] = item.staticRegions;
                        newStaticLabs.push({
                            id: res.data.id,
                            name: res.data.name,
                            value: res.data.value,
                            end: item.end,
                            start: item.start
                        });
                        return {...item, staticRegions: newStaticLabs}
                    }
                    return item;
                });
               updateEstimate({
                   ...prev,
                   labseg: newLabseg
               });

               return {
                    ...prev,
                   labseg: newLabseg
               }
           });
        }).then(() => {

        });
    }

    const deleteCallLabsegLabels =  (labsegId: number, labelIds: number[]) => {
        const companyId = getCompanyId();
        if(!call?.uuid) return;

        FetchService.deleteLabsegLabels(companyId, call?.uuid, labsegId, labelIds).then(() => {
            setCall((prev) => {
                if(!prev) return;
                const newLabseg = prev.labseg.map((item) => {
                    if(item.id === labsegId) {
                        return {
                            ...item,
                            staticRegions: item.staticRegions.filter((unit) => !labelIds.includes(unit.id))
                        }
                    }
                    return item;
                })
                updateEstimate({
                    ...prev,
                    labseg: newLabseg
                });
                return {
                    ...prev,
                    labseg: newLabseg
                }
            })
        }).then(() => {

        })

    }

    const handleEditText = (data: ILabSegResource, text:string) => {
        const companyId = CompanyObserver.getCompanyId();
        if(!call) return;
        if(!companyId) return;
        const body = {
            id: data.id,
            name: 'text',
            value: text
        };

        FetchService.putCallLabseg(companyId, call.uuid, data.id, data.textId, body).then((res) => {
            const {data} = res;

            setCall((prevState) => {
                if(!prevState) return prevState;
                return {
                    ...prevState,
                    labseg: prevState.labseg.map((item:ILabSegResource) => {
                        return data.id === item.textId ? {...item, text: data.value} : item;
                    })
                }
            });
        })
    };

    useEffect(() => {
        getCallDetails();
    },[])


    const {wave:wavePlayerInstance, regionsPlugin}:any = useWavePlayer({
        call: call,
        ref: containerRef.current,
        setIsPlaying: setIsPaying
    });

    const getDuration = () => {
      if(!wavePlayerInstance) return 0;
      return wavePlayerInstance.getDuration();
    };

    const handleTogglePlay = () => {

        if(wavePlayerInstance.isPlaying()) {
            if(timer) clearInterval(timer);
            wavePlayerInstance.pause();
            setIsPaying(false);
        } else {
            const interval = setInterval(() => {
                if(!wavePlayerInstance) return;
                setCurrentTime(wavePlayerInstance.getCurrentTime())
            },500);

            setTimer(interval);
            wavePlayerInstance.play();
            setIsPaying(true);
        }
    };

    const handleIncreaseVolume = () => {
        setVolume((prev) => {
            if(prev === 1) return 1;
            const value = prev + 0.05;
            return  Number(value.toFixed(2));
        })
    };

    const handleDecreaseVolume = () => {
        setVolume((prev) => {
            if(prev === 0) return 0;
            const value = prev - 0.05;
            return  Number(value.toFixed(2));
        })
    };

    const handlePlaybackRate = (value: number) => {
        setPlaybackRate(value);
        wavePlayerInstance.setPlaybackRate(value);
    };

    useEffect(() => {
        if(!wavePlayerInstance) return;
        wavePlayerInstance.setVolume(volume)
    },[volume]);

    const scrollToText = () => {
        if(!call) return;
        function isElementInViewport(el:any) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight - 150 || document.documentElement.clientHeight - 150) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        const labes = call.labseg.map((item) => {
            if(item.start <= currentTime && currentTime <= item.end && !isElementInViewport(document.getElementById(String(item.id)))){
                document.getElementById(String(item.id))?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        });
    };

    useEffect(() => {
        scrollToText()
    },[currentTime])

    const handlePressButton = (e:KeyboardEvent) => {
        const active = document.activeElement;

        const isDeclinePressButton = () => {
            if(!active) return false;

            const tagName = active.tagName.toLowerCase();

            if(tagName === 'input' || tagName === 'textarea') return true;
        };

        if(isDeclinePressButton()) return;

        if(e.code === "Space") {
            e.preventDefault();
            handleTogglePlay();
        }
    }

    useEffect(() => {
        if(!wavePlayerInstance) return;

        window.addEventListener('keydown', handlePressButton);
    },[wavePlayerInstance])

    useEffect(() => {

        return () => {
            window.removeEventListener('keydown', handlePressButton)
        }
    },[wavePlayerInstance])

    const handleGoToWave =  async (startTime: number) => {
        wavePlayerInstance.seekTo(startTime / wavePlayerInstance.getDuration());

        setCurrentTime(wavePlayerInstance.getCurrentTime())
        wavePlayerInstance.pause();
        setIsPaying(false);
    }

    const updateEstimate = (call:ICallDetailsResource) => {
        if(!call) return;
        regionsPlugin.clearRegions();

        let regions:IStaticRegionResource[] = [];
        const addCustomRegion = (start:number, end:number, content:string, color:string)  => {
            const newRegion = {
                start: start,
                end: end,
                content: content,
                color: color,
                drag: false,
                resize: false,
            };
            regionsPlugin.addRegion(newRegion);
        }

        call.labseg.forEach((segment:ILabSegResource) => {
            if (segment.staticRegions && segment.staticRegions.length === 1) {
                const staticRegion = segment.staticRegions[segment.staticRegions.length - 1];
                regions.push(staticRegion);
            }

            if (segment.staticRegions && segment.staticRegions.length > 1) {
                const staticRegion = segment.staticRegions[segment.staticRegions.length - 1];
                regions.push({...staticRegion, name: 'multi' as EnumEmotionType});
            }
        });

        regions.forEach((item:IStaticRegionResource) => {
            addCustomRegion(item.start, item.end, '', getColorForRegion(item.name))
        });
    }

    const labsegMiddleware = () => {
        if(!call) return [];
        if(call.labseg.every((item) => item.speaker === EnumSpeakerType.CLIENT)) {
            return call.labseg.map((item) => ({
                ...item,
                speaker: EnumSpeakerType.SPEAKER,
            }))
        }

        return call.labseg;
    };

    return (

        <>
            {isLoading ? <Loader /> : null}
            <div className={styles.call}>
                <div className={styles.call__header}>
                    <GoBackButton />
                    <div className={styles.call__info}>
                        <CompanyInfo />
                        <UserInfo />
                    </div>
                </div>
                <div className={styles.call__main}>
                    <PlayerActions
                        currentTime={currentTime}
                        duration={getDuration()}
                        handleTogglePlay={handleTogglePlay}
                        volume={volume}
                        handleIncreaseVolume={handleIncreaseVolume}
                        handleDecreaseVolume={handleDecreaseVolume}
                        handlePlaybackRate={handlePlaybackRate}
                        playbackRate={playbackRate}
                        isPlaying={isPlaying}
                    />
                    {call ? (
                        <CallGeneralInfo
                            handleEditNotes={handleEditNotes}
                            setCallDetails={setCall}
                            data={call}
                            handlePatchStatus={handlePatchStatus}
                        />
                    ) : null}
                </div>

                <div
                    className={styles.call__wave}
                    ref={containerRef}
                />
                {call ? (
                    <div className={styles.call__list}>
                        {labsegMiddleware().map((item:ILabSegResource) => (
                            <TextCard
                                handleTogglePlay={handleTogglePlay}
                                key={item.id}
                                handleEditText={handleEditText}
                                postCallLabsegLabel={postCallLabsegLabel}
                                deleteCallLabels={deleteCallLabsegLabels}
                                currentTime={currentTime}
                                handleGoToWave={handleGoToWave}
                                data={item}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default CallDetailsPage;
