import React, {useEffect, useState} from 'react';
import GeneralInfoCardEmpty from "../GeneralInfoCardEmpty";
import UploadFilesBtn from "../UploadFilesBtnEmpty";
import styles from './styles.module.scss';
import {ICompanyBalancesResource} from "../../../../resources/ICompanyBalances.resource";
import CompanyObserver from "../../../../mobx/company/CompanyObserver";
import FetchService from "../../../../services/api/FetchService";
import Loader from "../../../../components/Loader";

interface IProps {
    refresh: () => void;
}

const EmptyTestGrid = ({refresh}:IProps) => {
    const [companyBalances, setCompanyBalances] = useState<ICompanyBalancesResource | null>(null)
    const companyId =  CompanyObserver.getCompanyId();

    const getCompanyBalances = async () => {
        if(!companyId) return;
        try {
            const {data} = await FetchService.getCompanyBalance(companyId);
            setCompanyBalances(data);
        } finally {}
    };

    useEffect(() => {
        getCompanyBalances();
    },[companyId])

    const getMinutes = (amount?:number) => {
        if(!amount) return 0;

        return Math.ceil(amount / 60 )
    }

    return (
        <div className={styles.empty}>
            <div className={styles.empty__row}>
                <GeneralInfoCardEmpty
                    title={'Дзвінки'}
                    description={'доступно'}
                    amount={companyBalances?.balance?.maxTotalPhoneCalls}
                    limit={companyBalances?.limits?.maxTotalPhoneCalls}
                />
                <GeneralInfoCardEmpty
                    title={'Тривалість дзвінків'}
                    description={'доступно хвилин дзвінків'}
                    amount={getMinutes(companyBalances?.balance?.maxTotalPhoneCallsDurationSec)}
                    limit={getMinutes(companyBalances?.limits?.maxTotalPhoneCallsDurationSec)}
                />
            </div>
            <UploadFilesBtn
                isMain={true}
                refresh={() => {
                    refresh()
                    getCompanyBalances()
                }}
            />
        </div>
    );
};

export default EmptyTestGrid;
