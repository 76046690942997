import React, {forwardRef, useEffect} from 'react';
import {ICallResource} from "../../../../resources/ICall.resource";
import {NavLink} from "react-router-dom";
import HeadFilter, {EnumSortType} from "../../../AutomaticAssessmentPage/components/HeadFilter";
import TdDate from "../../../AutomaticAssessmentPage/components/TdDate";
import TdProblemResult from "../../../AutomaticAssessmentPage/components/TdProblemResult";
import TdNote from "../../../AutomaticAssessmentPage/components/TdNote";
import StringService from "../../../../services/StringService";
import styles from './styles.module.scss';

interface IProps {
    data: ICallResource[];
    handlePhoneFilter: (phoneNumber: string) => void;
    selectedSort: string;
    handleSelectSort: (value: string) => void;
    filter?:string;
    handleEditNotes: (callId:string | number, notes:string) => void;
}

const TableCalls = forwardRef(({data, selectedSort, handleSelectSort, handleEditNotes}:IProps, ref:any) => {

    const handleScroll = (e:React.UIEvent<HTMLElement>) => {
        const scrollTop = e.currentTarget.scrollTop;
        sessionStorage.setItem('scroll', JSON.stringify(scrollTop));
    };

    const handleScrollTo = () => {
        const scroll = sessionStorage.getItem('scroll');
        if(!scroll) return;
        ref?.current?.scrollTo(0, Number(scroll))
    }

    useEffect(() => {
        handleScrollTo();
    },[data]);

    return (
        <>
            <div
                className={styles.table__container}
                id={'table-container'}
                ref={ref}
                onScroll={handleScroll}
            >
                <table
                    className={styles.table}
                    cellSpacing={0}
                    cellPadding={0}
                >
                    <thead className={styles.table__thead}>
                    <tr>
                        <td className={styles.table__cell_first}>
                            ID
                        </td>
                        <td className={styles.table__cell_head}>
                            <HeadFilter
                                selectedSort={selectedSort}
                                handleSelectSort={handleSelectSort}
                                title={'Дата та час'}
                                type={EnumSortType.callTime}
                            />
                        </td>
                        <td className={styles.table__cell_head}>
                            Назва файлу
                        </td>
                        <td className={styles.table__cell_head}>
                            <HeadFilter
                                selectedSort={selectedSort}
                                handleSelectSort={handleSelectSort}
                                title={'Оцінка'}
                                type={EnumSortType.problemResult}
                            />
                        </td>
                        <td className={styles.table__cell_last}>
                            Нотатка
                        </td>
                    </tr>
                    </thead>
                    <tbody className={styles.table__tbody}>
                    {data.map((item:ICallResource) => (
                        <tr
                            key={item.uuid}
                            className={`${item.decodingStatus !== 'READY' ? styles.table__tr_inactive : ''}`}
                        >
                            <td>
                                {item.decodingStatus === 'READY' ? (
                                    <NavLink to={`/call/${item.uuid}`}>
                                        {item.uuid.slice(0,4)}
                                    </NavLink>
                                ) : (
                                    <span>
                                        {item.uuid.slice(0,4)}
                                    </span>
                                )}
                            </td>
                            <td>
                                <TdDate
                                    date={item.callTime}
                                    duration={item.duration}
                                />
                            </td>
                            <td>
                                <span title={item.fileName}>
                                    {StringService.cutString(item.fileName)}
                                </span>
                            </td>
                            <td>
                                {item.decodingStatus === 'READY' ? (
                                    <TdProblemResult
                                        problemResult={item.problemResult}
                                        uuid={item.uuid}
                                    />
                                ) : (
                                <span className={styles.table__item_inactive}>
                                    Розпізнається
                                </span>
                                )}
                            </td>
                            <td className={styles.table__note}>
                                {item.decodingStatus === 'READY' ? (
                                    <TdNote
                                        className={styles.table__note}
                                        note={item.notes}
                                        entityId={item.uuid}
                                        handleEditNotes={handleEditNotes}
                                    />
                                ) : null}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
});

export default TableCalls;
