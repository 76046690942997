import React, {useState} from 'react';
import {EnumRequestStatus, IRequestResource} from "../../../../resources/IRequest.resource";
import {RequestStatusFactory} from "../../../../constants/RequestStatusFactory";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import ModalEditLimits from "../ModalInitialLimits";
import styles from './styles.module.scss';

const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M19.2573 7.22745C19.5502 7.52033 19.5503 7.9952 19.2574 8.28811L10.773 16.7734C10.6324 16.9141 10.4416 16.9931 10.2427 16.9931C10.0437 16.9931 9.85297 16.9141 9.71231 16.7735L5.46967 12.5308C5.17678 12.2379 5.17678 11.7631 5.46967 11.4702C5.76256 11.1773 6.23744 11.1773 6.53033 11.4702L10.2426 15.1824L18.1967 7.22751C18.4895 6.9346 18.9644 6.93458 19.2573 7.22745Z"
              fill="#1E1E1E"/>
    </svg>
)

export interface ILimitFields {
    [key: string]: string;
    maxTotalPhoneCalls:string;
    maxTotalPhoneCallsDurationSec:string;
}

interface IProps {
    data: IRequestResource;
    handleEditStatus: (requestId: number, status:string, maxTotalPhoneCalls?: string, maxTotalPhoneCallsDurationSec?: string) => void;
}

const RequestStatus = ({data, handleEditStatus}: IProps) => {
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [fields, setFields] = React.useState<ILimitFields>({
        maxTotalPhoneCalls: '',
        maxTotalPhoneCallsDurationSec: ''
    });

    const handleToggleMenu = () => setIsOpenMenu((prev) => !prev);
    const handleCloseMenu = () => setIsOpenMenu(false);
    const handleOpenModal = () => setIsOpenModal(true);
    const handleCloseModal = () => setIsOpenModal(false);

    const ref= useOutsideClick(handleCloseMenu);

    const handleChangeStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
        const status = e.currentTarget.id;
        if(status === EnumRequestStatus.DONE && data.status === EnumRequestStatus.NEW) {
            handleOpenModal();
            handleCloseMenu();
            return;
        }

        handleEditStatus(data.id, status);
        handleCloseMenu();
    };

    const isEmptyBalance = () => {
        if(!data.companyLimits) return false;
        if(!data.companyBalance) return false;
        return !data.companyBalance.maxTotalPhoneCalls || !data.companyBalance.maxTotalPhoneCallsDurationSec;
    }

    return (
        <>
            {isOpenModal ? (
                <ModalEditLimits
                    requestId={data.id}
                    handleClose={handleCloseModal}
                    fields={fields}
                    setFields={setFields}
                    handleEditStatus={handleEditStatus}
                />
            ) : null}
            <div
                className={styles.status__container}
                ref={ref}
            >
                <button
                    style={{
                        backgroundColor: RequestStatusFactory(data.status).color,
                    }}
                    className={styles.status__btn}
                    type={'button'}
                    onClick={handleToggleMenu}
                >
                    {RequestStatusFactory(data.status).name}
                </button>
                {isEmptyBalance() ? (
                    <span
                        className={styles.status__empty}
                    >
                        Ліміт
                    </span>
                ) : null}
                {isOpenMenu ? (
                    <div className={styles.status__list}>
                        {data.status !== EnumRequestStatus.DONE  ? (
                            <button
                                className={`${styles.status__item}`}
                                type={'button'}
                                onClick={handleChangeStatus}
                                id={EnumRequestStatus.DONE}
                            >
                                <div className={styles.status__name}>
                                    <div
                                        style={{
                                            backgroundColor: RequestStatusFactory(EnumRequestStatus.DONE).color,
                                        }}
                                        className={styles.status__circle}
                                    />
                                    <span>Активувати</span>
                                </div>
                                {/*{data.status === EnumRequestStatus.DONE ? <CheckIcon/> : null}*/}
                            </button>
                        ) : null}
                        {data.status !== EnumRequestStatus.SUSPEND  ? (
                            <button
                                className={`${styles.status__item}`}
                                type={'button'}
                                onClick={handleChangeStatus}
                                id={EnumRequestStatus.SUSPEND}
                            >
                                <div className={styles.status__name}>
                                    <div
                                        style={{
                                            backgroundColor: RequestStatusFactory(EnumRequestStatus.SUSPEND).color,
                                        }}
                                        className={styles.status__circle}
                                    />
                                    <span>Призупинити</span>
                                </div>
                                {/*{data.status === EnumRequestStatus.SUSPEND ? <CheckIcon/> : null}*/}
                            </button>
                        ) : null}
                        {data.status !== EnumRequestStatus.CANCEL ? (
                            <button
                                className={`${styles.status__item}`}
                                type={'button'}
                                onClick={handleChangeStatus}
                                id={EnumRequestStatus.CANCEL}
                            >
                                <div className={styles.status__name}>
                                    <div
                                        style={{
                                            backgroundColor: RequestStatusFactory(EnumRequestStatus.CANCEL).color,
                                        }}
                                        className={styles.status__circle}
                                    />
                                    <span>Відхилити</span>
                                </div>
                                {/*{data.status === EnumRequestStatus.CANCEL ? <CheckIcon/> : null}*/}
                            </button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default RequestStatus;
