import {IEmployeeResource} from "./IEmployee.resource";

export interface ICallServer {
    readonly blobUrl: string;
    readonly callTime: string;
    readonly channels: number;
    readonly companyId: number;
    readonly countWithSameNumber: number;
    readonly created: string;
    readonly decodingStatus: string; // ??????
    readonly duration: number;
    readonly fileName: string;
    readonly phoneNumber: string;
    readonly problemResult: number;
    readonly state: EnumCallStatus; // ??????
    readonly status: string; // ??????
    readonly uuid: string;
    readonly peeked: string;
    readonly resolved: string;
    readonly employee: IEmployeeResource;
    readonly lastActivity: ILastActivityResource;
    readonly notes?: string;
    readonly countWithSameNumberPeriodEnd?: string;
    readonly countWithSameNumberPeriodStart?: string;
    readonly extId:string;
}

export interface ICallResource extends ICallServer {
    readonly isSelected?: boolean;
}


export enum EnumCallStatus {
    NEW = 'NEW',
    RESOLVED = 'RESOLVED',
    IN_PROGRESS = 'IN_PROGRESS',
    ACCEPTED = 'ACCEPTABLE'
}

export interface ILastActivityResource {
    readonly activity: EnumCallStatus; // ??????
    readonly time: string;
    readonly userId: number;
    readonly who: string;
}
