import React, {useEffect, useState} from 'react';
import ModalContainer from "../../../../components/ModalContainer";
import Input from "../../../../components/common/Input";
import TextButton from "../../../../components/common/TextButton";
import Button from "../../../../components/common/Button";
import {ILimitFields} from "../RequestStatus";
import {IPatchRequest} from "../../../../services/api/FetchService";
import {ICompanyLimits} from "../../../../resources/IRequest.resource";
import styles from './styles.module.scss';

interface IProps {
    handleClose: () => void;
    requestId: number;
    current: ICompanyLimits;
    handleEditRequest: (requestId: number, body:IPatchRequest) => void;
}

const ModalEditLimits = ({handleClose, requestId, current, handleEditRequest}:IProps) => {
    const[fields, setFields] = useState<ILimitFields>({
        maxTotalPhoneCalls: '',
        maxTotalPhoneCallsDurationSec: '',
    });

    useEffect(() => {
        setFields((prev) => ({
            maxTotalPhoneCalls: current?.maxTotalPhoneCalls ? String(current.maxTotalPhoneCalls) : '',
            maxTotalPhoneCallsDurationSec: current?.maxTotalPhoneCallsDurationSec ? String(current.maxTotalPhoneCallsDurationSec / 60) : '',
        }))
    },[])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const key = e.target.id;

        setFields((prev:ILimitFields) => ({
            ...prev,
            [key]: value
        }));
    }

    const handleCancel = () => {
        setFields({
            maxTotalPhoneCalls: '',
            maxTotalPhoneCallsDurationSec: ''
        });
        handleClose();
    }

    const handleSubmit = () => {

        handleEditRequest(requestId, {
            companyLimits:  {
                maxTotalPhoneCallsDurationSec: fields.maxTotalPhoneCallsDurationSec ?  Number(fields.maxTotalPhoneCallsDurationSec) * 60 : 0,
                maxTotalPhoneCalls: fields.maxTotalPhoneCalls ?  Number(fields.maxTotalPhoneCalls) : 0,
            }
        })
        handleClose();
    };

    const isDisabled = () => {
        return !fields.maxTotalPhoneCalls || !fields.maxTotalPhoneCallsDurationSec;
    }

    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.modal}>
                <h2 className={styles.modal__title}>
                    Редагувати максимальну кількість дзвінків і максимальний час дзвінків в хвилинах
                </h2>
                <Input
                    id={'maxTotalPhoneCalls'}
                    placeholder={'Ліміт дзвінків'}
                    value={fields.maxTotalPhoneCalls}
                    onChange={handleChange}
                />
                <Input
                    id={'maxTotalPhoneCallsDurationSec'}
                    placeholder={'Ліміт тривалості'}
                    value={fields.maxTotalPhoneCallsDurationSec}
                    onChange={handleChange}
                />
                <div className={styles.modal__footer}>
                    <TextButton
                        text={'Cancel'}
                        onClick={handleCancel}
                    />
                    <Button
                        onClick={handleSubmit}
                        text={'OK'}
                        disabled={isDisabled()}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalEditLimits;
