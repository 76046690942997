import React from 'react';
import styles from './styles.module.scss'

const ClearIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.25 9C2.25 5.27208 5.27208 2.25 9 2.25H15C18.7279 2.25 21.75 5.27208 21.75 9V15C21.75 18.7279 18.7279 21.75 15 21.75H9C5.27208 21.75 2.25 18.7279 2.25 15V9ZM9 3.75C6.10051 3.75 3.75 6.10051 3.75 9V15C3.75 17.8995 6.10051 20.25 9 20.25H15C17.8995 20.25 20.25 17.8995 20.25 15V9C20.25 6.10051 17.8995 3.75 15 3.75H9Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.08306 9.08295C9.37596 8.79006 9.85083 8.79006 10.1437 9.08295L14.9167 13.8559C15.2096 14.1488 15.2096 14.6237 14.9167 14.9166C14.6238 15.2095 14.1489 15.2095 13.856 14.9166L9.08306 10.1436C8.79017 9.85072 8.79017 9.37584 9.08306 9.08295Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.9168 9.08306C15.2097 9.37596 15.2097 9.85083 14.9168 10.1437L10.1438 14.9167C9.85094 15.2096 9.37607 15.2096 9.08317 14.9167C8.79028 14.6238 8.79028 14.1489 9.08317 13.856L13.8561 9.08306C14.149 8.79017 14.6239 8.79017 14.9168 9.08306Z" fill="#1E1E1E"/>
        </svg>
    )
}

interface IProps {
    onClick: () => void;
}

const ClearAllTagsBtn = ({onClick}:IProps) => {
    return (
        <button
            type={'button'}
            className={styles.clear}
            onClick={onClick}
        >
            <ClearIcon/>
            <span>Clear all</span>
        </button>
    );
};

export default ClearAllTagsBtn;
