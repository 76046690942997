import React, {useState} from 'react';
import useOutsideClick from "../../../../hooks/useOutsideClick";
import styles from './styles.module.scss';
import {EnumCallStatus} from "../../../../resources/ICall.resource";
import StatusNameColorFactory from "../../../../constants/StatusNameColorFactory";

const initialProblemResult = [
    {
        title: '10+',
        value: '10'
    },
    {
        title: '20+',
        value: '20'
    },
    {
        title: '30+',
        value: '30'
    },
    {
        title: '40+',
        value: '40'
    },
    {
        title: '50+',
        value: '50'
    }
];

const initialTopProblems = [
    {
        title: 'топ 5',
        value: '5'
    },
    {
        title: 'топ 10',
        value: '10'
    },
    {
        title: 'топ 15',
        value: '15'
    }
];

const initialState = [
    {
        title: StatusNameColorFactory(EnumCallStatus.ACCEPTED).name,
        value: EnumCallStatus.ACCEPTED
    },
    {
        title: StatusNameColorFactory(EnumCallStatus.IN_PROGRESS).name,
        value: EnumCallStatus.IN_PROGRESS
    },
    {
        title: StatusNameColorFactory(EnumCallStatus.RESOLVED).name,
        value: EnumCallStatus.RESOLVED
    },
    {
        title: StatusNameColorFactory(EnumCallStatus.NEW).name,
        value: EnumCallStatus.NEW
    }
];


const IconCheck = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2573 7.22745C19.5502 7.52033 19.5503 7.9952 19.2574 8.28811L10.773 16.7734C10.6324 16.9141 10.4416 16.9931 10.2427 16.9931C10.0437 16.9931 9.85297 16.9141 9.71231 16.7735L5.46967 12.5308C5.17678 12.2379 5.17678 11.7631 5.46967 11.4702C5.76256 11.1773 6.23744 11.1773 6.53033 11.4702L10.2426 15.1824L18.1967 7.22751C18.4895 6.9346 18.9644 6.93458 19.2573 7.22745Z" fill="#1E1E1E"/>
        </svg>
    )
}

const IConMenu = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.5858 3.33579 11.25 3.75 11.25H14.25C14.6642 11.25 15 11.5858 15 12C15 12.4142 14.6642 12.75 14.25 12.75H3.75C3.33579 12.75 3 12.4142 3 12Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.58579 3.33579 5.25 3.75 5.25H20.25C20.6642 5.25 21 5.58579 21 6C21 6.41421 20.6642 6.75 20.25 6.75H3.75C3.33579 6.75 3 6.41421 3 6Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 18C3 17.5858 3.33579 17.25 3.75 17.25H20.25C20.6642 17.25 21 17.5858 21 18C21 18.4142 20.6642 18.75 20.25 18.75H3.75C3.33579 18.75 3 18.4142 3 18Z" fill="#1E1E1E"/>
        </svg>
    )
};

interface IProps {
    handleTopProblems: (topProblems:string) => void;
    handleProblemsResult: (problemsResult: string) => void;
    problemsResult: string | null;
    topProblems: string | null;
    handleSetStatus: (status: EnumCallStatus) =>void;
    status: EnumCallStatus[];
    handleDefaultAnalyzed: () => void;
    isAnalyzed:string | null;
    isNotAnalyzed:string | null;
    handleNotAnalyzed: () => void;
}

const SelectAdditional = ({handleTopProblems, handleProblemsResult, problemsResult, topProblems, status, handleSetStatus, handleDefaultAnalyzed, isAnalyzed, isNotAnalyzed, handleNotAnalyzed}:IProps) => {
    const [isOpen, setIOpen] = useState(false);

    const handleClose = () => setIOpen(false);
    const handleToggleOpen = () => setIOpen((prev) => !prev);

    const handleSelectTopProblems = (e: React.MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.id;
        handleTopProblems(value)
    };

    const handleSelectProblemsResult = (e: React.MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.id;
        handleProblemsResult(value)
    };

    const handleSelectStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.id;
        handleSetStatus(value as EnumCallStatus);
    }

    const ref = useOutsideClick(handleClose);

    return (
        <div
            className={styles.select}
            ref={ref}
        >
            <button
                type={'button'}
                className={styles.select__btn}
                onClick={handleToggleOpen}
            >
                <IConMenu />
            </button>
            {isOpen ? (
                <div className={styles.select__list}>
                    <button
                        type={'button'}
                        className={`${styles.select__item} ${isAnalyzed ? styles.select__item_active : ''}`}
                        onClick={handleDefaultAnalyzed}
                    >
                        Проаналізовані дзвінки
                        {isAnalyzed ? <IconCheck /> : null}
                    </button>
                    <button
                        type={'button'}
                        className={`${styles.select__item} ${isNotAnalyzed ? styles.select__item_active : ''}`}
                        onClick={handleNotAnalyzed}
                    >
                        Непроаналізовані дзвінки
                        {isNotAnalyzed  ? <IconCheck /> : null}
                    </button>
                    {initialProblemResult.map((item) => (
                        <button
                            key={item.value}
                            type={'button'}
                            className={`${styles.select__item} ${problemsResult === item.value ? styles.select__item_active : ''}`}
                            id={item.value}
                            onClick={handleSelectProblemsResult}
                        >
                            {item.title}
                            {problemsResult === item.value ? <IconCheck /> : null}
                        </button>
                    ))}
                    {initialTopProblems.map((unit) => (
                        <button
                            key={unit.value}
                            type={'button'}
                            className={`${styles.select__item} ${topProblems === unit.value ? styles.select__item_active : ''}`}
                            id={unit.value}
                            onClick={handleSelectTopProblems}
                        >
                            {unit.title}
                            {topProblems === unit.value ? <IconCheck /> : null}
                        </button>
                    ))}
                    {initialState.map((unit) => (
                        <button
                            key={unit.value}
                            type={'button'}
                            className={`${styles.select__item} ${status?.includes(unit.value) ? styles.select__item_active : ''}`}
                            id={unit.value}
                            onClick={handleSelectStatus}
                        >
                            {unit.title}
                            {status?.includes(unit.value) ? <IconCheck /> : null}
                        </button>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default SelectAdditional;
