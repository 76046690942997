import React, {useState} from 'react';
import ModalEditNote from "../ModalEditNote";
import {NavLink} from "react-router-dom";
import StringService from "../../../../services/StringService";
import styles from './styles.module.scss';

const NoteIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g opacity="0.4">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.6175 3.44983C10.612 3.45273 10.5925 3.46432 10.5525 3.49825C10.4868 3.554 10.4047 3.63561 10.2672 3.77307L10.2647 3.77554L8.70719 5.33308L10.6667 7.29264L12.2256 5.73376L12.2262 5.7332L12.2302 5.72916C12.3654 5.59393 12.4461 5.51264 12.5014 5.44755C12.5354 5.4075 12.547 5.38792 12.5498 5.38244C12.5598 5.35029 12.5598 5.31591 12.5499 5.28381C12.547 5.27833 12.5353 5.25869 12.5012 5.21849C12.4453 5.15262 12.3634 5.07021 12.2256 4.93238L11.0657 3.77251C10.9285 3.63524 10.8464 3.55376 10.7809 3.49811C10.741 3.4643 10.7215 3.45273 10.7161 3.44983C10.684 3.4399 10.6496 3.4399 10.6175 3.44983ZM9.95963 7.99974L8.00008 6.04019L3.16675 10.8735V12.8331L5.12631 12.8331L9.95963 7.99974ZM6.54051 12.8331L12.9287 6.44491L12.9327 6.44087L12.9333 6.4403L12.9456 6.42797C13.0669 6.30677 13.1782 6.19542 13.2637 6.0947C13.3559 5.9861 13.447 5.85746 13.5002 5.69365C13.5763 5.45936 13.5764 5.20696 13.5002 4.97261C13.447 4.80871 13.3558 4.67997 13.2635 4.5713C13.178 4.47048 13.0665 4.359 12.9451 4.23762L11.7605 3.05308C11.6397 2.93223 11.5286 2.82108 11.428 2.73572C11.3195 2.64367 11.191 2.55267 11.0273 2.49948C10.793 2.42335 10.5406 2.42335 10.3063 2.49948C10.1425 2.5527 10.0139 2.64377 9.90536 2.73588C9.8047 2.82132 9.69345 2.93259 9.57244 3.05362L9.56143 3.06463L2.31319 10.3129C2.21943 10.4066 2.16675 10.5338 2.16675 10.6664V13.3331C2.16675 13.6092 2.39061 13.8331 2.66675 13.8331H10.3334C10.6096 13.8331 10.8334 13.6092 10.8334 13.3331C10.8334 13.0569 10.6096 12.8331 10.3334 12.8331H6.54051Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5 13.25C11.5 12.9739 11.7239 12.75 12 12.75H13C13.2761 12.75 13.5 12.9739 13.5 13.25C13.5 13.5261 13.2761 13.75 13 13.75H12C11.7239 13.75 11.5 13.5261 11.5 13.25Z" fill="#1E1E1E"/>
        </g>
    </svg>
);

const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 8C2 7.72386 2.22386 7.5 2.5 7.5H4.5C4.77614 7.5 5 7.72386 5 8C5 8.27614 4.77614 8.5 4.5 8.5H2.5C2.22386 8.5 2 8.27614 2 8Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.5 8C6.5 7.72386 6.72386 7.5 7 7.5H13.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5H7C6.72386 8.5 6.5 8.27614 6.5 8Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1464 4.64645C10.3417 4.45118 10.6583 4.45118 10.8536 4.64645L13.8536 7.64645C14.0488 7.84171 14.0488 8.15829 13.8536 8.35355L10.8536 11.3536C10.6583 11.5488 10.3417 11.5488 10.1464 11.3536C9.95118 11.1583 9.95118 10.8417 10.1464 10.6464L12.7929 8L10.1464 5.35355C9.95118 5.15829 9.95118 4.84171 10.1464 4.64645Z" fill="#1E1E1E"/>
        </svg>
    )
}

interface IProps {
    note?:string;
    entityId: string | number;
    handleEditNotes: (callId:string | number, notes:string) => void;
    isShowLink?: boolean;
    className?:string;
}

const TdNote = ({ handleEditNotes, isShowLink=true, className, note, entityId}:IProps) => {
    const [isOpen, setIOpen] = useState(false);

    const handleCloseModal = () => setIOpen(false);
    const handleOpenModal = () => setIOpen(true);

    return (
        <>
            {isOpen ? (
                <ModalEditNote
                    handleClose={handleCloseModal}
                    entityId={entityId}
                    notes={note}
                    handleEditNotes={handleEditNotes}
                />
            ) : null}
            <div className={`${styles.note} ${className ? className : ''}`}>
                <button
                    className={styles.note__btn}
                    onClick={handleOpenModal}
                    title={note}
                >
                    <NoteIcon />
                    {note ? (
                        <span className={styles.note__text}>
                            {StringService.cutString(note, 25)}
                        </span>
                    ) : (
                        <span className={styles.note__text_empty}>
                            Write note
                        </span>
                    )}
                </button>
                {isShowLink ? (
                    <NavLink
                        to={`/call/${entityId}`}
                    >
                        <LinkIcon />
                    </NavLink>
                ) : null}
            </div>
        </>
    );
};

export default TdNote;
