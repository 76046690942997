import React, {useEffect, useState} from 'react';
import ModalContainer from "../ModalContainer";
import TextButton from "../common/TextButton";
import Button from "../common/Button";
import styles from './styles.module.scss';

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M19.2803 4.71967C19.5732 5.01256 19.5732 5.48744 19.2803 5.78033L5.78033 19.2803C5.48744 19.5732 5.01256 19.5732 4.71967 19.2803C4.42678 18.9874 4.42678 18.5126 4.71967 18.2197L18.2197 4.71967C18.5126 4.42678 18.9874 4.42678 19.2803 4.71967Z"
              fill="#1E1E1E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L19.2803 18.2197C19.5732 18.5126 19.5732 18.9874 19.2803 19.2803C18.9874 19.5732 18.5126 19.5732 18.2197 19.2803L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z"
              fill="#1E1E1E"/>
    </svg>
);

const LineIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="2" viewBox="0 0 16 2" fill="none">
        <path d="M0 1H16" stroke="black"/>
    </svg>
)

interface IProps {
    handleClose: () => void;
    handleSetProblemResult: (start: string, end: string, clean?:boolean) => void;
    selectedProblemResultJSON: string | null;
}

const ModalProblemResultRange = ({handleClose, selectedProblemResultJSON, handleSetProblemResult}: IProps) => {
    const [startRange, setStartRange] = useState<string>('');
    const [endRange, setEndRange] = useState<string>('');

    const handleChangeStart = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if(Number(value) < 0 || !value.match(`^[0-9]*$`)) return;
        setStartRange(value)
    }

    const handleChangeEnd = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if(Number(value) < 0 || !value.match(`^[0-9]*$`)) return;
        setEndRange(value)
    };

    const handleClear = () => {
        handleSetProblemResult(startRange, endRange, true);
        handleClose();
    };

    const handleSubmit = () => {
        handleClose();
        handleSetProblemResult(startRange, endRange);
    }

    const isDisabled = () => {
        return !Number(startRange) || !Number(endRange);
    };

    useEffect(() => {
        if(startRange && endRange) return;
        const start = selectedProblemResultJSON?.split('-')[0];
        const end = selectedProblemResultJSON?.split('-')[1];
        if(!start || !end) return;
        setStartRange(start);
        setEndRange(end);
    }, [selectedProblemResultJSON]);

    return (
        <ModalContainer handleClose={handleClose}>
            <div className={styles.modal}>
                <div className={styles.modal__header}>
                    <span>
                        Оцінка, %
                    </span>
                    {selectedProblemResultJSON ? (
                        <button
                            className={styles.modal__clear}
                            type={'button'}
                            onClick={handleClear}
                        >
                            <CloseIcon/>
                            <span>Очистити</span>
                        </button>
                    ) : null}
                </div>
                <div className={styles.modal__body}>
                    <input
                        className={styles.modal__input}
                        placeholder={'0'}
                        value={startRange}
                        onChange={handleChangeStart}
                    />
                    <LineIcon/>
                    <input
                        className={styles.modal__input}
                        placeholder={'100'}
                        value={endRange}
                        onChange={handleChangeEnd}
                    />
                </div>
                <div className={styles.modal__footer}>
                    <TextButton
                        text={'Закрити'}
                        onClick={handleClose}
                    />
                    <Button
                        disabled={isDisabled()}
                        text={'Підтвердити'}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </ModalContainer>
    );
};

export default ModalProblemResultRange;
