import React from 'react';
import styles from './styles.module.scss';
import {toast} from "react-toastify";
import {SuccessToastSettings} from "../../../../services/toast/toastSettings";
import StringService from "../../../../services/StringService";

interface IProps {
    text: string;
    isCutString?: boolean;
}

const ClickCopyBtn = ({text, isCutString=true}:IProps) => {

    const handleCopy = async () => {
        await navigator.clipboard.writeText(text);
        toast.success(`${text} was copied!`, SuccessToastSettings)
    };

    return (
        <button
            type={'button'}
            onClick={handleCopy}
            className={styles.btn__btn}
            title={`Click to copy: ${text}`}
        >
            {isCutString ?  StringService.cutMiddleString(text, 3, 3, 10) : text}
        </button>
    );
};

export default ClickCopyBtn;
