class StringService {
    cutString= (string: string, length:number=20):string => {
        if(!string) return '';
        if(string.length <= length){
            return string;
        }
        return `${string.slice(0, length)}...`
    }

    cutMiddleString = (string: string, firstPart:number, lastPart:number, length: number) => {
        if(!string) return '';
        if(string.length <= length){
            return string;
        }

        return `${string.slice(0, firstPart)}...${string.slice(string.length - lastPart, string.length)}`
    }
}

export default new StringService();
