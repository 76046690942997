import React, {useEffect, useState} from 'react';
import BaseLayout from "../../layouts/BaseLayout";
import EmptyTestGrid from "./components/EmptyTestGrid";
import TestGrid from "./components/TestGrid";
import CompanyObserver from "../../mobx/company/CompanyObserver";
import FetchService from "../../services/api/FetchService";
import {ICallResource} from "../../resources/ICall.resource";
import Loader from "../../components/Loader";
import {observer} from "mobx-react-lite";

const TestAutomaticAssessmentPage = () => {
    const [allCalls, setAllCalls] = useState<ICallResource[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const companyId = CompanyObserver.getCompanyId();

    const getAllCalls = async () => {
        if(!companyId) return;
        try {
            setIsLoading(true);
            const {data} = await FetchService.getAllCalls(companyId);
            setAllCalls(data.calls)
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCalls()
    },[companyId])

    if(isLoading) {
        return <Loader/>;
    }

    return (
        <BaseLayout>
            {isLoading ? <Loader /> : null}
            {allCalls.length ? (
                <TestGrid />
            ) :  (
                <EmptyTestGrid
                    refresh={getAllCalls}
                />
            )}
        </BaseLayout>
    );
};

export default observer(TestAutomaticAssessmentPage);
