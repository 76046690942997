import React, {useState} from 'react';
import GuestLayout from "../../layouts/GuestLayout";
import Input from "../../components/common/Input";
import GuestCard from "../../components/GuestCard";
import Button from "../../components/common/Button";
import FetchService, {IPostPassword} from "../../services/api/FetchService";
import styles from './styles.module.scss';
import Loader from "../../components/Loader";
import {useNavigate, useSearchParams} from "react-router-dom";
import ROUTES from "../../routes";

const RecoverPasswordPage = () => {
    const [password, setPassword] = useState('');
    const [repeat, setRepeat] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();


    const handleChangePassword = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value.trim());
    };

    const handleChangeRepeat = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setRepeat(value.trim());
    };

    const handleCreatePassword = async () => {
        if(password !== repeat){
            setIsError(true);
            return;
        }
        const token = params.get('otp');
        if(!token) return;

        const body:IPostPassword = {
            newPassword: password,
            confirmNewPassword: repeat,
            otp: token
        };

        try {
            setIsLoading(true);
            await FetchService.postPassword(body)
            navigate(ROUTES.LOGIN)
        } finally {
            setIsLoading(false);
        }

    }

    const isDisabled = () => {
        const token = params.get('otp');
        return !token || !password || !repeat;
    }

    return (
        <GuestLayout>
            {isLoading ? <Loader/> : null}
            <GuestCard>
                <div className={styles.recover__container}>
                    <Input
                        placeholder={'Enter password'}
                        type={'password'}
                        value={password}
                        onChange={handleChangePassword}
                    />
                    <Input
                        placeholder={'Confirm password'}
                        type={'password'}
                        value={repeat}
                        onChange={handleChangeRepeat}
                        errorText={isError ? 'Passwords do not match' : ''}
                    />
                    <Button
                        text={'Create Password'}
                        onClick={handleCreatePassword}
                        disabled={isDisabled()}
                    />
                </div>
            </GuestCard>
        </GuestLayout>
    );
};

export default RecoverPasswordPage;
