import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from "react-dom";
import styles from './styles.module.scss';

interface IProps {
    children: React.ReactNode;
    handleClose: () => void;
}

const ModalContainer = ({children, handleClose}:IProps) => {
    const [mounted, setMounted] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    const escFunction = useCallback((event: any) => {
        if (event.key === "Escape") handleClose();
    }, []);

    const handleOverlayClick = (e: React.MouseEvent) => {
        if (e.target === modalRef.current) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return mounted ? createPortal((
        <div
            ref={modalRef}
            className={styles.modal__overlay}
            onMouseDown={handleOverlayClick}
        >
            <div>{children}</div>
        </div>
    ), document.getElementById("modals") as Element) : null;
};

export default ModalContainer;
