import React from 'react';
import styles from './styles.module.scss';
import ModalContainer from "../../../../components/ModalContainer";
import Button from "../../../../components/common/Button";

interface IProps {
    handleClose: () => void;
}

const SuccessEncodingModal = ({handleClose}:IProps) => {
    return (
        <ModalContainer
            handleClose={handleClose}
        >
            <div className={styles.analyze__modal}>
                <h2 className={styles.analyze__title}>
                    Запис успішно відправлено на розпізнавання
                </h2>
                <Button
                    text={'OK'}
                    onClick={handleClose}
                />
            </div>
        </ModalContainer>
    );
};

export default SuccessEncodingModal;
