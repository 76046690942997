import {useQuery} from "@tanstack/react-query";
import QueryKeyFactory from "./QueryKeyFactory";
import {AxiosResponse} from "axios";
import FetchService from "../services/api/FetchService";
import CompanyObserver from "../mobx/company/CompanyObserver";
import {IEmployeeResource} from "../resources/IEmployee.resource";

const useQMyEmployee = () => {
    const companyId = CompanyObserver.getCompanyId();

    const {data, isFetching} = useQuery({
        queryKey: QueryKeyFactory.myEmployees(companyId),
        queryFn: () => FetchService.getMyEmployee(companyId),
        select: (response:AxiosResponse<IEmployeeResource>) => response.data,
        staleTime: 0
    });

    return {
        employee: data ? data : null,
        isLoading: isFetching
    }
};

export default useQMyEmployee;
