import React from 'react';
import styles from './styles.module.scss';

const IconTitle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="212" height="20" viewBox="0 0 212 20" fill="none">
            <path d="M193.864 19.5056L201.224 0.495117H204.212L211.517 19.5056H208.394L204.049 8.04501C203.994 7.91828 203.886 7.61049 203.723 7.12164C203.578 6.6328 203.406 6.08964 203.207 5.49217C203.008 4.8947 202.826 4.35154 202.664 3.8627C202.501 3.35575 202.392 3.02985 202.338 2.88501L202.962 2.85785C202.854 3.16564 202.718 3.55491 202.555 4.02564C202.41 4.49638 202.247 4.99428 202.066 5.51933C201.903 6.04438 201.74 6.54228 201.577 7.01301C201.414 7.46565 201.278 7.84586 201.17 8.15365L196.852 19.5056H193.864ZM197.123 14.7802L198.21 11.9557H206.846L208.095 14.7802H197.123Z" fill="#1E1E1E"/>
            <path d="M180.239 19.7771C178.917 19.7771 177.704 19.5417 176.6 19.071C175.495 18.6002 174.536 17.9303 173.721 17.0613C172.906 16.1741 172.273 15.1331 171.82 13.9381C171.385 12.7432 171.168 11.4306 171.168 10.0002C171.168 8.64235 171.404 7.38403 171.874 6.2253C172.363 5.04845 173.033 4.01645 173.884 3.12929C174.735 2.24214 175.722 1.55414 176.844 1.06529C177.967 0.576452 179.18 0.332031 180.483 0.332031C181.407 0.332031 182.303 0.476873 183.172 0.766556C184.059 1.03814 184.856 1.4274 185.562 1.93435C186.286 2.42319 186.875 2.98445 187.327 3.61814L185.372 5.65498C184.847 5.12993 184.313 4.6954 183.769 4.3514C183.244 3.9893 182.701 3.71772 182.14 3.53666C181.597 3.35561 181.045 3.26508 180.483 3.26508C179.596 3.26508 178.763 3.43708 177.985 3.78108C177.224 4.10698 176.555 4.56866 175.975 5.16614C175.414 5.76361 174.97 6.47877 174.644 7.31161C174.319 8.12635 174.156 9.02256 174.156 10.0002C174.156 11.0322 174.309 11.9737 174.617 12.8247C174.943 13.6575 175.396 14.3727 175.975 14.9701C176.555 15.5676 177.243 16.0293 178.039 16.3552C178.854 16.663 179.75 16.8169 180.728 16.8169C181.325 16.8169 181.914 16.7354 182.493 16.5725C183.072 16.4095 183.616 16.1741 184.123 15.8663C184.629 15.5586 185.091 15.1965 185.508 14.78L186.974 17.1699C186.558 17.6588 185.987 18.1023 185.263 18.5007C184.557 18.899 183.76 19.2158 182.873 19.4512C182.004 19.6685 181.126 19.7771 180.239 19.7771Z" fill="#1E1E1E"/>
            <path d="M154.361 19.5056V16.7899H157.429V3.21091H154.361V0.495117H163.431V3.21091H160.363V16.7899H163.431V19.5056H154.361Z" fill="#1E1E1E"/>
            <path d="M128.643 19.5056V0.495117H131.277L142.901 16.0566L142.466 16.301C142.394 15.8484 142.33 15.3595 142.276 14.8345C142.222 14.2913 142.167 13.721 142.113 13.1235C142.077 12.508 142.041 11.8652 142.004 11.1953C141.986 10.5073 141.968 9.79217 141.95 9.04986C141.932 8.30754 141.923 7.53807 141.923 6.74143V0.495117H144.856V19.5056H142.167L130.734 4.51449L131.033 4.13428C131.123 5.2387 131.196 6.18017 131.25 6.9587C131.322 7.71912 131.377 8.37091 131.413 8.91407C131.467 9.43912 131.503 9.87365 131.522 10.2176C131.54 10.5435 131.549 10.8151 131.549 11.0324C131.567 11.2496 131.576 11.4397 131.576 11.6027V19.5056H128.643Z" fill="#1E1E1E"/>
            <path d="M110.468 19.7773C109.128 19.7773 107.888 19.5329 106.747 19.0441C105.625 18.5552 104.638 17.8763 103.787 17.0072C102.954 16.1201 102.303 15.0881 101.832 13.9112C101.379 12.7163 101.153 11.4127 101.153 10.0005C101.153 8.58827 101.379 7.29374 101.832 6.1169C102.303 4.92195 102.954 3.88995 103.787 3.0209C104.638 2.13374 105.625 1.44574 106.747 0.956896C107.888 0.468054 109.128 0.223633 110.468 0.223633C111.808 0.223633 113.039 0.468054 114.162 0.956896C115.302 1.44574 116.289 2.13374 117.122 3.0209C117.973 3.90805 118.625 4.94911 119.077 6.14406C119.548 7.3209 119.783 8.60637 119.783 10.0005C119.783 11.3946 119.548 12.6891 119.077 13.8841C118.625 15.0609 117.973 16.0929 117.122 16.9801C116.289 17.8672 115.302 18.5552 114.162 19.0441C113.039 19.5329 111.808 19.7773 110.468 19.7773ZM110.468 16.9257C111.391 16.9257 112.233 16.7537 112.994 16.4097C113.772 16.0657 114.442 15.586 115.003 14.9704C115.565 14.3367 115.999 13.6034 116.307 12.7706C116.633 11.9196 116.796 10.9963 116.796 10.0005C116.796 9.00469 116.633 8.09037 116.307 7.25753C115.999 6.40658 115.565 5.67332 115.003 5.05774C114.442 4.42406 113.772 3.93521 112.994 3.59121C112.233 3.24721 111.391 3.07521 110.468 3.07521C109.545 3.07521 108.694 3.24721 107.915 3.59121C107.155 3.93521 106.485 4.415 105.906 5.03058C105.344 5.64616 104.91 6.37942 104.602 7.23037C104.294 8.06321 104.14 8.98658 104.14 10.0005C104.14 10.9963 104.294 11.9196 104.602 12.7706C104.91 13.6215 105.344 14.3548 105.906 14.9704C106.485 15.586 107.155 16.0657 107.915 16.4097C108.694 16.7537 109.545 16.9257 110.468 16.9257Z" fill="#1E1E1E"/>
            <path d="M74.151 19.5056V0.495117H77.0297L83.955 11.7656L82.5699 11.7385L89.5767 0.495117H92.2925V19.5056H89.3594V11.7113C89.3594 10.0819 89.3956 8.61533 89.4681 7.31175C89.5586 6.00817 89.7034 4.71364 89.9026 3.42817L90.2828 4.43301L84.0636 14.0469H82.2712L76.2422 4.54165L76.5409 3.42817C76.7401 4.64122 76.8758 5.89049 76.9483 7.17596C77.0388 8.44333 77.0841 9.95512 77.0841 11.7113V19.5056H74.151Z" fill="#1E1E1E"/>
            <path d="M50.3793 19.5056V0.495117H58.4723C59.5586 0.495117 60.5544 0.766697 61.4597 1.30986C62.3831 1.83491 63.1163 2.55912 63.6595 3.48249C64.2026 4.38775 64.4742 5.4107 64.4742 6.55133C64.4742 7.58333 64.2026 8.54291 63.6595 9.43006C63.1163 10.3172 62.3921 11.0324 61.4869 11.5755C60.5816 12.1006 59.5767 12.3631 58.4723 12.3631H53.3123V19.5056H50.3793ZM61.677 19.5056L56.8429 10.9237L59.8574 10.2448L65.2346 19.5328L61.677 19.5056ZM53.3123 9.5387H58.7439C59.269 9.5387 59.7397 9.41196 60.1561 9.15849C60.5725 8.88691 60.8984 8.5248 61.1338 8.07217C61.3692 7.60143 61.4869 7.08543 61.4869 6.52417C61.4869 5.89049 61.333 5.33828 61.0252 4.86754C60.7355 4.3787 60.3191 3.99849 59.7759 3.72691C59.2509 3.45533 58.6534 3.31954 57.9835 3.31954H53.3123V9.5387Z" fill="#1E1E1E"/>
            <path d="M24.4927 19.5056L31.8525 0.495117H34.8398L42.1453 19.5056H39.0222L34.6769 8.04501C34.6226 7.91828 34.5139 7.61049 34.351 7.12164C34.2061 6.6328 34.0342 6.08964 33.835 5.49217C33.6358 4.8947 33.4548 4.35154 33.2918 3.8627C33.1289 3.35575 33.0203 3.02985 32.9659 2.88501L33.5906 2.85785C33.4819 3.16564 33.3462 3.55491 33.1832 4.02564C33.0384 4.49638 32.8754 4.99428 32.6944 5.51933C32.5314 6.04438 32.3685 6.54228 32.2055 7.01301C32.0426 7.46565 31.9068 7.84586 31.7982 8.15365L27.48 19.5056H24.4927ZM27.7516 14.7802L28.8379 11.9557H37.4742L38.7234 14.7802H27.7516Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.31649 0.339844H0.483154V8.42318H15.8165V0.339844H12.9832V5.58984H3.31649V0.339844ZM3.31649 19.5063H0.483154V11.423H15.8165V19.5063H12.9832V14.2563H3.31649V19.5063Z" fill="#1E1E1E"/>
        </svg>
    )
}

interface IProps {
    children: React.ReactNode;
}

const GuestCard = ({children}:IProps) => {

    return (
        <div className={styles.card}>
            <div className={styles.card__left}>
                <IconTitle />
            </div>
            <div className={styles.card__right}>
                {children}
            </div>
        </div>
    );
};

export default GuestCard;
